/* CONSTANTS USED IN HomeScreen COMPONENT */
export const DONATION_LIST_REQUEST = "DONATION_LIST_REQUEST";

export const DONATION_LIST_SUCCESS = "DONATION_LIST_SUCCESS";

export const DONATION_LIST_FAIL = "DONATION_LIST_FAIL";

export const DONATION_CREATE_REQUEST = "DONATION_CREATE_REQUEST";

export const DONATION_CREATE_SUCCESS = "DONATION_CREATE_SUCCESS";

export const DONATION_CREATE_FAIL = "DONATION_CREATE_FAIL";

export const DONATION_CREATE_RESET = "DONATION_CREATE_RESET";

export const DONATION_DETAILS_REQUEST = "DONATION_DETAILS_REQUEST";

export const DONATION_DETAILS_SUCCESS = "DONATION_DETAILS_SUCCESS";

export const DONATION_DETAILS_FAIL = "DONATION_DETAILS_FAIL";

/* CONSTANTS USED IN ProductListScreen COMPONENT */
export const DONATION_ADD_REQUEST = "DONATION_ADD_REQUEST";

export const DONATION_ADD_SUCCESS = "DONATION_ADD_SUCCESS";

export const DONATION_ADD_FAIL = "DONATION_ADD_FAIL";




/* CONSTANTS USED IN ProductListScreen COMPONENT */
export const DONATION_UPDATE_REQUEST = "DONATION_UPDATE_REQUEST";

export const DONATION_UPDATE_SUCCESS = "DONATION_UPDATE_SUCCESS";

export const DONATION_UPDATE_FAIL = "DONATION_UPDATE_FAIL";
export const DONATION_UPDATE_RESETS = "DONATION_UPDATE_RESET";



/* CONSTANTS USED IN ProductListScreen COMPONENT */
export const DONATION_DELETE_REQUEST = "DONATION_DELETE_REQUEST";

export const DONATION_DELETE_SUCCESS = "DONATION_DELETE_SUCCESS";

export const DONATION_DELETE_FAIL = "DONATION_DELETE_FAIL";



export const DONATION_DELETE_RESET = "DONATION_DELETE_RESET";




/* Donation LIK  */
export const DONATION_LIKE_REQUEST = "DONATION_LIKE_REQUEST";

export const DONATION_LIKE_SUCCESS = "DONATION_LIKE_SUCCESS";

export const DONATION_LIKE_FAIL = "DONATION_LIKE_FAIL";

export const DONATION_LIKE_RESET = "DONATION_LIKE_RESET";



/* Donation UNLIK  */
export const DONATION_UNLIKE_REQUEST = "DONATION_UNLIKE_REQUEST";

export const DONATION_UNLIKE_SUCCESS = "DONATION_UNLIKE_SUCCESS";

export const DONATION_UNLIKE_FAIL = "DONATION_UNLIKE_FAIL";

export const DONATION_UNLIKE_RESET = "DONATION_UNLIKE_RESET";

