import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { listEvents } from "../../features/actions/eventActions";
import EventItem from './EventItem';
import EventLoading from '../loading/EventLoading';
import Paginate from '../dashboard/navigation/Paginate';
import Search from '../search/Search';
function EventList() {

  const dispatch = useDispatch();
  const location = useLocation();


  const [isSticky, setIsSticky] = useState(false);
  const tabRef = useRef(null);



  const eventList = useSelector((state) => state.eventList);

  const { events, page, pages, loading, error } = eventList;


  const eventLike = useSelector((state) => state.eventLike);
  const { loading: likeLoading, success: likeSuccess, error: likeError } = eventLike;


  const eventUnLike = useSelector((state) => state.eventUnLike);
  const { loading: unlikeLoading, success: unlikeSuccess, error: unlikeError } = eventUnLike;





  /* FIRING OFF THE ACTION CREATORS USING DISPATCH */

  let keyword =
    location.search; /* IF USER SEARCHES FOR ANYTHING THEN THIS KEYWORD CHANGES AND USE EFFECT GETS TRIGGERED */

  useEffect(() => {
    let _;

    dispatch(listEvents(keyword));


  }, [dispatch, keyword]);


  useEffect(() => {

    if (likeSuccess || unlikeSuccess) {
      dispatch(listEvents(keyword))
    }


  }, [dispatch, likeSuccess, unlikeSuccess, keyword])




  useEffect(() => {

    const handleScroll = () => {
      const tabPosition = tabRef.current.getBoundingClientRect().top;
      if (tabPosition <= 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);

      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll)

    }

  }, [])





  return (
    <div className=" p-1 md:p-2   flex flex-col gap-3 justify-center content-center items-center text-black  mx-auto bg-white w-full lg:mt-[100px] relative">


      <div ref={tabRef} className={isSticky ? "sticky top-0   z-30 p-1 " : "flex"}>
        <Search isSticky={isSticky} />

      </div>



      {/* center items */}
      {loading ? <div className="flex flex-col  justify-center content-center items-center gap-3 md:gap-4  w-full ">
        <EventLoading />
      </div>
        : ""}









      {events === 0 & !loading ? (
        <div className="flex flex-col  justify-center items-center content-center w-full">
          <h1 className="text-gray-500 text-md md:text-xl">No Events Found</h1>
        </div>


      ) : (
        <div className="flex flex-col  justify-center content-center items-center gap-3 md:gap-4  w-full ">
          {events?.map((event) => (
            <EventItem key={event._id} event={event} />
          )
          )}



        </div>

      )}

      {/* pagination */}
      <div className="p-2 md:p-3 mt-[20px]">
        <Paginate page={page} pages={pages} keyword={keyword} route="/events" />
      </div>








    </div>
  )
}

export default EventList