import React, { useEffect, useState } from 'react'
import { checkCredentials } from '../../../features/actions/AuthActions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Message from '../../../components/message/Message';
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs';
import { AiFillLock, AiFillMail } from 'react-icons/ai';
import { BiSolidChevronLeftCircle } from 'react-icons/bi';

function EmailChangeRequestPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const [hide, setHide] = useState(true);

  // bernadmisheto@gmail.com
  
  const credentialsCheck = useSelector((state) => state.credentialsCheck);
  const {  loading, success,  error, invalid } = credentialsCheck;


  // bernadmisheto@gmail.com

  useEffect(() => {

 if (success & !invalid) {
      navigate(`/account/email/change/`)

    } 
  }, [navigate,loading, success,  invalid])



  const submitHandler = (e) => {

    e.preventDefault();
    dispatch(
      checkCredentials(
        pwd,
        email));
  };


  const handleNextStep = () => {
    setStep(2);
  }

  const handlePreviousStep = () => {
    setStep(1);
  }

  const handleHide = () => {
    setHide(!hide)
  }
  return (
    <div className="">
    <main className="min-h-[500px] ">
      <div className="subpixel-antialiased  bg-hero-cover bg-no-repeat bg-center bg-cover  min-h-screen flex flex-row justify-around items-center font-sans    mx-auto lg:justify-around lg:align-center lg:text-centen  p-3 lg:p-4">

        <div className='flex flex-col justify-center content-center items-center w-full'>



          <div className='text-[1rem] md:text-[1.4rem] font-[500] flex flex-row items-center content-center justify-center gap-1 md:gap-2'>
            <h1>Verify your Account</h1>
            <AiFillLock />

          </div>
          {/* central div */}
          <form
            onSubmit={submitHandler}
            className='w-full md:w-[40vw] flex flex-col  justify-start content-start  items-start gap-1 md:gap-2 p-1 md:p-2'>



            {step === 1 ? (
              <>
                {/* email */}
                <div className="flex flex-col  justify-start content-start  items-start gap-1 md:gap-2 p-1 md:p-2 text-[12px] md:text-[13px]  w-full ">
                  <div className='flex flex-row items-center content-center justify-start gap-1 md:gap-2 mb-[10px]'>
                    <h1 className="font-[500] text-gray-900">
                      Email</h1>
                    <span className='text-gray-800 text-[12px] md:text-[14px]'>
                      <AiFillMail />
                    </span>
                  </div>

                  <input
                    className="p-1 md:p-2  border-b bg-none  outline-none  placeholder:text-gray-400 placeholder:text-[11px] placeholder:pl-4  w-full md:min-w-full lg:min-w-full h-[50px] md:h-[60px] text-[13px]  font-[600] "
                    type="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                  />
                </div>



                <button
                  onClick={handleNextStep}
                  className="p-2  bg-none outline-none rounded-xl bg-blue-600 text-white  hover:bg-blue-700  w-full h-[60px] text-[11px] md:text-[14px]  m-1 drop-shadow-lg shadow-blue-500/50 "
                >
                  Next
                </button>




              </>

            ) : ("")}



            {/* password */}
            {step === 2 ? (
              <>
                <div className="flex flex-col  justify-start content-start  items-start gap-1 md:gap-2 p-1 md:p-2 text-[12px] md:text-[13px]  w-full ">
                  <div className='flex flex-row items-center content-center justify-start gap-1 md:gap-2 mb-[10px]'>

                    <span><BiSolidChevronLeftCircle onClick={handlePreviousStep} className='cursor-pointer text-[1.4rem]' /></span>
                    <h1 className="font-[500] text-gray-900">
                      Password</h1>
                    <span className='text-gray-800 text-[12px] md:text-[14px]'>
                      <AiFillLock />
                    </span>



                  </div>

                  <input
                    className="p-1 md:p-2  border-b bg-none  outline-none  placeholder:text-gray-400 placeholder:text-[11px] placeholder:pl-4  w-full md:min-w-full lg:min-w-full h-[50px] md:h-[60px] text-[13px]  font-[600] "
                    type={hide ? "password" : ""}
                    required
                    value={pwd}
                    onChange={(e) => setPwd(e.target.value)}
                    placeholder="Enter your password"
                  />

                  <div className='flex flex-row justify-end content-center items-center gap-1 md:gap-2 w-full'>
                    {hide === true ? (
                      <div
                        onClick={handleHide}
                        className='flex flex-row justify-end content-center items-center gap-1 md:gap-2  cursor-pointer group text-[12px] md:text-[14px]' >
                        <h1 className='group-hover:font-[600]'>Show Password</h1>

                        <BsEyeFill />

                      </div>


                    ) : (<div
                      onClick={handleHide}
                      className='flex flex-row justify-end content-center items-center gap-1 md:gap-2  cursor-pointer group text-[12px] md:text-[14px]'>
                      <h1 className='group-hover:font-[600]'>Hide Password</h1>

                      <BsEyeSlashFill />

                    </div>)}





                  </div>
                </div>


                {/* handling invalid logins */}
                {invalid === true  ? (
                  <div className='w-full'>
                    <Message type="error">{error?.detail || error }</Message>
                  </div>
                ) : ('')}
               


                {loading ? (
                  <button

                    className="p-2  bg-none outline-none rounded-xl bg-gray-200 text-gray-400  w-full text-[11px] md:text-[14px] h-[60px] m-1  animate-pulse"
                  >
                    Verifying
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="p-2  bg-none outline-none rounded-xl bg-blue-600 text-white  hover:bg-blue-700  w-full h-[60px] text-[11px] md:text-[14px]  m-1 drop-shadow-lg shadow-blue-500/50 "
                  >
                    Verify
                  </button>

                )}







              </>

            ) : ("")}











          </form>

        </div>
      </div>
    </main>
  </div>
  )
}

export default EmailChangeRequestPage