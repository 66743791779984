import React, { useEffect, useState } from 'react'
import { REACT_APP_URL } from '../../../utilities/utils';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { AiFillLock } from 'react-icons/ai';
import axios from 'axios';
import { BsEyeFill, BsEyeSlashFill, } from 'react-icons/bs';
import Message from '../../../components/message/Message';
import { CancelAuthActions, changePassword, passwordChange } from '../../../features/actions/AuthActions';
import { logout } from '../../../features/actions/userActions';

function ChangePasswordPage() {
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();
    const [pwd, setPwd] = useState("");
    const [pwd2, setPwd2] = useState("");
    const [hide, setHide] = useState(true);
    const [changeError, setChangeError] = useState(false)
    const [codeError, setcodeError] = useState(null);

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;
     
    const credentialsCheck = useSelector((state) => state.credentialsCheck);
  const {  success: credsSuccess } = credentialsCheck;

    const passwordChange = useSelector((state) => state.passwordChange);
  const {   loading,invalid, error,success } = passwordChange;





    var token = params.token;
    var encoded_pk = params.encoded_pk;

    useEffect(() => {
    if(!credsSuccess) {
    navigate('/account/password/check')
    } 
    }, [navigate,credsSuccess])



    useEffect(() => {
    if(success) {
    dispatch(logout());
    } 
    }, [dispatch,success])
    


        const CancelPasswordChange =() =>{
     
        dispatch(CancelAuthActions())
            
        
        navigate('/account/edit')
    }


    const handlePasswordChange = async (e) => {
        e.preventDefault();
        


        setChangeError(false);



        if (pwd !== pwd2) {
            setChangeError(true);
            setcodeError("Passwords do not match")




        } else {
            dispatch(changePassword(
                pwd,
                encoded_pk,
                token
            ))

            


        }

 };



    const handleHide = () => {
        setHide(!hide)
    }

    return (
        <div className="">
            <main className="min-h-[500px] ">
                <div className="subpixel-antialiased  bg-hero-cover bg-no-repeat bg-center bg-cover  min-h-screen flex flex-row justify-around items-center font-sans    mx-auto lg:justify-around lg:align-center lg:text-centen  p-3 lg:p-4">

                    <div className='flex flex-col justify-center content-center items-center w-full'>
                       




                        <div className='text-[1rem] md:text-[1.4rem] font-[500] flex flex-row items-center content-center justify-center gap-1 md:gap-2 mb-[20px]'>
                            <h1>New Password</h1>
                            <AiFillLock />

                        </div>
                        {/* central div */}
                        <form
                            onSubmit={handlePasswordChange}
                            className='w-full md:w-[40vw] flex flex-col  justify-start content-start  items-start gap-1 md:gap-2 p-1 md:p-2'>


                            {/* new password */}
                            <div className="flex flex-col  justify-start content-start  items-start gap-1 md:gap-2 p-1 md:p-2 text-[12px] md:text-[13px]  w-full ">
                                <div className='flex flex-row items-center content-center justify-start gap-1 md:gap-2 mb-[10px]'>


                                    <h1 className="font-[500] text-gray-900">
                                        New Password</h1>
                                    <span className='text-gray-800 text-[12px] md:text-[14px]'>
                                        <AiFillLock />
                                    </span>



                                </div>

                                <input
                                    className="p-1 md:p-2  border-b bg-none  outline-none  placeholder:text-gray-400 placeholder:text-[11px] placeholder:pl-4  w-full md:min-w-full lg:min-w-full h-[50px] md:h-[60px] text-[13px]  font-[600] "
                                    type={hide ? "password" : ""}
                                    required
                                    autocomplete="false"
                                    value={pwd}
                                    onChange={(e) => setPwd(e.target.value)}
                                    placeholder="New password"
                                />


                            </div>

                            {/* confirm new password */}
                            <div className="flex flex-col  justify-start content-start  items-start gap-1 md:gap-2 p-1 md:p-2 text-[12px] md:text-[13px]  w-full ">
                                <div className='flex flex-row items-center content-center justify-start gap-1 md:gap-2 mb-[10px]'>


                                    <h1 className="font-[500] text-gray-900">
                                        Confirm New Password</h1>
                                    <span className='text-gray-800 text-[12px] md:text-[14px]'>
                                        <AiFillLock />
                                    </span>



                                </div>

                                <input
                                    className="p-1 md:p-2  border-b bg-none  outline-none  placeholder:text-gray-400 placeholder:text-[11px] placeholder:pl-4  w-full md:min-w-full lg:min-w-full h-[50px] md:h-[60px] text-[13px]  font-[600] "
                                    type={hide ? "password" : ""}
                                    required
                                    value={pwd2}
                                    onChange={(e) => setPwd2(e.target.value)}
                                    placeholder="Confirm new password"
                                />


                            </div>


                            {/* hanlding error */}
                            {invalid === true || error ? (
                                <div className='w-full'>
                                    <Message type="error">{error?.detail || error}</Message>
                                </div>
                            ) : ('')}


                            {/* hanlding error */}
                            {changeError === true ? (
                                <div className='w-full'>
                                    <Message type="error">{codeError}</Message>
                                </div>
                            ) : ('')}





                        <div className='flex flex-row justify-between w-full items-center content-center p-1 md:p-2'>
                         {/* Cancel    */}

                            <div
                            onClick={CancelPasswordChange}
                             className='flex flex-row  items-center justify-center content-center gap-1  cursor-pointer  border rounded-full  text-[0.8rem] md:text-[1rem] w-[120px] p-1 text-red-500 text-center hover:bg-red-600 hover:text-white hover:border-red-600'>
                               
                                <h1>Cancel</h1>
                            </div>

                        


                        {/* show and hid */}
                        <div className='flex flex-row justify-end content-center items-center gap-1 md:gap-2 w-full'>
                                {hide === true ? (
                                    <div
                                        onClick={handleHide}
                                        className='flex flex-row justify-end content-center items-center gap-1 md:gap-2  cursor-pointer group text-[12px] md:text-[14px]' >
                                        <h1 className='group-hover:font-[600]'>Show Password</h1>

                                        <BsEyeFill />

                                    </div>


                                ) : (<div
                                    onClick={handleHide}
                                    className='flex flex-row justify-end content-center items-center gap-1 md:gap-2  cursor-pointer group text-[12px] md:text-[14px]'>
                                    <h1 className='group-hover:font-[600]'>Hide Password</h1>

                                    <BsEyeSlashFill />

                                </div>)}





                            </div>

                        </div>

                           


                            {loading ? (
                                <button

                                    className="p-2  bg-none outline-none rounded-xl bg-gray-200 text-gray-400  w-full text-[11px] md:text-[14px] h-[60px] m-1  animate-pulse"
                                >
                                    Changing Password
                                </button>
                            ) : (
                                <button
                                    type="submit"
                                    className="p-2  bg-none outline-none rounded-xl bg-blue-600 text-white  hover:bg-blue-700  w-full h-[60px] text-[11px] md:text-[14px]  m-1 drop-shadow-lg shadow-blue-500/50 "
                                >
                                    Change Password
                                </button>

                            )}














                        </form>

                    </div>
                </div>
            </main>
        </div>
    )
}

export default ChangePasswordPage