/* CONSTANTS USED IN HomeScreen COMPONENT */
export const EVENT_COMMENT_LIST_REQUEST = "EVENT_COMMENT__LIST_REQUEST";

export const EVENT_COMMENT_LIST_SUCCESS = "EVENT_COMMENT_LIST_SUCCESS";

export const EVENT_COMMENT_LIST_FAIL = "EVENT_COMMENT_LIST_FAIL";

/* CONSTANTS USED IN FEEDScreen COMPONENT */
export const EVENT_COMMENT_DETAILS_REQUEST = "EVENT_COMMENT_DETAILS_REQUEST";

export const EVENT_COMMENT_DETAILS_SUCCESS = "EVENT_COMMENT_DETAILS_SUCCESS";

export const EVENT_COMMENT_DETAILS_FAIL = "EVENT_COMMENT_DETAILS_FAIL";

/* CONSTANTS USED IN ProductListScreen COMPONENT */
export const EVENT_COMMENT_DELETE_REQUEST = "EVENT_COMMENT_DELETE_REQUEST";

export const EVENT_COMMENT_DELETE_SUCCESS = "EVENT_COMMENT_DELETE_SUCCESS";

export const EVENT_COMMENT_DELETE_FAIL = "EVENT_COMMENT_DELETE_FAIL";

/* CONSTANTS USED IN ProductListScreen COMPONENT */
export const EVENT_COMMENT_CREATE_REQUEST = "EVENT_COMMENT_CREATE_REQUEST";

export const EVENT_COMMENT_CREATE_SUCCESS = "EVENT_COMMENT_CREATE_SUCCESS";

export const EVENT_COMMENT_CREATE_FAIL = "EVENT_COMMENT_CREATE_FAIL";

export const EVENT_COMMENT_CREATE_RESET = "EVENT_COMMENT_CREATE_RESET";

/* CONSTANTS USED IN ProductEditScreen COMPONENT */
export const EVENT_COMMENT_UPDATE_REQUEST = "EVENT_COMMENT_UPDATE_REQUEST";

export const EVENT_COMMENT_UPDATE_SUCCESS = "EVENT_COMMENT_UPDATE_SUCCESS";

export const EVENT_COMMENT_UPDATE_FAIL = "EVENT_COMMENT_UPDATE_FAIL";

export const EVENT_COMMENT_UPDATE_RESET = "EVENT_COMMENT_UPDATE_RESET";
