import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { register } from "../../features/actions/userActions";
import { BsCheck, BsCheckCircleFill, BsGlobeEuropeAfrica, BsPerson, BsPersonFill } from "react-icons/bs";
import { AiFillEye, AiFillLock, AiFillMail, AiTwotoneEyeInvisible } from "react-icons/ai";
import { BiSolidChevronLeftCircle, BiSolidChevronRightCircle } from "react-icons/bi";
import { emailAvailability, emailAvailibility, usernameAvailability, usernameAvailibility } from "../../features/actions/AuthActions";
import Message from "../../components/message/Message";
import Loading from "../../components/loading/Loading";

function Register() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  /* STATE */
  const [step, setStep] = useState(1);
  const [username, setUsername] = useState("");
  const [usernameAccepted, setUsernameAccepted] = useState("");
  const [stickername, setStickername] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [country, setCountry] = useState("");
  const [email, setEmail] = useState(null);
  const [emailAccepted, setEmailAccepted] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setpassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [passwordError, setpasswordError] = useState(false);
  const [UsernameMessage, setUsernameMessage] = useState("");


  const [hide, setHide] = useState(true);


  const redirect = "/";

  const userRegister = useSelector((state) => state.userRegister);

  const { userInfo, success, loading, error } = userRegister;

  const usernameAvailabilityCheck = useSelector((state) => state.usernameAvailabilityCheck);

  const { loading: usernameAvailabilityLoading, success: usernameAvailabilityCheckSuccess, error: usernameAvailabilityError, invalid: usernameAvailabilityCheckInvalid, message: usernameAvailabilityCheckMessage
  } = usernameAvailabilityCheck;

  const emailAvailabilityCheck = useSelector((state) => state.emailAvailabilityCheck);

  const { loading: emailAvailabilityLoading,
    success: emailAvailabilityCheckSuccess, error: emailAvailabilityError, invalid: emailAvailabilityCheckInvalid,
    message: emailAvailabilityCheckMessage
  } = emailAvailabilityCheck;




  useEffect(() => {
    if (success) {
      navigate("/login");
    }
  }, [navigate, userInfo, success, redirect]);





  useEffect(() => {
    if (emailAvailabilityCheckSuccess) {
      setEmailAccepted(emailAvailabilityCheckMessage?.email);
    }
  }, [email, emailAvailabilityCheckSuccess, emailAvailabilityCheckMessage])


  useEffect(() => {
    if (usernameAvailabilityCheckSuccess) {
      setUsernameAccepted(usernameAvailabilityCheckMessage?.username);
    }
  }, [username, usernameAvailabilityCheckSuccess, usernameAvailabilityCheckMessage])


  const countries = ["Tanzania", "Kenya", "Uganda"]

  const handleNextStep = () => {
    setStep(step + 1)
  }

  const handlePreviousStep = () => {
    setStep(step - 1);
  }

  const handleCheckEmail = (e) => {
    e.preventDefault();

    if (email === "") {
      setMessage("You must write an email")
    } if (email === emailAccepted) {
      setMessage("email is available")

    } else if (email !== null) {
      setMessage(null)
      dispatch(emailAvailability(email));

    }

  }


  const handleCheckUsername = (e) => {
    e.preventDefault();



    if (username === "") {
      setUsernameMessage("You must write a username")
    } if (username === emailAccepted) {
      setUsernameMessage("username is available")

    } else if (username !== null) {
      setUsernameMessage(null)
      dispatch(usernameAvailability(username));

    }

  }

  const handleUsernameRegex = (event) => {
    const { value } = event.target;
    const regex = /^[a-zA-Z0-9]*$/;

    if (regex.test(value)) {
      setUsername(value);
    }
  }
  const handlePasswordRegex = (event) => {
    const { value } = event.target;


    const minLength = 8;
    const maxLength = 16;
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,16}$/;

    if (value.length < minLength) {
      setpasswordError(`Passsword must be at least ${minLength} characters long`);
    } else if (value.length > maxLength) {
      setpasswordError(`Password must not exceed ${maxLength} characters`);
    } else if (!regex.test(value)) {
      setpasswordError('Password must include at least one uppercase letter,one lowercase letter,one number and one special character');
    } else {
      setpasswordError("");
    }

    setpassword(value)

  }


  const submitHandler = (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setMessage("Passwords do not match");
    } else {
      dispatch(register(first_name, last_name, country, email, mobile, username, stickername, password));
    }
  };


  const handlehide = () => {
    setHide(!hide)
  }

  return (
    <div className="">
      <main className="min-h-[500px] ">
        <div className="subpixel-antialiased  bg-hero-cover bg-no-repeat bg-bottom bg-cover  min-h-screen flex flex-row justify-around items-center font-sans    mx-auto lg:justify-around lg:align-center lg:text-center  p-3 lg:p-0">
          {/* central div */}

          <div className="inset-0 w-full">
            {/* login */}
            <div>
              {/* description */}
              <div className="flex flex-col justify-center content-center items-center text-center space-y-3 p-5">
                <h1 className="text-[18px] font-bold">Your Create Account</h1>
              </div>


              <div>

              </div>

              {/* form */}

              <div>
                <div>
                  <form
                    onSubmit={submitHandler}
                    className="flex flex-col  content-center items-center space-y-4 w-full h-auto"
                  >
                    {/* Your Full Name */}

                    {step === 1 ? (<div className="flex flex-col justify-start  content-center items-start gap-1 md:gap-2 h-auto">
                      {/* first name */}
                      <div className="flex flex-col  justify-start content-start  items-start gap-1 md:gap-2 p-1 md:p-2 text-[12px] md:text-[13px]  w-full ">
                        <div className='flex flex-row items-center content-center justify-start gap-1 md:gap-2'>
                          <h1 className="font-[500] text-gray-900">
                            Firstname</h1>
                          <span className='text-gray-800 text-[12px] md:text-[14px]'>
                            <BsPersonFill />
                          </span>
                        </div>

                        <input
                          className="p-1 md:p-2  border-b bg-none outline-none  placeholder:text-gray-400 placeholder:text-[11px] placeholder:pl-4   w-[90vw]  sm:w-[70vw] md:max-w-[400px] h-[50px] md:h-[60px] text-[13px] border-gray-400  font-[400] focus:bg-white"
                          type="text"
                          required
                          value={first_name}
                          onChange={(e) => setFirstName(e.target.value)}
                          placeholder="Firstname"
                        />
                      </div>
                      {/* first name */}
                      <div className="flex flex-col  justify-start content-start  items-start gap-1 md:gap-2 p-1 md:p-2 text-[12px] md:text-[13px]  w-full ">
                        <div className='flex flex-row items-center content-center justify-start gap-1 md:gap-2'>
                          <h1 className="font-[500] text-gray-900">
                            Lastname</h1>
                          <span className='text-gray-800 text-[12px] md:text-[14px]'>
                            <BsPersonFill />
                          </span>
                        </div>

                        <input
                          className="p-1 md:p-2  border-b bg-none outline-none  placeholder:text-gray-400 placeholder:text-[11px] placeholder:pl-4   w-[90vw]  sm:w-[70vw] md:max-w-[400px] h-[50px] md:h-[60px] text-[13px] border-gray-400  font-[400] focus:bg-white"
                          type="text"
                          value={last_name}
                          required
                          onChange={(e) => setLastName(e.target.value)}
                          placeholder="Lastname"
                        />
                      </div>
                      {/* country */}
                      <div className="flex flex-col  justify-start content-start  items-start gap-1 md:gap-2 p-1 md:p-2 text-[12px] md:text-[13px]  w-full ">
                        <div className='flex flex-row items-center content-center justify-start gap-1 md:gap-2'>
                          <h1 className="font-[500] text-gray-900">
                            Country</h1>
                          <span className='text-gray-800 text-[12px] md:text-[14px]'>
                            <BsGlobeEuropeAfrica />
                          </span>
                        </div>




                        <select
                          required
                          value={country}
                          onChange={(e) => setCountry(e.target.value)}
                          placeholder="country"
                          className="p-1 md:p-2  border-b bg-none outline-none  placeholder:text-gray-400 placeholder:text-[11px] placeholder:pl-4   w-[90vw]  sm:w-[70vw] md:max-w-[400px] h-[50px] md:h-[60px] text-[13px] border-gray-400  font-[400] focus:bg-white">
                          <option>Choose a country</option>
                          {countries.map((country, index) =>
                            <option key={index}>{country}</option>
                          )}

                        </select>



                      </div>
                      <button
                        onClick={handleNextStep}
                        className="p-2  bg-none outline-none rounded-xl bg-blue-600 text-white  hover:bg-blue-700  w-full h-[60px] text-[11px] md:text-[14px]  m-1 drop-shadow-lg shadow-blue-500/50 "
                      >
                        Next
                      </button>



                    </div>) : ""}



                    {step === 2 ? (<div className="flex flex-col justify-start  content-center items-start gap-1 md:gap-2 h-auto">
                      <div className="flex flex-row justify-between  content-center items-center w-full gap-1 md:gap-2 ">
                        <div onClick={handlePreviousStep} className="flex flex-row self-start justify-start  items-center content-center gap-1 md:gap-2 cursor-pointer text-[12px] md:text-[14px] mb-[10px] text-blue-600 hover:text-blue-500">

                          <span className=""><BiSolidChevronLeftCircle className='cursor-pointer text-[1.4rem]' /></span>
                          <h1>Previous Step</h1>

                        </div>

                        {emailAvailabilityCheckSuccess & email === emailAccepted ? (
                          <div onClick={handleNextStep} className="flex flex-row self-end justify-start  items-center content-center gap-1 md:gap-2 cursor-pointer text-[12px] md:text-[14px] mb-[10px] text-blue-600 hover:text-blue-500">
                            <h1>Next Step</h1>

                            <span className=""><BiSolidChevronRightCircle className='cursor-pointer text-[1.4rem]' /></span>


                          </div>

                        ) : ""}






                      </div>


                      {/* email */}

                      <div className="flex flex-col  justify-start content-start  items-start gap-1 md:gap-2 p-1 md:p-2 text-[12px] md:text-[13px]  w-full ">
                        <div className='flex flex-row items-center content-center justify-start gap-1 md:gap-2'>
                          <h1 className="font-[500] text-gray-900">
                            Email</h1>
                          <span className='text-gray-800 text-[12px] md:text-[14px]'>
                            <AiFillMail />
                          </span>
                          <span className="text-red-600">
                            {message ? (<>{message}</>) : ""}
                          </span>

                        </div>


                        <input
                          className="p-1 md:p-2  border-b bg-none outline-none  placeholder:text-gray-400 placeholder:text-[11px] placeholder:pl-4   w-[90vw]  sm:w-[70vw] md:max-w-[400px] h-[50px] md:h-[60px] text-[13px] border-gray-400  font-[400] focus:bg-white"
                          type="email"
                          required
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Your email"
                        />

                        {emailAvailabilityCheckSuccess ? (
                          <div className="flex flex-row justify-start items-center content-center gap-1 md:gap-2 text-[12px] md:text-[14px]">
                            <BsCheckCircleFill className="text-green-600" />

                            <span className="text-green-600">{emailAccepted}</span>
                          </div>

                        ) : ""}


                        {/* handling success and not changing email */}
                        {emailAvailabilityCheckSuccess ? (
                          <div className="w-full">
                            <Message type="success">{emailAvailabilityCheckMessage?.detail}</Message></div>
                        ) : ("")}

                        {/* handling errors and changing email */}
                        {emailAvailabilityCheckInvalid === true || emailAvailabilityError ? (
                          <div className="w-full"><Message type="error">{emailAvailabilityError?.detail || emailAvailabilityError}</Message> </div>
                        ) : ("")}
                      </div>


                      {/* mobile */}
                      <div className="flex flex-col  justify-start content-start  items-start gap-1 md:gap-2 p-1 md:p-2 text-[12px] md:text-[13px]  w-full ">
                        <div className='flex flex-row items-center content-center justify-start gap-1 md:gap-2'>
                          <h1 className="font-[500] text-gray-900">
                            Mobile</h1>
                          <span className='text-gray-800 text-[12px] md:text-[14px]'>
                            <BsPersonFill />
                          </span>
                        </div>

                        <input
                          className="p-1 md:p-2  border-b bg-none outline-none  placeholder:text-gray-400 placeholder:text-[11px] placeholder:pl-4   w-[90vw]  sm:w-[70vw] md:max-w-[400px] h-[50px] md:h-[60px] text-[13px] border-gray-400  font-[400] focus:bg-white"
                          type="number"
                          value={mobile}
                          onChange={(e) => setMobile(e.target.value)}
                          placeholder="Your Mobile(optional)"
                        />
                      </div>




                      {emailAvailabilityLoading === true ? (
                        <div

                          className="p-2  animate-pulse  bg-none outline-none rounded-xl bg-blue-400 text-white   text-center flex flx-row items-center content-center justify-center  w-full h-[60px] text-[11px] md:text-[14px]  m-1 drop-shadow-lg shadow-blue-500/50 "
                        >
                          Checking Email
                        </div>
                      ) : (<div
                        onClick={(e) => handleCheckEmail(e)}
                        className="p-2 cursor-pointer  bg-none outline-none rounded-xl bg-blue-600 text-white  hover:bg-blue-700 text-center flex flx-row items-center content-center justify-center  w-full h-[60px] text-[11px] md:text-[14px]  m-1 drop-shadow-lg shadow-blue-500/50 "
                      >
                        Check
                      </div>)}






                      {/* {emailAvailabilityCheckSuccess & emailAccepted === email ? (
                        <div
                        onClick={handleNextStep}
                        className="p-2 cursor-pointer  bg-none outline-none rounded-xl bg-blue-600 text-white  hover:bg-blue-700 text-center flex flx-row items-center content-center justify-center  w-full h-[60px] text-[11px] md:text-[14px]  m-1 drop-shadow-lg shadow-blue-500/50 "
                      >
                        Continue
                      </div>

                      ): ""} */}



                    </div>) : ""}




                    {step === 3 ? (<div className="flex flex-col justify-start  content-center items-start gap-1 md:gap-2 h-auto">
                      <div className="flex flex-row justify-between  content-center items-center w-full gap-1 md:gap-2 ">
                        <div onClick={handlePreviousStep} className="flex flex-row self-start justify-start  items-center content-center gap-1 md:gap-2 cursor-pointer text-[12px] md:text-[14px] mb-[10px] text-blue-600 hover:text-blue-500">

                          <span className=""><BiSolidChevronLeftCircle className='cursor-pointer text-[1.4rem]' /></span>
                          <h1>Previous Step</h1>

                        </div>

                        {usernameAvailabilityCheckSuccess & username === usernameAccepted ? (
                          <div onClick={handleNextStep} className="flex flex-row self-end justify-start  items-center content-center gap-1 md:gap-2 cursor-pointer text-[12px] md:text-[14px] mb-[10px] text-blue-600 hover:text-blue-500">
                            <h1>Next Step</h1>

                            <span ><BiSolidChevronRightCircle className='cursor-pointer text-[1.4rem]' /></span>


                          </div>

                        ) : ""}






                      </div>


                      {/* username */}
                      <div className="flex flex-col  justify-start content-start  items-start gap-1 md:gap-2 p-1 md:p-2 text-[12px] md:text-[13px]  w-full ">

                        <div className='flex flex-row items-center content-center justify-start gap-1 md:gap-2'>
                          <h1 className="font-[500] text-gray-900">
                            Username</h1>
                          <span className='text-gray-800 text-[12px] md:text-[14px]'>
                            <BsPersonFill />
                          </span>
                          <span className="text-red-600">
                            {UsernameMessage ? (<>{UsernameMessage}</>) : ""}
                          </span>
                        </div>

                        <input
                          className="p-1 md:p-2  border-b bg-none outline-none  placeholder:text-gray-400 placeholder:text-[11px] placeholder:pl-4   w-[90vw]  sm:w-[70vw] md:max-w-[400px] h-[50px] md:h-[60px] text-[13px] border-gray-400  font-[400] focus:bg-white"
                          type="text"
                          value={username}
                          required
                          onChange={handleUsernameRegex}
                          placeholder="username (unique)"
                        />
                        <p className="text-[11px] md:text-[12px] bg-gray-50 p-1 text-left rounded text-gray-500">Only Aphanumeric Characters</p>
                        {usernameAvailabilityCheckSuccess ? (
                          <div className="flex flex-row justify-start items-center content-center gap-1 md:gap-2 text-[12px] md:text-[14px]">
                            <BsCheckCircleFill className="text-green-600" />

                            <span className="text-green-600">{usernameAccepted}</span>
                          </div>

                        ) : ""}

                        {/* handling success and not changing email */}
                        {usernameAvailabilityCheckSuccess ? (
                          <div className="w-full">
                            <Message type="success">{usernameAvailabilityCheckMessage?.detail}</Message></div>
                        ) : ("")}

                        {/* handling errors and changing email */}
                        {usernameAvailabilityCheckInvalid === true || usernameAvailabilityError ? (
                          <div className="w-full"><Message type="error">{usernameAvailabilityError?.detail || usernameAvailabilityError}</Message> </div>
                        ) : ("")}

                      </div>



                      {/* stickr name */}
                      <div className="flex flex-col  justify-start content-start  items-start gap-1 md:gap-2 p-1 md:p-2 text-[12px] md:text-[13px]  w-full ">
                        <div className='flex flex-row items-center content-center justify-start gap-1 md:gap-2'>
                          <h1 className="font-[500] text-gray-900">
                            Sticker Name</h1>
                          <span className='text-gray-800 text-[12px] md:text-[14px]'>
                            <BsPersonFill />
                          </span>
                        </div>

                        <input
                          className="p-1 md:p-2  border-b bg-none outline-none  placeholder:text-gray-400 placeholder:text-[11px] placeholder:pl-4   w-[90vw]  sm:w-[70vw] md:max-w-[400px] h-[50px] md:h-[60px] text-[13px] border-gray-400  font-[400] focus:bg-white"
                          type="text"
                          required
                          value={stickername}
                          onChange={(e) => setStickername(e.target.value)}
                          placeholder="sticker name"
                        />
                      </div>

                      {usernameAvailabilityLoading === true ? (
                        <div

                          className="p-2  animate-pulse  bg-none outline-none rounded-xl bg-blue-400 text-white   text-center flex flx-row items-center content-center justify-center  w-full h-[60px] text-[11px] md:text-[14px]  m-1 drop-shadow-lg shadow-blue-500/50 "
                        >
                          Checking Username
                        </div>
                      ) : (<div
                        onClick={(e) => handleCheckUsername(e)}
                        className="p-2 cursor-pointer  bg-none outline-none rounded-xl bg-blue-600 text-white  hover:bg-blue-700 text-center flex flx-row items-center content-center justify-center  w-full h-[60px] text-[11px] md:text-[14px]  m-1 drop-shadow-lg shadow-blue-500/50 "
                      >
                        Check
                      </div>)}



                    </div>) : ""}
                    {step === 4 ? (<div className="flex flex-col justify-start  content-center items-start gap-1 md:gap-2 ">
                      <div onClick={handlePreviousStep} className="flex flex-row justify-start  items-center content-center gap-1 md:gap-2 cursor-pointer text-[12px] md:text-[14px] mb-[10px] text-blue-600 hover:text-blue-500">

                        <span className=""><BiSolidChevronLeftCircle className='cursor-pointer text-[1.4rem]' /></span>
                        <h1>Previous Step</h1>

                      </div>

                      {/* password */}
                      <div className="flex flex-col  justify-start content-start  items-start gap-1 md:gap-2 p-1 md:p-2 text-[12px] md:text-[13px]  w-full ">
                        <div className='flex flex-row items-center content-center justify-start gap-1 md:gap-2'>
                          <h1 className="font-[500] text-gray-900">
                            Password</h1>
                          <span className='text-gray-800 text-[12px] md:text-[14px]'>
                            <AiFillLock />
                          </span>
                        </div>

                        <input
                          className="p-1 md:p-2  border-b bg-none outline-none  placeholder:text-gray-400 placeholder:text-[11px] placeholder:pl-4   w-[90vw]  sm:w-[70vw] md:max-w-[400px] h-[50px] md:h-[60px] text-[13px] border-gray-400  font-[400] focus:bg-white"
                          type={hide ? "password" : ""}
                          required
                          value={password}
                          onChange={handlePasswordRegex}
                          placeholder="Password"
                        />
                      </div>
                      {/* confirm password */}
                      <div className="flex flex-col  justify-start content-start  items-start gap-1 md:gap-2 p-1 md:p-2 text-[12px] md:text-[13px]  w-full ">
                        <div className='flex flex-row items-center content-center justify-start gap-1 md:gap-2'>
                          <h1 className="font-[500] text-gray-900">
                            Confirm Password</h1>
                          <span className='text-gray-800 text-[12px] md:text-[14px]'>
                            <AiFillLock />
                          </span>
                        </div>

                        <input
                          className="p-1 md:p-2  border-b bg-none outline-none  placeholder:text-gray-400 placeholder:text-[11px] placeholder:pl-4   w-[90vw]  sm:w-[70vw] md:max-w-[400px] h-[50px] md:h-[60px] text-[13px] border-gray-400  font-[400] focus:bg-white"
                          type={hide ? "password" : ""}
                          value={confirmPassword}
                          required
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          placeholder="Confirm Password"
                        />


                        <div className="flex flex-row justify-end  w-[90vw]  sm:w-[70vw] md:max-w-[400px] h-[20px] mb-[10px]">
                         <div
                          onClick={handlehide}
                          className=" flex flex-row justify-center items-center content-center gap-1 md:gap-2 text-[11px] md:text-[12px] cursor-pointer self-end hover:text-gray-700 text-gray-500">
                          <h1>{hide ? "Show" : "Hide"} Password</h1>
                          <span className=" text-[11px] md:text-[12px] ">
                            {hide ? <AiFillEye /> : <AiTwotoneEyeInvisible />}

                          </span>

                        </div>
                        </div>

                       


                    
                    </div>


                      {message ? (<div>{message}</div>) : ""}


                    {passwordError ? (<Message type="error">{passwordError}</Message>) : ""}
                    {error ? (<Message type="error">{error}</Message>) : ""}


                    {loading ? (
                      <div

                        className="p-2 flex flex-row items-center content-center justify-center  bg-none outline-none rounded-xl bg-gray-100 text-white   w-full h-[60px] text-[11px] md:text-[14px]  m-1  "
                      >
                        <Loading />
                      </div>
                    ) : (
                      <button
                        type="submit"
                        className="p-2  bg-none outline-none rounded-xl bg-blue-600 text-white  hover:bg-blue-700  w-full h-[60px] text-[11px] md:text-[14px]  m-1 drop-shadow-lg shadow-blue-500/50 "
                      >
                        Create Account
                      </button>

                    )}





                </div>) : ""}



              </form>
            </div>
          </div>

          {/* Register */}
          <div className="flex flex-col justify-center content-center items-center text-center space-y-3 p-5">
            <h1 className="text-[15px] ">
              Already have an account?{" "}
              <span className="font-bold">
                <Link to="/login">Sign In</Link>
              </span>
            </h1>
          </div>
        </div>
    </div>
        </div >
      </main >
    </div >
  );
}

export default Register;
