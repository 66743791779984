import React, { useEffect, useState } from "react";
import { BsChevronDown, BsChevronLeft, BsChevronUp, BsTwitterX, BsXCircleFill } from "react-icons/bs";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { likePost, listPostDetails, unlikePost } from "../../features/actions/postActions";
import PostComments from "../../components/posts/PostComments";
import CommentForm from "../../components/posts/CommentForm";
import PostPoll from "../../components/posts/PostPoll";
import Charts from "../../components/posts/Charts";
import { AiFillHeart, AiOutlineClockCircle, AiOutlineComment, AiOutlineHeart, AiOutlineShareAlt } from "react-icons/ai";
import { REACT_API_URL } from "../../utilities/utils";
import { format } from "timeago.js";
import { BiCopy, BiLoader, BiLogoFacebook, BiLogoWhatsapp } from "react-icons/bi";
import Loading from "../../components/loading/Loading";

const formatNumber = (num) => {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + 'M'; // 1.1M
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + 'k'; // 1.2k
  } else {
    return num;
  }
};

function PostPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();


  const url = window.location.href;

  const [showGraphs, setshowGraphs] = useState(false);
  const [WriteComment, setWriteComment] = useState(false);

  const [option, setOptions] = useState(false);
  const [shareOptions, setShareOptions] = useState(false);

  const getId = useParams();
  const postId = getId.id;

  const postDetails = useSelector((state) => state.postDetails);
  const { loading, post, error } = postDetails;


  const postLike = useSelector((state) => state.postLike);
  const { loading: likeLoading, success: likeSuccess, error: likeError } = postLike;


  const postUnLike = useSelector((state) => state.postUnLike);
  const { loading: unlikeLoading, success: unlikeSuccess, error: unlikeError } = postUnLike;

  const CurrentURL = () => {
    const url = window.location.href;
    return url;

  }



  // product detail dispatch
  useEffect(() => {
    dispatch(listPostDetails(postId));
  }, [dispatch, postId]);



  useEffect(() => {

    if (likeSuccess || unlikeSuccess) {
      dispatch(listPostDetails(postId))
    }


  }, [dispatch, likeSuccess, unlikeSuccess, postId])



  const handleLike = (id) => {
    dispatch(likePost(id));
  };


  const handleUnLike = (id) => {
    dispatch(unlikePost(id));
  };



  const handleGraphs = () => {

    setshowGraphs(!showGraphs);

  }
  const handleCommentForm = () => {

    setWriteComment(!WriteComment);

  }


  const handleOptions = (e) => {
    setOptions(!option);
  }
  const handleShareOptions = (e) => {
    setShareOptions(!shareOptions);
  }



  const handleCopy = () => {
    navigator.clipboard.writeText(url)
      .then(() => {
        alert('Text copied to clipboard')
      })
      .catch(err => {
        alert(err)
      })

  }


  return (
    <div className="subpixel-antialiased min-h-screen inset-0 flex flex-col justify-center items-center font-sans   lg:justify-center lg:align-center  p-3 mt-[10px] ">
      {/* central div */}

      {loading ? (
        <div className="flex flex-col justify-center items-center content-center text-center  gap-3 md:gap-5 p-2 md:p-3  w-full md:w-[80%] lg:w-[80%]">
          <Loading />
        </div>
      ) : (<div className="flex flex-col justify-start items-start content-start gap-2 md:gap-3  p-1 md:p-2 w-[95%] md:w-[80%]">
        {/* back button */}
        <motion.div
          onClick={() => navigate(-1)}
          className=" flex flex-row items-center content-center justify-start text-gray-500 hover:text-black w-full   gap-1 md:gap-2 cursor-pointer mb-[20px] text-[14px]"
        >
          <BsChevronLeft />
          <h1>Back</h1>
        </motion.div>


        {/* profile and name */}
        <div className="flex flex-row items-start content-start justify-start gap-2 w-full p-1 md:p-2">

          {/* image */}
          <img src={post?.User?.profile_photo ? `${REACT_API_URL}${post?.User?.profile_photo}` : "noavatar.jpg"} className="w-[50px] h-[50px] rounded-full object-cover object-center border border-gray-200" alt="profile-photo" />

          {/* name,title and time */}
          <div className="w-full flex flex-col gap-1">

            <div className="flex flex-row items-start content-start justify-between w-full">

              <div className="flex flex-col items-start content-start justify-start  h-auto">
                <h1 className="flex flex-col gap-2 text-[0.8rem] text-gray-800 font-[400]">
                  {"@"}{post?.User?.sticker_name}
                </h1>
                <p className="font-[500] text-[0.9rem]">
                  {post?.User?.username}
                </p>

              </div>

              <div className="flex flex-row items-center content-start justify-start text-[12px] text-gray-500 gap-1">

                <AiOutlineClockCircle />
                <p className="text-gray-700">
                  {format(post?.timestamp)}

                </p>


              </div>

            </div>




          </div>





        </div>

        {/* title */}
        <div className="flex flex-col justify-start items-start content-center gap-1 p-2 w-full">






          <h1 className="text-lg md:text-xl ">{post?.title}</h1>
        </div>


        {post?.cover_photo ? (
          <>
            <img src={`${REACT_API_URL}${post?.cover_photo}`} className="w-full h-[180px] sm:h-[250px] md:h-[400px] object-cover object-center rounded-md" alt="" />

          </>) : ("")}

        {/* description */}
        <div className="p-1 md:p-2">
          <p className="text-[13px] text-start">
            {post?.content}
          </p>
        </div>




        {/* link */}

        {/* {!loading && post ? (<div className="flex flex-row items-start content-start justify-start gap-1 w-full p-1 md:p-2">

          <BsGlobeEuropeAfrica className="text-gray-500 text-[12px]" />
          <Link
            target="_blank"
            to={post?.link} className="text-[10px] md:text-[12px] ">


            {post.link.length > 50 ? `${post.link.substring(0, 40)}...` : post.link}

          </Link>

        </div>) : ("")} */}



        {/* Likes,CommentsCount and Share */}
        <div className="flex flex-row items-start content-start justify-start gap-3 p-1 md:p-2 relative">
          <div className="flex flex-row items-center content-center justify-start gap-1">

            {post?.has_liked ? (<AiFillHeart onClick={() => handleUnLike(postId)} className="text-red-500 cursor-pointer text-[1.4rem]" />

            ) : (<AiOutlineHeart onClick={() => handleLike(postId)} className="cursor-pointer text-[1.4rem]" />)}



            <p className="text-[0.8rem]">{formatNumber(post?.LikesCount)}</p>
          </div>

          <div className="flex flex-row items-center content-center justify-start gap-1">
            <AiOutlineComment className="text-blue-700 cursor-pointer text-[1.4rem]" />
            <p className="text-[0.8rem]">{formatNumber(post?.CommentsCount)}</p>
          </div>

          <div
            onClick={(e) => handleShareOptions(e)}
            className="flex flex-row items-center content-center justify-start gap-1">
            <AiOutlineShareAlt className="text-green-800 cursor-pointer text-[1.4rem]" />
          </div>

          {shareOptions && (
            <motion.div
              initial={{ bottom: 15, opacity: 0 }}
              animate={{ bottom: 0, opacity: 1 }}
              exit={{ bottom: 20, opacity: 0 }}
              className="absolute bottom-[2rem] bg-white w-auto border p-1 md:p-2 rounded-lg shadow-lg">
              <div

                className="flex flex-row items-center content-center justify-between w-full text-[12px] md:text-[14px] p-1 md:p-2">
                <h1 className="font-[500] ">Share</h1>

                <BsXCircleFill onClick={(e) => handleShareOptions(e)} className="text-[1rem] cursor-pointer" />
              </div>
              <ul className="flex flex-row items-center content-center justify-evenly  gap-1 md:gap-2 p-1 md:p-2">
                <li className="flex flex-col items-center justify-center content-center gap-1 md:gap-2 p-1">
                  <div
                    onClick={() => handleCopy()}
                    className="bg-gray-100 flex flex-row items-center content-center justify-center text-center h-[40px] w-[40px] rounded-full border border-gray-200 hover:bg-gray-400 hover:border-gray-400 hover:text-white cursor-pointer">
                    <BiCopy className=" text-[1rem]" />

                  </div>

                  <h1 className="text-[11px] md:text-[12px]">Copy</h1>
                </li>
                <li className="flex flex-col items-center justify-center content-center gap-1 md:gap-2 p-1">
                  <a
                    href={`https://wa.me/?text=${encodeURIComponent('Check this out:' + url)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-gray-100 flex flex-row items-center content-center justify-center text-center h-[40px] w-[40px] rounded-full border border-gray-200 hover:text-white hover:bg-green-500 hover:border-green-500 cursor-pointer">
                    <BiLogoWhatsapp className=" text-[1rem]" />


                  </a>

                  <h1 className="text-[11px] md:text-[12px]">Whatsapp</h1>
                </li>
                <li className="flex flex-col items-center justify-center content-center gap-1 md:gap-2 p-1">
                  <a
                    href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(post?.title + '' + url)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-gray-100 flex flex-row items-center content-center justify-center text-center h-[40px] w-[40px] rounded-full border border-gray-200 hover:text-white hover:bg-blue-500 hover:border-blue-500 cursor-pointer">
                    <BsTwitterX className=" text-[1rem]" />


                  </a>

                  <h1 className="text-[11px] md:text-[12px]">Twitter</h1>
                </li>
                <li className="flex flex-col items-center justify-center content-center gap-1 md:gap-2 p-1">
                  <a
                    href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-gray-100 flex flex-row items-center content-center justify-center text-center h-[40px] w-[40px] rounded-full border border-gray-200 hover:text-white hover:bg-blue-500 hover:border-blue-500 cursor-pointer">
                    <BiLogoFacebook className=" text-[1rem]" />


                  </a>

                  <h1 className="text-[11px] md:text-[12px]">Facebook</h1>
                </li>
              </ul>


            </motion.div>)}

        </div>



        {/* polls */}
        {post?.is_poll ? (
          <PostPoll choices={post?.choices} />

        ) : ("")}




        {/* Show Graphs actions */}




        {post.is_poll ? (<div className="flex flex-row items-center content-center justify-end gap-1 text-[12px] cursor-pointer     w-full">
          <div onClick={handleGraphs} className="flex flex-row items-center content-center justify-end gap-1 text-[12px] cursor-pointer hover:bg-gray-200 rounded-md    p-1 md:p-2  ">

            {showGraphs === true ? (<BsChevronUp className="font-[600]" />) : (<BsChevronDown className="font-[600]" />)}
            {showGraphs === true ? (<h1 className="font-[500]">Hide Graphs</h1>) : (<h1 className="font-[500]">Show Graphs</h1>)}



          </div>

        </div>

        ) : ("")}

        {/* charts */}
        {post?.is_poll && showGraphs === true ? (
          <Charts postId={postId} />
        ) : (
          ""
        )}








        {/* Write a comment actions */}
        <div className="flex flex-row items-center content-center justify-end gap-1 text-[12px] cursor-pointer     w-full">
          <div onClick={handleCommentForm} className="flex flex-row items-center content-center justify-end gap-1 text-[12px] cursor-pointer hover:bg-gray-200 rounded-md    p-1 md:p-2  ">

            {WriteComment === true ? (<BsChevronUp className="font-[600]" />) : (<BsChevronDown className="font-[600]" />)}
            {WriteComment === true ? (<h1 className="font-[500]">Hide </h1>) : (<h1 className="font-[500]">Write a Comment</h1>)}



          </div>


        </div>


        {/* post comment */}
        {WriteComment ? (<CommentForm postId={post._id} />) : ("")}




        {/* comments */}
        <PostComments />

      </div>
      )}
    </div>
  );
}

export default PostPage;
