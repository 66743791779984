import React from 'react'
import MyEvents from '../../../components/account/MyEvents';
import { motion } from 'framer-motion';

function MyEventsPage() {
  return (
    <motion.main className="">
    <div className="  ">
      {/* central div */}
      <div className="">
        
      

    

        <MyEvents />

      </div>


      
    </div>
  </motion.main>
  )
}

export default MyEventsPage