import React, { useState } from "react";
import { AiOutlineClockCircle } from "react-icons/ai";
import { format } from "timeago.js";
import {  REACT_API_URL, REACT_APP_URL } from "../../utilities/utils";
import { BiDotsHorizontalRounded, BiSolidTrashAlt } from "react-icons/bi";
import { BsFillHandThumbsUpFill } from "react-icons/bs";
import axios from "axios";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { listComments } from "../../features/actions/commentActions";


const formatNumber = (num) => {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + 'M'; // 1.1M
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + 'k'; // 1.2k
  } else {
    return num;
  }
};

function CommentItem({ comment }) {

  const dispatch = useDispatch();
  const getId = useParams();
  const postId = getId.id;

  const [loading, setLoading] = useState(false);
  const [option, setOptions] = useState(false);


  const userLogin = useSelector((state) => state.userLogin);

  const { userInfo } = userLogin;

  const handleClap = async (e) => {
    e.preventDefault();
    setLoading(true);

    

    

    try {
      const config = {
        header: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `${REACT_APP_URL}/feed/comments/${comment?._id}/clap/`,
        config
      );

      dispatch(listComments(postId));
      setLoading(false);

    } catch (error) {
      setLoading(false);
    }
  };



  const handleOptions = (e) => {
    setOptions(!option);
  }


  const handleDelete = async (e) => {
    e.preventDefault();


    setLoading(true);

   

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.delete(
        `${REACT_APP_URL}/feed/comments/${comment?._id}/delete/`,
        config
      );


      setLoading(false);
      dispatch(listComments(postId));

    } catch (error) {
      setLoading(false);

    }
  };
  return (
    <div className="flex flex-col items-start justify-start content-start gap-1  p-1 md:p-2  w-full bg-gray-50 border border-gray-200 rounded-lg hover:bg-gray-100 hover:border-gray-300">
      <div className="p-1 flex flex-row items-start content-start justify-between gap-2 w-full">

        {/* profile and name */}
        <div className="flex flex-row items-start content-start justify-start gap-2 w-full p-1 md:p-2">

          {/* image */}
          <img src={`${REACT_API_URL}${comment?.User?.profile_photo}` || "noavatar.jpg"} className="w-[50px] h-[50px] rounded-full object-cover object-center border border-gray-200" alt="profile-photo" />

          {/* name,title and time */}
          <div className="w-full flex flex-col  gap-1">

            <div className="flex flex-row items-start content-center  justify-between w-full relative">

              <div className="flex flex-col items-start content-start justify-start  h-auto">
                <h1 className="flex flex-col gap-2 text-[0.8rem] text-gray-800 font-[400]">
                  {"@"}{comment?.User?.sticker_name}
                </h1>
                <p className="font-[500] text-[0.9rem]">
                  {comment?.User?.username}
                </p>

              </div>

              <div className='w-full flex items-center content-center justify-end'>
                {userInfo?.id === comment?.user ? (

                  <div

                    onClick={handleOptions}
                    className="border border-gray-400 rounded-full p-[2px] text-center text-black cursor-pointer hover:bg-gray-600 hover:text-white hover:border-gray-600 ">
                    <BiDotsHorizontalRounded

                      className="text-[0.8rem] md:text-[14px]" />

                  </div>

                ) : ""}


                {option ? (<div className="absolute bg-white border top-6 right-1 rounded-md p-1 md:p-2 drop-shadow-xl text-[12px] md:text-[14px] min-w-[150px] md:min-w-[200px] z-30">
                  <ul className="flex flex-col justify-evenly items-end  gap-2 md:gap-3 w-full">
                    <li
                      onClick={(e) => handleDelete(e)}
                      className="flex flex-row justify-between content-center items-center gap-2 p-1 hover:bg-gray-600 hover:text-white group cursor-pointer rounded-sm w-full">
                      <h1>Delete this comment</h1>
                      <BiSolidTrashAlt className="group-hover:text-red-600" />
                    </li>

                  </ul>


                </div>) : ""}

              </div>



            </div>




          </div>





        </div>
      </div>

      <div className="w-full text-[12px] md:text-[13px] p-2 md:p-3">

        {comment?.content}

      </div>

      <div className="flex flex-row items-start content-start justify-between p-1 md:p-2 w-full">

        {loading ? (
          <div className="flex flex-row items-center content-center justify-start gap-1 animate-pulse">


            <h1 >  <BsFillHandThumbsUpFill className="text-blue-400" /></h1>




            <p className="text-[0.8rem]">{formatNumber(comment?.claps)}</p>
          </div>

        ) : (
          <div className="flex flex-row items-center content-center justify-start gap-1">
            <h1 onClick={(e) => handleClap(e)} className="cursor-pointer">  <BsFillHandThumbsUpFill className="text-blue-600" /></h1>
            <p className="text-[0.8rem]">{formatNumber(comment?.claps)}</p>
          </div>

        )}

        <div className="flex flex-row items-center content-start justify-start text-[12px] text-gray-500 gap-1 self-end">

          <AiOutlineClockCircle />
          <p className="text-gray-700">
            {format(comment?.timestamp)}

          </p>
        </div>
      </div>
    </div>
  );
}

export default CommentItem;
