import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import PostItem from "./PostItem";
import { useDispatch, useSelector } from "react-redux";
import { useLocation,  useParams } from "react-router";
import {  listPosts } from "../../features/actions/postActions";
import PostLoading from "../loading/PostLoading";
import Paginate from "../dashboard/navigation/Paginate";
import Search from "../search/Search";

function Posts() {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();


  const [isSticky, setIsSticky] = useState(false);
  const tabRef = useRef(null);


  const pollUpdate = useSelector((state) => state.pollUpdate);

  const { loading: pollLoading, success:pollSuccess, error: pollError } = pollUpdate;

  const postLike = useSelector((state) => state.postLike);
  const { loading: likeLoading, success: likeSuccess,error: likeError  } = postLike;


  const postUnLike = useSelector((state) => state.postUnLike);
  const { loading: unlikeLoading, success: unlikeSuccess,error: unlikeError  } = postUnLike;





  const postList = useSelector((state) => state.postList);

  const { posts, page, pages, loading, error } = postList;

  const { search } = useLocation();

  const route = location.route;

  /* FIRING OFF THE ACTION CREATORS USING DISPATCH */

  let keyword =
    location.search; /* IF USER SEARCHES FOR ANYTHING THEN THIS KEYWORD CHANGES AND USE EFFECT GETS TRIGGERED */

  useEffect(() => {
    let _;

    dispatch(listPosts(keyword));
  }, [dispatch, keyword]);


  useEffect(() => {

    if(pollSuccess || likeSuccess || unlikeSuccess){
      dispatch(listPosts(keyword))
    }


  },[dispatch, pollSuccess,likeSuccess, unlikeSuccess, keyword])


  useEffect(() => {

    const handleScroll = () => {
        const tabPosition = tabRef.current.getBoundingClientRect().top;
        if (tabPosition <= 0) {
            setIsSticky(true);
        } else {
            setIsSticky(false);

        }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
        window.removeEventListener("scroll", handleScroll)

    }

}, [])


  return (
    <motion.div className=" p-1 md:p-2   flex flex-col gap-3 justify-center content-center items-center text-black  mx-auto bg-white lg:mt-[100px] relative">


      <div ref={tabRef} className={isSticky ? "sticky top-0   z-30 p-1 " : "flex"}>
       <Search isSticky={isSticky} />

      </div>

    
      

   

      {/* center items */}
      {loading ? <PostLoading /> : ""}

      {(posts === 0) & !loading ? (
        <div className="flex flex-col  justify-center items-center content-center w-full">
        <h1 className="text-gray-500 text-md md:text-xl">No Posts Found</h1>
      </div>
      ) : (
        <div className="flex flex-col justify-center content-center items-center gap-2 md:gap-3   w-full md:min-w-[400px]">
          {posts?.map((post) => (
            <PostItem key={post._id} post={post} />
          ))}
        </div>
      )}

      {/* pagination */}
      <div className="p-2 md:p-3 mt-[20px]">
        <Paginate page={page} pages={pages} keyword={keyword} route="/" />
      </div>

    
    </motion.div>
  );
}

export default Posts;
