import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import Message from "../../components/message/Message";
import { Link } from "react-router-dom";
import LoginLoading from "../../components/loading/LoginLoading";
import { login } from "../../features/actions/userActions";
import Loading from "../../components/loading/Loading";
import { AiFillEye, AiTwotoneEyeInvisible } from "react-icons/ai";

function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [hide, setHide] = useState(true);

  const dispatch = useDispatch();
	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo, loading, error } = userLogin;

	
	const redirect = location.state?.from || "/";

  useEffect(() => {
    if (userInfo) {
      navigate(redirect);
    }
  }, [navigate, userInfo, redirect]);

	const submitHandler = (e) => {
		e.preventDefault();
		dispatch(login(email, password));
    
		
	};
  

  const handleHide = () => {
    setHide(!hide);
  };

  return (
    <div className="">
      <main className="min-h-[500px]">
        <div className="subpixel-antialiased bg-hero-cover bg-no-repeat bg-center bg-cover min-h-screen flex flex-row justify-around items-center font-sans mx-auto lg:justify-around lg:align-center lg:text-centen p-3 lg:p-0">
          {/* central div */}
          <div className="inset-0 w-full">
            {/* login */}
            <div>
              {/* description */}
              <div className="flex flex-col justify-center content-center items-center text-center space-y-3 p-5">
                <h1 className="text-[18px] font-bold">Login</h1>
              </div>

              {/* form */}
              <div>
               
                  <form
                    onSubmit={submitHandler}
                    className="flex flex-col justify-start content-center items-center gap-1 md:gap-2 w-full"
                  >
                    <input
                      className="p-1 md:p-2 border-b bg-none outline-none placeholder:text-gray-400 placeholder:text-[11px] placeholder:pl-4 w-[90vw] sm:w-[70vw] md:max-w-[400px] h-[50px] md:h-[60px] text-[13px] border-gray-400 font-[400]"
                      type="email"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Your email"
                    />

                    <input
                      className="p-1 md:p-2 border-b bg-none outline-none placeholder:text-gray-400 placeholder:text-[11px] placeholder:pl-4 w-[90vw] sm:w-[70vw] md:max-w-[400px] h-[50px] md:h-[60px] text-[13px] border-gray-400 font-[400] focus:bg-white"
                      type={hide ? "password" : "text"}
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Your Password"
                    />
                    <div className="flex flex-row justify-end w-[90vw] sm:w-[70vw] md:max-w-[400px] h-[20px]">
                      <div
                        onClick={handleHide}
                        className="flex flex-row justify-center items-center content-center gap-1 md:gap-2 text-[11px] md:text-[12px] cursor-pointer self-end hover:text-gray-700 text-gray-500"
                      >
                        <h1>{hide ? "Show" : "Hide"} Password</h1>
                        <span className="text-[11px] md:text-[12px]">
                          {hide ? <AiFillEye /> : <AiTwotoneEyeInvisible />}
                        </span>
                      </div>
                    </div>

                    

                    {/* message */}
                    {error && <Message type={"error"}>{error}</Message>}

                    {/* button */}
                    {loading ? (
                      <div className="p-2 bg-none outline-none rounded-xl   min-w-[300px] md:min-w-[400px] flex flex-row items-center content-center justify-center h-[60px] text-[13px] md:text-[16px] m-1  ">
                      <Loading />
                    </div>
                    ) : (
                      <button
                        type="submit"
                        className="p-2 bg-none outline-none rounded-xl bg-blue-600 text-white hover:bg-blue-700 min-w-[300px] md:min-w-[400px] h-[60px] text-[13px] md:text-[16px] m-1 drop-shadow-lg shadow-blue-500/50"
                      >
                        Login
                      </button>
                    )}
                  </form>
                


              </div>

              {/* Register */}
              <div className="flex flex-row gap-2 md:gap-3 justify-center items-center content-center text-left p-1 md:p-2 mt-[20px] text-[12px] md:text-[14px]">
                      <h1 className="text-gray-700">Don't remember your password?</h1>
                      <Link
                        to="/forgot-password"
                        className="text-red-600 text-[12px] md:text-[14px]"
                      >
                        Reset
                      </Link>
                    </div>


              <div className="flex flex-col justify-center content-center items-center text-center gap-2 md:gap-3 p-1 md:p-2">
                <h1 className="text-gray-700 text-[12px] md:text-[14px]">
                  Don't have an account?{" "}
                  <span className="font-bold">
                    <Link to="/register">Sign Up</Link>
                  </span>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Login;
