import React, { useEffect, useState } from 'react'
import { createPost } from '../../features/actions/postActions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { BsArrowLeft, BsChevronLeft } from 'react-icons/bs';
import NormalPost from '../../components/posts/CreatingPost/NormalPost';
import PollPost from '../../components/posts/CreatingPost/PollPost';

function CreatePostPage() {



    const navigate = useNavigate();
    const [type, setType] = useState(1)

   

   
    return (
        <div className="subpixel-antialiased min-h-screen inset-0 flex flex-col justify-center items-center content-center font-sans    mx-auto  lg:align-center lg:text-centen rounded-b-2xl p-3 mt-[10px] lg:mt-[50px] w-full">
            {/* central div */}


            <div className="flex flex-col justify-start items-start content-center gap-3 md:gap-5 p-2 md:p-3  h-full w-[95%] md:w-[80%] lg:w-[80%]">
                {/* back button */}
                <div
                    onClick={() => navigate(-1)}
                    className=" flex flex-row items-center content-center justify-center text-gray-900 hover:text-black w-auto rounded-full p-1 md:p-2 gap-1 md:gap-2 cursor-pointer text-[12px] md:text-[15px] mb-[10px]"
                >
                    <BsChevronLeft />
                    <h1 className='font-[500]'>Back</h1>
                </div>



                <div className='flex flex-row w-[200px] justify-evenly content-center items-center bg-gray-100 rounded-md h-[40px] text-[12px] md:text-[14px] p-1  gap-1 md:gap-2 border border-gray-100'>

                    <div
                        onClick={() => setType(1)}
                        className={type === 1 ? 'bg-white w-full h-full flex flex-row items-center content-center justify-center border border-gray-200 rounded-md text-gray-900 cursor-pointer p-1   font-[500]' : ' w-full h-full flex flex-row items-center content-center justify-center rounded-md text-gray-700 cursor-pointer p-1   hover:bg-gray-200 hover:font-[500]'}>
                        <h1>Normal</h1>

                    </div>

                    <div
                        onClick={() => setType(2)}
                        className={type === 2 ? 'bg-white w-full h-full flex flex-row items-center content-center justify-center border border-gray-200 rounded-md text-gray-900 cursor-pointer p-1  font-[500]' : ' w-full h-full flex flex-row items-center content-center justify-center rounded-md text-gray-700 cursor-pointer p-1   hover:bg-gray-200 hover:font-[500]'} >
                        <h1>Poll</h1>
                    </div>
                </div>




                {/* Options */}

                {type === 1 ?  <NormalPost /> : ""}
                {type === 2 ?  <PollPost /> : ""}










            </div>

        </div>
    )
}

export default CreatePostPage