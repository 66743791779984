import { motion,AnimatePresence } from 'framer-motion'
import React, { useState } from 'react'
import { BsFillArrowRightCircleFill, BsPlusCircleFill, BsXCircleFill } from 'react-icons/bs'
import { CgCalendar,  CgFeed } from 'react-icons/cg'
import { HiCalendar, HiCurrencyDollar, HiUser } from 'react-icons/hi'
import { useNavigate } from 'react-router'

function BottomBar() {

    
    const navigate = useNavigate();
    const [add, setAdd] = useState(false)

    const [create, setCreate] = useState(false);

  const handleCreate = () => {
    setCreate(!create);
  }
  const handleCreatePost = () => {
    navigate("/feed/create/post");
    setCreate(false)
  }
  const handleCreateEvent = () => {
    navigate("/feed/create/event");
    setCreate(false)
  }
  const handleCreateDonation = () => {
    navigate("/feed/create/donation");
    setCreate(false)
  }





    const handleAdd = () => {
        setAdd(!add);

    }
    return (
        <div className="bg-white transition delay-80 duration-80 ease-in-out h-[4rem] sm:h-[5rem]  fixed flex  justify-around  bottom-0 z-40      pl-5 pr-5 min-w-full  ">
            <div className='flex flex-row items-center content-center justify-between w-full'>

                {/* feed */}

                <div
                onClick={( ) => navigate('/')}
                 className='flex flex-col items-center content-center justify-center gap-1 cursor-pointer text-gray-900 hover:text-gray-700 min-w-[60px] h-full w-full rounded-md'>
                    <CgFeed className="text-[1.8rem]" />
                    <h1 className='text-[12px] md:text-[14px] font-[400]'>Feed</h1>

                </div>


                <div
                onClick={( ) => navigate('/events')}
                className='flex flex-col items-center content-center justify-center gap-1 cursor-pointer text-gray-900 hover:text-gray-700 min-w-[60px] h-full w-full rounded-md'>
                    <CgCalendar className="text-[1.8rem]" />
                    <h1 className='text-[12px] md:text-[14px] font-[400]'>Events</h1>

                </div>

                <div
                    onClick={handleCreate}
                    className='flex flex-col items-center content-center justify-center gap-1 cursor-pointer text-gray-900 hover:text-gray-700 min-w-[60px] h-full w-full rounded-md relative'>

                    {create === true ? (
                        <BsXCircleFill className="text-[1.8rem] mb-[5px] " />

                    ) : (<BsPlusCircleFill className="text-[1.8rem] mb-[5px]" />)}

                    {/* <h1 className='text-[12px] md:text-[14px] font-[400]'>Add</h1> */}



                    {create ? (<motion.div 
              
              initial={{opacity:0, bottom:40}}
              animate={{opacity:1, bottom:60}}
              exit={{opacity:0, bottom:50}}
              className="absolute  bottom-20   w-[150px] md:w-[170px] h-auto rounded-xl bg-gray-50  border border-gray-200 shadow-xl flex flex-col justify-center content-center items-center font-[400]  z-40">
                <div
                 className="w-full p-2 flex flex-row justify-between items-center content-center h-[40px] ">
                  <h1 className="text-gray-500 text-[12px] ">Create</h1>
                <BsXCircleFill 
                onClick={handleCreate}
                className="self-end cursor-pointer hover:text-red-600" />

                </div>
                


                <ul className="w-full text-[13px] md:text-[14px]">
                  <li 
                  onClick={handleCreatePost}
                  className="w-full p-2  hover:text-white cursor-pointer hover:bg-black h-[40px] ">
                    <div className="flex flex-row items-center justify-between  content-center gap-1  w-full">
                      <div className="flex flex-row items-center justify-start content-center gap-1">
                        <CgFeed />
                        <h1>Post</h1>
                      </div>


                      <BsFillArrowRightCircleFill />
                    </div>
                  </li>
                  <li 
                  onClick={handleCreateEvent}
                  className="w-full p-2  hover:text-white cursor-pointer hover:bg-black h-[40px]  ">
                    <div className="flex flex-row items-center justify-between  content-center gap-1  w-full">
                      <div className="flex flex-row items-center justify-start content-center gap-1">
                        <HiCalendar />
                        <h1>Event</h1>
                      </div>


                      <BsFillArrowRightCircleFill />
                    </div>
                  </li>
                  <li 
                  onClick={handleCreateDonation}
                  className="w-full p-2  hover:text-white cursor-pointer hover:bg-black h-[40px] rounded-b-xl  ">
                    <div className="flex flex-row items-center justify-between  content-center gap-1  w-full">
                      <div className="flex flex-row items-center justify-start content-center gap-1">
                        <HiCurrencyDollar />
                        <h1>Donation</h1>
                      </div>


                      <BsFillArrowRightCircleFill />
                    </div>
                  </li>

                </ul>

              </motion.div>) : ""}




                </div>

                <div
                   onClick={( ) => navigate('/donate')}
                   className='flex flex-col items-center content-center justify-center gap-1 cursor-pointer text-gray-900 hover:text-gray-700 min-w-[60px] h-full w-full rounded-md'>
                    <HiCurrencyDollar className="text-[1.8rem]" />
                    <h1 className='text-[12px] md:text-[14px] font-[400]'>Donate</h1>

                </div>



                <div
                onClick={( ) => navigate('/account')}
                 className='flex flex-col items-center content-center justify-center gap-1 cursor-pointer text-gray-900 hover:text-gray-700 min-w-[60px] h-full w-full rounded-md'>
                    <HiUser className="text-[1.8rem]" />
                    <h1 className='text-[12px] md:text-[14px] font-[400]'>Account</h1>

                </div>






            </div>

        </div>
    )
}

export default BottomBar