import React from "react";
import { motion } from "framer-motion";
import { BsArrowUp, BsUpload } from "react-icons/bs";

function UploadingLoader() {
  return (
    <motion.div 
    animate={{ bottom: 0, opacity: 1 }}
    initial={{ bottom: -100, opacity: 0 }}
    exit={{ bottom: -100, opacity: 0 }}
    className="flex flex-col items-center content-center justify-center gap-1 md:gap-2 bg-gray-50 p-1 md:p-2 w-full  rounded-lg ">
      <BsArrowUp  className="animate-bounce text-green-600"/>
      <h1>Uploading Your Profile</h1>
    </motion.div>
  );
}

export default UploadingLoader;
