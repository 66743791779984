import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { listDonations } from '../../features/actions/donationsActions';
import DonateItem from './DonateItem';
import DonationLoading from '../loading/DonationLoading';
import Paginate from '../dashboard/navigation/Paginate';
import { useLocation } from 'react-router';
import Search from '../search/Search';

function DonationList() {
  const dispatch = useDispatch();

  const location = useLocation();

  const [isSticky, setIsSticky] = useState(false);
  const tabRef = useRef(null);

  const donationList = useSelector((state) => state.donationList);

  const { donations, page, pages, loading, error } = donationList;


  const donationLike = useSelector((state) => state.donationLike);
  const { loading: likeLoading, success: likeSuccess, error: likeError } = donationLike;


  const donationUnLike = useSelector((state) => state.donationUnLike);
  const { loading: unlikeLoading, success: unlikeSuccess, error: unlikeError } = donationUnLike;




  let keyword =
    location.search; /* IF USER SEARCHES FOR ANYTHING THEN THIS KEYWORD CHANGES AND USE EFFECT GETS TRIGGERED */

  useEffect(() => {
    let _;

    dispatch(listDonations(keyword));


  }, [dispatch, keyword]);




  useEffect(() => {

    if (likeSuccess || unlikeSuccess) {
      dispatch(listDonations(keyword))
    }


  }, [dispatch, likeSuccess, unlikeSuccess, keyword])


  useEffect(() => {

    const handleScroll = () => {
      const tabPosition = tabRef.current.getBoundingClientRect().top;
      if (tabPosition <= 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);

      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll)

    }

  }, [])



  return (
    <div className=" p-1 md:p-2   flex flex-col gap-3 justify-center content-center items-center text-black  mx-auto bg-white w-[100%] relative lg:mt-[100px]">


      <div ref={tabRef} className={isSticky ? "sticky top-0   z-30 p-1 " : "flex"}>
        <Search isSticky={isSticky} />

      </div>



      {/* center items */}
      {loading ? <div className="flex flex-col  justify-center content-center items-center gap-3 md:gap-4  w-full ">
        <DonationLoading />
      </div>
        : ""}









      {donations === 0 & !loading ? (
        <div className="flex flex-col  justify-center items-center content-center w-full">
          <h1 className="text-gray-500 text-md md:text-xl">No Donations Found</h1>
        </div>


      ) : (
        <div className="flex flex-col  justify-center content-center items-center gap-3 md:gap-4  w-full ">
          {donations?.map((donation) => (
            <DonateItem key={donation._id} donation={donation} />
          )
          )}



        </div>

      )}

      {/* pagination */}
      <div className="p-2 md:p-3 mt-[20px]">
        <Paginate page={page} pages={pages} keyword={keyword} route="/events" />
      </div>








    </div>
  )
}

export default DonationList