import React from 'react'
import { motion } from 'framer-motion'
import DonationList from '../../components/donations/DonationList'
function Donations() {
  return (
    <motion.main className="min-h-[500px]">
    <div className="subpixel-antialiased min-h-screen inset-0 flex flex-col justify-center items-center font-sans    mx-auto lg:justify-center lg:align-center lg:text-centen rounded-b-2xl p-3  ">
      {/* central div */}

      {/* Feed */}
      <div className='w-full flex flex-col items-center content-center justify-center'>
        <DonationList />
      </div>
      

      
    </div>
  </motion.main>
  )
}

export default Donations