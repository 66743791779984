/* CONSTANTS USED IN HomeScreen COMPONENT */
export const COMMENT_LIST_REQUEST = "COMMENT__LIST_REQUEST";

export const COMMENT_LIST_SUCCESS = "COMMENT_LIST_SUCCESS";

export const COMMENT_LIST_FAIL = "COMMENT_LIST_FAIL";

/* CONSTANTS USED IN FEEDScreen COMPONENT */
export const COMMENT_DETAILS_REQUEST = "COMMENT_DETAILS_REQUEST";

export const COMMENT_DETAILS_SUCCESS = "COMMENT_DETAILS_SUCCESS";

export const COMMENT_DETAILS_FAIL = "COMMENT_DETAILS_FAIL";

/* CONSTANTS USED IN ProductListScreen COMPONENT */
export const COMMENT_DELETE_REQUEST = "COMMENT_DELETE_REQUEST";

export const COMMENT_DELETE_SUCCESS = "COMMENT_DELETE_SUCCESS";

export const COMMENT_DELETE_FAIL = "COMMENT_DELETE_FAIL";

/* CONSTANTS USED IN ProductListScreen COMPONENT */
export const COMMENT_CREATE_REQUEST = "COMMENT_CREATE_REQUEST";

export const COMMENT_CREATE_SUCCESS = "COMMENT_CREATE_SUCCESS";

export const COMMENT_CREATE_FAIL = "COMMENT_CREATE_FAIL";

export const COMMENT_CREATE_RESET = "COMMENT_CREATE_RESET";

/* CONSTANTS USED IN ProductEditScreen COMPONENT */
export const COMMENT_UPDATE_REQUEST = "COMMENT_UPDATE_REQUEST";

export const COMMENT_UPDATE_SUCCESS = "COMMENT_UPDATE_SUCCESS";

export const COMMENT_UPDATE_FAIL = "COMMENT_UPDATE_FAIL";

export const COMMENT_UPDATE_RESET = "COMMENT_UPDATE_RESET";
