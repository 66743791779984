import axios from "axios";
import { REACT_API_URL, REACT_APP_URL } from "../../utilities/utils";
import { CREDENTIALS_CHECK_ERROR, CREDENTIALS_CHECK_FAIL, CREDENTIALS_CHECK_REQUEST, CREDENTIALS_CHECK_RESETS, CREDENTIALS_CHECK_SUCCESS, EMAIL_AVAILABILITY_CHECK_ERROR, EMAIL_AVAILABILITY_CHECK_FAIL, EMAIL_AVAILABILITY_CHECK_REQUEST, EMAIL_AVAILABILITY_CHECK_RESETS, EMAIL_AVAILABILITY_CHECK_SUCCESS, EMAIL_CHANGE_ERROR, EMAIL_CHANGE_FAIL, EMAIL_CHANGE_REQUEST, EMAIL_CHANGE_RESETS, EMAIL_CHANGE_SUCCESS, EMAIL_TOKEN_CHECK_ERROR, EMAIL_TOKEN_CHECK_FAIL, EMAIL_TOKEN_CHECK_REQUEST, EMAIL_TOKEN_CHECK_RESETS, EMAIL_TOKEN_CHECK_SUCCESS, EMAIL_TOKEN_RESEND_ERROR, EMAIL_TOKEN_RESEND_FAIL, EMAIL_TOKEN_RESEND_REQUEST, EMAIL_TOKEN_RESEND_SUCCESS, PASSWORD_CHANGE_ERROR, PASSWORD_CHANGE_FAIL, PASSWORD_CHANGE_REQUEST, PASSWORD_CHANGE_RESETS, PASSWORD_CHANGE_SUCCESS, USERNAME_AVAILABILITY_CHECK_ERROR, USERNAME_AVAILABILITY_CHECK_FAIL, USERNAME_AVAILABILITY_CHECK_REQUEST, USERNAME_AVAILABILITY_CHECK_RESETS, USERNAME_AVAILABILITY_CHECK_SUCCESS } from "../constants/AuthConstants";



export const checkCredentials = (pwd, email) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CREDENTIALS_CHECK_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {

        Authorization: `Bearer ${userInfo.token}`,
      },
    };


    const { data } = await axios.post(
      `${REACT_APP_URL}/users/auth/password/check/`,
      { pwd, email },
      config
    );



    if (data.has_creds === true) {
      dispatch({
        type: CREDENTIALS_CHECK_SUCCESS,
        payload: data,
      });


    } else if (data.has_creds === false) {
      dispatch({
        type: CREDENTIALS_CHECK_ERROR,
        payload: data,
      });


    }


  } catch (error) {
    dispatch({
      type: CREDENTIALS_CHECK_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};





export const verifyEmail = (token) => async (dispatch, getState) => {
  try {
    dispatch({
      type: EMAIL_TOKEN_CHECK_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };


    const { data } = await axios.post(
      `${REACT_APP_URL}/users/verification/email/verify/`,
      { token, email: userInfo.email },
      config
    );




    if (data.success === true) {
      dispatch({
        type: EMAIL_TOKEN_CHECK_SUCCESS,
        payload: data,
      });


    } else if (data.success === false) {
      dispatch({
        type: EMAIL_TOKEN_CHECK_ERROR,
        payload: data,
      });


    }


  } catch (error) {
    dispatch({
      type: EMAIL_TOKEN_CHECK_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};



export const resendEmailToken = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: EMAIL_TOKEN_RESEND_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };


    const { data } = await axios.post(
      `${REACT_APP_URL}/users/verification/email/token/resend/`,
      {email: userInfo.email},
      config
    );




    if (data.success === true) {
      dispatch({
        type: EMAIL_TOKEN_RESEND_SUCCESS,
        payload: data,
      });


    } else if (data.success === false) {
      dispatch({
        type: EMAIL_TOKEN_RESEND_ERROR,
        payload: data,
      });


    }


  } catch (error) {
    dispatch({
      type: EMAIL_TOKEN_RESEND_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};



export const changePassword = (pwd, encoded_pk, token) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PASSWORD_CHANGE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };


    const { data } = await axios.put(
      `${REACT_APP_URL}/users/auth/password/change/${encoded_pk}/${token}/`,
      { pwd, email: userInfo.email },
      config
    );




    if (data.success === true) {
      dispatch({
        type: PASSWORD_CHANGE_SUCCESS,
        payload: data,
      });


    } else if (data.success === false) {
      dispatch({
        type: PASSWORD_CHANGE_ERROR,
        payload: data,
      });


    }


  } catch (error) {
    dispatch({
      type: PASSWORD_CHANGE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const changeEmail = (email) => async (dispatch, getState) => {
  try {
    dispatch({
      type: EMAIL_CHANGE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };


    const { data } = await axios.put(
      `${REACT_APP_URL}/users/verification/email/change/`,
      { email, current_email: userInfo.email, user: userInfo.id },
      config
    );




    if (data.success === true) {
      dispatch({
        type: EMAIL_CHANGE_SUCCESS,
        payload: data,
      });


    } else if (data.success === false) {
      dispatch({
        type: EMAIL_CHANGE_ERROR,
        payload: data,
      });


    }


  } catch (error) {
    dispatch({
      type: EMAIL_CHANGE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};




export const CancelAuthActions = () => (dispatch) => {

  dispatch({
    type: CREDENTIALS_CHECK_RESETS,
  });
  dispatch({
    type: EMAIL_CHANGE_RESETS,
  });
  dispatch({
    type: PASSWORD_CHANGE_RESETS,
  });
  dispatch({
    type: EMAIL_TOKEN_CHECK_RESETS,
  });
  dispatch({
    type: EMAIL_AVAILABILITY_CHECK_RESETS,
  });
  dispatch({
    type: USERNAME_AVAILABILITY_CHECK_RESETS,
  });
  

};



export const usernameAvailability = (username) => async (dispatch) => {
  
  const csrftoken = getCookie('token');
  try {
    dispatch({
      type: USERNAME_AVAILABILITY_CHECK_REQUEST,
    });


    const config = {
      headers: {
        "Content-Type": "application/json",
        'X-CSRFToken': csrftoken,
      },
    };

    const { data } = await axios.post(
      `${REACT_APP_URL}/users/profile/username/check/`,
      {username:username},
      config
    );

    if (data.success === true) {
      dispatch({
        type: USERNAME_AVAILABILITY_CHECK_SUCCESS,
        payload: data,
      });
    } else if (data.success === false) {
      dispatch({
        type: USERNAME_AVAILABILITY_CHECK_ERROR,
        payload: data,
      });

    }

  } catch (error) {
    dispatch({
      type: USERNAME_AVAILABILITY_CHECK_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};






export const emailAvailability = (email) => async (dispatch) => {
  
  const csrftoken = getCookie('token');
  try {
    dispatch({
      type: EMAIL_AVAILABILITY_CHECK_REQUEST,
    });

    /* MAKE POST REQUEST TO GET BACK THE USER TOKEN */
    const config = {
      headers: {
        "Content-Type": "application/json",
        'X-CSRFToken': csrftoken,
      },
    };

    const { data } = await axios.post(
      `${REACT_APP_URL}/users/profile/email/check/`,
      {  email: email },
      config
    );

    if (data.success === true) {
      dispatch({
        type: EMAIL_AVAILABILITY_CHECK_SUCCESS,
        payload: data,
      });


    } else if (data.success === false) {
      dispatch({
        type: EMAIL_AVAILABILITY_CHECK_ERROR,
        payload: data,
      });


    }

  } catch (error) {
    dispatch({
      type: EMAIL_AVAILABILITY_CHECK_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};




function getCookie(name) {
  let cookieValue = null;

  if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim();

          // Does this cookie string begin with the name we want?
          if (cookie.substring(0, name.length + 1) === (name + '=')) {
              cookieValue = decodeURIComponent(cookie.substring(name.length + 1));

              break;
          }
      }
  };

}