import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { listComments } from '../../../features/actions/commentActions';
import DonationCommentItem from './DonationCommentItem';
import { listDonationComments } from '../../../features/actions/donationCommentActions';
import Loading from '../../loading/Loading';

function DonationComments() {
  const dispatch = useDispatch();

  const location = useLocation();

  const getId = useParams();
  const donationId = getId.id;
 

  const donationCommentList = useSelector((state) => state.donationCommentList);
  const { loading, comments, error, count } = donationCommentList;
  const route = location.route;
  useEffect(() => {
    dispatch(listDonationComments(donationId));

  }, [dispatch, donationId]);

  
  return (
    <div className="flex flex-col items-start content-center justify-start border-t gap-2 md:gap-3 w-full p-1 md:p-2">


      <div className="flex flex-row justify-between content-center items-center w-full">
        <h1 className="flex flex-row justify-start content-center items-center gap-1 text-black font-[500] text-[12px] ">Comments {" "}
          <span className=" text-blue-600 font-[500] text-[12px] ">({comments?.length})</span> {" "}
        </h1>



        <button className="text-blue-500 text-[12px] md:text-[14px]" >View all</button>




      </div>

      {loading ? (
        <div className="flex flex-col justify-center items-center content-center text-center    p-2 md:p-3  w-full md:w-[80%] lg:w-[80%]">
        <Loading />
      </div>
      ) : (
        <div className="flex flex-col gap-2 divide-y  justify-start items-start content-center w-full">
          {comments?.map((comment) => (
            <DonationCommentItem key={comment._id} comment={comment} />
          ))}
        </div>)}



    </div>
  )
}

export default DonationComments