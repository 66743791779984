import axios from "axios";
import React, { useEffect, useState, Component } from "react";
import { AiFillCheckCircle, AiOutlineCheck, AiOutlineCheckCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { updatePoll, votePoll } from "../../features/actions/pollActions";
import { pollUpdateReducer } from "../../features/reducers/pollReducers";
import { REACT_API_URL } from "../../utilities/utils";
import { useParams } from "react-router";
import { listPostDetails } from "../../features/actions/postActions";





const formatNumber = (num) => {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + 'M'; // 1.1M
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + 'k'; // 1.2k
  } else {
    return num;
  }
};


// Utility function to format percentages
const formatPercentage = (percentage) => {
  return percentage > 0 ? `${percentage.toFixed(1)}%` : '0%';
};




function PollItem({ choice, percentage }) {
  const dispatch = useDispatch();
  const [voting, setVoting] = useState()
  const pollUpdate = useSelector((state) => state.pollUpdate);

  
  const getId = useParams();
  const postId = getId.id;

  const { loading, success, error } = pollUpdate;

  
  const pollId = choice._id;


  useEffect(() => {
    if (success) {
      // window.location.reload();
      dispatch(listPostDetails(postId));

    } else {
      return;
    }


  }, [success, postId])

  function handleVote(id) {


    dispatch(votePoll(id))



  }

  return (
    <li key={choice._id}>
      <div className="flex flex-row items-center content-center justify-between gap-2">
        <h1 className="text-[12px] font-[500] text-gray-600">{choice.choice_text}</h1>
        <div className="rounded-lg h-[30px] text-center text-[14px] flex flex-row items-center content-center justify-center">
          <p className="text-[12px] text-gray-500">{formatPercentage(percentage)}{""}(<span>{formatNumber(choice.votes)}</span>)</p>
        </div>

        <div
          onClick={() => handleVote(choice._id)}
          className="flex flex-row items-center content-center justify-center gap-1 text-gray-600 cursor-pointer hover:text-gray-900"
        >
          <h1 className="text-[12px]">Vote</h1>
          <AiFillCheckCircle />
        </div>
      </div>
    </li>
  );
}

export default PollItem;
