import { CREDENTIALS_CHECK_ERROR, CREDENTIALS_CHECK_FAIL, CREDENTIALS_CHECK_REQUEST, CREDENTIALS_CHECK_RESETS, CREDENTIALS_CHECK_SUCCESS, EMAIL_AVAILABILITY_CHECK_ERROR, EMAIL_AVAILABILITY_CHECK_FAIL, EMAIL_AVAILABILITY_CHECK_REQUEST, EMAIL_AVAILABILITY_CHECK_RESETS, EMAIL_AVAILABILITY_CHECK_SUCCESS, EMAIL_CHANGE_ERROR, EMAIL_CHANGE_FAIL, EMAIL_CHANGE_REQUEST, EMAIL_CHANGE_RESETS, EMAIL_CHANGE_SUCCESS, EMAIL_TOKEN_CHECK_ERROR, EMAIL_TOKEN_CHECK_FAIL, EMAIL_TOKEN_CHECK_REQUEST, EMAIL_TOKEN_CHECK_RESETS, EMAIL_TOKEN_CHECK_SUCCESS, EMAIL_TOKEN_RESEND_ERROR, EMAIL_TOKEN_RESEND_FAIL, EMAIL_TOKEN_RESEND_REQUEST, EMAIL_TOKEN_RESEND_RESETS, EMAIL_TOKEN_RESEND_SUCCESS, PASSWORD_CHANGE_ERROR, PASSWORD_CHANGE_FAIL, PASSWORD_CHANGE_REQUEST, PASSWORD_CHANGE_RESETS, PASSWORD_CHANGE_SUCCESS, USERNAME_AVAILABILITY_CHECK_ERROR, USERNAME_AVAILABILITY_CHECK_FAIL, USERNAME_AVAILABILITY_CHECK_REQUEST, USERNAME_AVAILABILITY_CHECK_RESETS, USERNAME_AVAILABILITY_CHECK_SUCCESS } from "../constants/AuthConstants";


export const credentialsCheckReducer = (state = {tokens: {}}, action) => {
  switch (action.type) {
    case CREDENTIALS_CHECK_REQUEST:
      return {
        loading: true,
      };

    case CREDENTIALS_CHECK_SUCCESS:
      return {
        loading: false,
        success: true,
        invalid: false,
        tokens: action.payload,
      };


    case CREDENTIALS_CHECK_ERROR:
      return {
        loading: false,
        success: false,
        invalid: true,
        error: action.payload,
      };

    case CREDENTIALS_CHECK_FAIL:
      return {
        loading: false,
        invalid: true,
        error: action.payload,
      };


    case CREDENTIALS_CHECK_RESETS:
        return { };

    default:
      return state;
  }
  };



export const emailVerifyReducer = (state = {}, action) => {
  switch (action.type) {
    case EMAIL_TOKEN_CHECK_REQUEST:
      return {
        loading: true,
      };

    case EMAIL_TOKEN_CHECK_SUCCESS:
      return {
        loading: false,
        success: true,
        invalid: false,
        message: action.payload,
      };


    case EMAIL_TOKEN_CHECK_ERROR:
      return {
        loading: false,
        success: false,
        invalid: true,
        error: action.payload,
      };

    case EMAIL_TOKEN_CHECK_FAIL:
      return {
        loading: false,
        invalid: true,
        error: action.payload,
      };


    case EMAIL_TOKEN_CHECK_RESETS:
        return { };

    default:
      return state;
  }
  };



export const emailTokenResendReducer = (state = {}, action) => {
  switch (action.type) {
    case EMAIL_TOKEN_RESEND_REQUEST:
      return {
        loading: true,
      };

    case EMAIL_TOKEN_RESEND_SUCCESS:
      return {
        loading: false,
        success: true,
        invalid: false,
        message: action.payload,
      };


    case EMAIL_TOKEN_RESEND_ERROR:
      return {
        loading: false,
        success: false,
        invalid: true,
        error: action.payload,
      };

    case EMAIL_TOKEN_RESEND_FAIL:
      return {
        loading: false,
        invalid: true,
        error: action.payload,
      };


    case EMAIL_TOKEN_RESEND_RESETS:
        return { };

    default:
      return state;
  }
  };






export const passwordChangeReducer = (state = {}, action) => {
  switch (action.type) {
    case PASSWORD_CHANGE_REQUEST:
      return {
        loading: true,
      };

    case PASSWORD_CHANGE_SUCCESS:
      return {
        loading: false,
        success: true,
        invalid: false,
        message: action.payload,
      };


    case PASSWORD_CHANGE_ERROR:
      return {
        loading: false,
        success: false,
        invalid: true,
        error: action.payload,
      };

    case PASSWORD_CHANGE_FAIL:
      return {
        loading: false,
        success: false,
        invalid: true,
        error: action.payload,
      };


    case PASSWORD_CHANGE_RESETS:
        return {};

    default:
      return state;
  }
  };


export const emailAvailabilityCheckReducer = (state = {}, action) => {
  switch (action.type) {
    case EMAIL_AVAILABILITY_CHECK_REQUEST:
      return {
        loading: true,
      };

    case EMAIL_AVAILABILITY_CHECK_SUCCESS:
      return {
        loading: false,
        success: true,
        invalid: false,
        message: action.payload,
      };


    case EMAIL_AVAILABILITY_CHECK_ERROR:
      return {
        loading: false,
        success: false,
        invalid: true,
        error: action.payload,
      };

    case EMAIL_AVAILABILITY_CHECK_FAIL:
      return {
        loading: false,
        success: false,
        invalid: true,
        error: action.payload,
      };


    case EMAIL_AVAILABILITY_CHECK_RESETS:
        return {};

    default:
      return state;
  }
  };




export const nameAvailabilityCheckReducer = (state = {}, action) => {
  switch (action.type) {
    case USERNAME_AVAILABILITY_CHECK_REQUEST:
      return {
        loading: true,
      };

    case USERNAME_AVAILABILITY_CHECK_SUCCESS:
      return {
        loading: false,
        success: true,
        invalid: false,
        message: action.payload,
      };


    case USERNAME_AVAILABILITY_CHECK_ERROR:
      return {
        loading: false,
        success: false,
        invalid: true,
        error: action.payload,
      };

    case USERNAME_AVAILABILITY_CHECK_FAIL:
      return {
        loading: false,
        success: false,
        invalid: true,
        error: action.payload,
      };


    case USERNAME_AVAILABILITY_CHECK_RESETS:
        return {};

    default:
      return state;
  }
  };






export const emailChangeReducer = (state = {}, action) => {
  switch (action.type) {
    case EMAIL_CHANGE_REQUEST:
      return {
        loading: true,
      };

    case EMAIL_CHANGE_SUCCESS:
      return {
        loading: false,
        success: true,
        invalid: false,
        message: action.payload,
      };


    case EMAIL_CHANGE_ERROR:
      return {
        loading: false,
        success: false,
        invalid: true,
        error: action.payload,
      };

    case EMAIL_CHANGE_FAIL:
      return {
        loading: false,
        invalid: true,
        error: action.payload,
      };


    case EMAIL_CHANGE_RESETS:
        return {};

    default:
      return state;
  }
  };