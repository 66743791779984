import React, { useState } from "react";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { AiFillPlusCircle } from "react-icons/ai";
import { HiCalendar, HiCurrencyDollar, HiUser } from "react-icons/hi";
import BottomBar from "./BottomBar/BottomBar";
import {  CgFeed } from "react-icons/cg";
import {  BsFillArrowRightCircleFill,  BsXCircleFill } from "react-icons/bs";

function Header() {


  const navigate  = useNavigate();
  const [create, setCreate] = useState(false);

  const handleCreate = () => {
    setCreate(!create);
  }
  const handleCreatePost = () => {
    navigate("/feed/create/post");
    setCreate(false)
  }
  const handleCreateEvent = () => {
    navigate("/feed/create/event");
    setCreate(false)
  }
  const handleCreateDonation = () => {
    navigate("/feed/create/donation");
    setCreate(false)
  }








  return (
    <div className="flex ">

      <div className="w-full block lg:hidden">
        <BottomBar />

      </div>


      <motion.div className="bg-white transition delay-80 duration-80 ease-in-out h-[4rem]  lg:fixed  w-screen hidden lg:flex justify-around  top-0 z-40       pl-5 pr-5">
        <motion.nav className="w-full flex flex-row justify-between   lg:justify-evenly items-center content-center font-[200] p-2 mt-4 ">
          {/* Logo */}
          <Link className="" to="/">
            <h1 className="text-lg font-[700]">
              Genz254
            </h1>
          </Link>

          {/* routes */}
          <div className="relative">
            <div className="hidden p-2 lg:flex flex-row justify-around text-[15px] space-x-8 font-[600]">
              <div className="p-2  hover:text-gray-800">
                <Link to="/">Feed</Link>
              </div>
              <div className="p-2 hover:text-gray-700">
                <Link to="/events">Events</Link>
              </div>

              <div className="p-2 hover:text-gray-700">
                <Link to="/donate">Donate</Link>
              </div>


              {/* Create button */}


              <div 
              onClick={handleCreate}
              className="w-[90px] p-[2px] pl-2 pr-2 rounded-xl  flex flex-row justify-center content-center items-center gap-1   cursor-pointer bg-gray-100   font-[400] border border-gray-200 hover:bg-gray-200 hover:text-gray-800  hover:border-gray-300">


                <AiFillPlusCircle />
                <h1 className="text-[12px]">Create</h1>


              </div>


              {/* create modal */}

              {create ? (<motion.div 
              
              initial={{opacity:0, top:40}}
              animate={{opacity:1, top:50}}
              exit={{opacity:0, top:50}}
              className="absolute  top-12 right-0  w-[150px] h-auto rounded-xl bg-gray-50  border border-gray-200 shadow-xl flex flex-col justify-center content-center items-center font-[400]  z-40">
                <div
                 className="w-full p-2 flex flex-row justify-between items-center content-center">
                  <h1 className="text-gray-500 text-[12px] ">Create</h1>
                <BsXCircleFill 
                onClick={handleCreate}
                className="self-end cursor-pointer hover:text-red-600" />

                </div>
                


                <ul className="w-full text-[13px]">
                  <li 
                  onClick={handleCreatePost}
                  className="w-full p-2  hover:text-white cursor-pointer hover:bg-black  ">
                    <div className="flex flex-row items-center justify-between  content-center gap-1  w-full">
                      <div className="flex flex-row items-center justify-start content-center gap-1">
                        <CgFeed />
                        <h1>Post</h1>
                      </div>


                      <BsFillArrowRightCircleFill />
                    </div>
                  </li>
                  <li 
                  onClick={handleCreateEvent}
                  className="w-full p-2  hover:text-white cursor-pointer hover:bg-black  ">
                    <div className="flex flex-row items-center justify-between  content-center gap-1  w-full">
                      <div className="flex flex-row items-center justify-start content-center gap-1">
                        <HiCalendar />
                        <h1>Event</h1>
                      </div>


                      <BsFillArrowRightCircleFill />
                    </div>
                  </li>
                  <li 
                  onClick={handleCreateDonation}
                  className="w-full p-2  hover:text-white cursor-pointer hover:bg-black rounded-b-xl  ">
                    <div className="flex flex-row items-center justify-between  content-center gap-1  w-full">
                      <div className="flex flex-row items-center justify-start content-center gap-1">
                        <HiCurrencyDollar />
                        <h1>Donation</h1>
                      </div>


                      <BsFillArrowRightCircleFill />
                    </div>
                  </li>

                </ul>

              </motion.div>) : ""}

              

            </div>
          </div>



          {/* Account */}
          <div className="hidden p-1 md:p-1 lg:flex">
            <Link
              className="flex flex-row items-center content-center justify-center gap-2 text-[15px] font-[600] text-gray-600"
              to="/account"
            >
              <h1 className=" ">Account</h1>

              <HiUser className="" />


            </Link>
          </div>


        </motion.nav>
      </motion.div>
    </div>
  );
}

export default Header;
