import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import { listMyDonations } from '../../features/actions/donationsActions';
import Paginate from '../dashboard/navigation/Paginate';
import { motion } from 'framer-motion';
import DonateItem from './DonateItem';
import DonationLoading from '../loading/DonationLoading';
import { BsChevronLeft } from 'react-icons/bs';

function AllProfileDonations() {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const location = useLocation();
    const params = useParams();
    const [choices,SetChoices] = useState(false);
  
  
    const donationMyList = useSelector((state) => state.donationMyList);
  
    const { donations, page, pages, loading, error } = donationMyList;
  
  
    const { search } = useLocation();
  
    const route = location.route;
  
    /* FIRING OFF THE ACTION CREATORS USING DISPATCH */
  
    let keyword =
      location.search; /* IF USER SEARCHES FOR ANYTHING THEN THIS KEYWORD CHANGES AND USE EFFECT GETS TRIGGERED */
  
    useEffect(() => {
      dispatch(listMyDonations(keyword));
    }, [dispatch, keyword]);
  return (
    <div className=" p-1 md:p-2   flex flex-col gap-3 justify-center content-center items-center text-black  mx-auto bg-white w-full lg:mt-[100px]">

<motion.div
          onClick={() => navigate(-1)}
          className=" flex flex-row items-center content-center justify-start text-gray-500 hover:text-black w-full   gap-1 md:gap-2 cursor-pointer mb-[20px] text-[14px]"
        >
          <BsChevronLeft />
          <h1>Back</h1>
        </motion.div>



      {/* center items */}
      {loading ? <div className="flex flex-col  justify-center content-center items-center gap-3 md:gap-4  w-full ">
        <DonationLoading />
      </div>
        : ""}


      {donations === 0 & !loading ? (
        <div className="flex flex-col  justify-center items-center content-center w-full">
          <h1 className="text-gray-500 text-md md:text-xl">No Donations Found</h1>
        </div> 
        ) : (
        <div className="flex flex-col  justify-center content-center items-center gap-3 md:gap-4  w-full ">
          {donations?.map((donation) => (
            <DonateItem key={donation._id} donation={donation} />
          )
          )}
      </div>

      )}

      {/* pagination */}
      <div className="p-2 md:p-3 mt-[20px]">
        <Paginate page={page} pages={pages} keyword={keyword} route="/account/donations" />
      </div>








    </div>
  )
}

export default AllProfileDonations