

/* REDUX */
import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";



import {
  postCommentCreateReducer,
  postCreateReducer,
  postDeleteReducer,
  postDetailsReducer,
  postListReducer,
  postMyListReducer,
  postUpdateReducer,
  postLikeReducer,
  postUnLikeReducer
} from "../features/reducers/postReducers";
import {
  commentCreateReducer,
  commentDeleteReducer,
  commentDetailsReducer,
  commentListReducer,
  commentUpdateReducer,
} from "../features/reducers/commentReducers";
import {
  pollCreateReducer,
  pollDeleteReducer,
  pollDetailsReducer,
  pollListReducer,
  pollUpdateReducer,
  pollVoteReducer,
} from "../features/reducers/pollReducers";
import {
  userDeleteReducer,
  userDetailsReducer,
  userEmailCheckReducer,
  userListReducer,
  userLoginReducer,
  userRegisterReducer,
  userResetPasswordReducer,
  userUpdateProfileReducer,
  userUpdateReducer,
} from "../features/reducers/userReducers";
import {
  donationCreateReducer,
  donationDeleteReducer,
  donationDetailsReducer,
  donationLikeReducer,
  donationListReducer,
  donationMyListReducer,
  donationUnLikeReducer,
  donationUpdateReducer,
} from "../features/reducers/donationReducers";
import {
  eventCreateReducer,
  eventDeleteReducer,
  eventDetailsReducer,
  eventLikeReducer,
  eventListReducer,
  eventMyListReducer,
  eventUnLikeReducer,
  eventUpdateReducer,
} from "../features/reducers/eventsReducers";
import {
  attendingAddReducer,
  attendingListReducer,
  attendingRemoveReducer,
} from "../features/reducers/attendingReducers";
import { donationCommentCreateReducer, donationCommentDeleteReducer, donationCommentDetailsReducer, donationCommentListReducer, donationCommentUpdateReducer } from "../features/reducers/donationCommentReducers";
import { eventCommentCreateReducer, eventCommentDeleteReducer, eventCommentDetailsReducer, eventCommentListReducer, eventCommentUpdateReducer } from "../features/reducers/eventCommentReducer";
import { credentialsCheckReducer, emailAvailabilityCheckReducer, emailChangeReducer, emailTokenResendReducer, emailVerifyReducer, nameAvailabilityCheckReducer, passwordChangeReducer } from "../features/reducers/AuthReducers";
import { donationQueueReducer, eventQueueReducer, postQueueReducer } from "../features/reducers/QueueReducers";

/* COMBINED REDUCER */
const reducer = {

  // que rvducers
  postQueue:postQueueReducer,
  eventQueue:eventQueueReducer,
  donationQueue:donationQueueReducer,

  // AUTH
  credentialsCheck: credentialsCheckReducer,
  emailChange : emailChangeReducer,
  emailVerify : emailVerifyReducer,
  emailTokenResend : emailTokenResendReducer,
  emailAvailabilityCheck:emailAvailabilityCheckReducer,
  usernameAvailabilityCheck:nameAvailabilityCheckReducer,
  passwordChange : passwordChangeReducer,


  postList: postListReducer,
  postMyList: postMyListReducer,
  postDetails: postDetailsReducer,
  postDelete: postDeleteReducer,
  postCreate: postCreateReducer,
  postUpdate: postUpdateReducer,
  postCommentCreate: postCommentCreateReducer,
  postLike: postLikeReducer,
  postUnLike: postUnLikeReducer,


  commentList: commentListReducer,
  commentDetails: commentDetailsReducer,
  commentDelete: commentDeleteReducer,
  commentCreate: commentCreateReducer,
  commentUpdate: commentUpdateReducer,




  donationCommentList: donationCommentListReducer,
  donationCommentDetails: donationCommentDetailsReducer,
  donationCommentCreate: donationCommentCreateReducer,
  donationCommentDelete: donationCommentDeleteReducer,
  donationCommentUpdate: donationCommentUpdateReducer,



  eventCommentList: eventCommentListReducer,
  eventCommentDetails: eventCommentDetailsReducer,
  eventCommentCreate: eventCommentCreateReducer,
  eventCommentDelete: eventCommentDeleteReducer,
  eventCommentUpdate: eventCommentUpdateReducer,

  pollList: pollListReducer,
  pollDetails: pollDetailsReducer,
  pollDelete: pollDeleteReducer,
  pollCreate: pollCreateReducer,
  pollUpdate: pollUpdateReducer,
  pollVote: pollVoteReducer,

  donationList: donationListReducer,
  donationMyList: donationMyListReducer,
  donationDetails: donationDetailsReducer,
  donationDelete: donationDeleteReducer,
  donationCreate: donationCreateReducer,
  donationUpdate: donationUpdateReducer,
  donationLike: donationLikeReducer,
  donationUnLike: donationUnLikeReducer,

  eventList: eventListReducer,
  eventMyList: eventMyListReducer,
  eventDetails: eventDetailsReducer,
  eventDelete: eventDeleteReducer,
  eventCreate: eventCreateReducer,
  eventUpdate: eventUpdateReducer,
  eventLike: eventLikeReducer,
  eventUnLike: eventUnLikeReducer,

  attendingList: attendingListReducer,
  attendingAdd: attendingAddReducer,

  attendingRemove: attendingRemoveReducer,

  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,
  userUpdate: userUpdateReducer,
  userEmailCheck: userEmailCheckReducer,
  userPasswordReset: userResetPasswordReducer,
};



const postItemsFromStorage = localStorage.getItem('post')
  ? JSON.parse(localStorage.getItem('post'))
  : null;

const eventItemsFromStorage = localStorage.getItem('event')
  ? JSON.parse(localStorage.getItem('event'))
  : null;

const donationItemsFromStorage = localStorage.getItem('donation')
  ? JSON.parse(localStorage.getItem('donation'))
  : null;

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;


  const initialState = {
    userLogin: { userInfo: userInfoFromStorage },
    postQueue: { post: postItemsFromStorage || [] },
    eventQueue: { event: eventItemsFromStorage || [] },
    donationQueue: { donation: donationItemsFromStorage || [] },
  };
  

  // Configure the store with reducers and initial state

const store = configureStore({
  reducer,
  preloadedState: initialState,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk), // Thunk middleware is included by default
});
  
  export default store;