import React, { lazy, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Backdrop from "../backdrop/Backdrop";
import { motion } from "framer-motion";
import { BsFillXCircleFill, BsInfoCircleFill, BsPlusCircleFill, BsX, BsXCircle } from "react-icons/bs";
import { modifyImageUrl, REACT_API_URL } from "../../utilities/utils";
import { useDispatch, useSelector } from "react-redux";
import Message from "../message/Message";
import { BiCalendarMinus, BiLoader, BiMinusCircle, BiPlusCircle, BiSolidMinusCircle, BiSolidPlusCircle } from "react-icons/bi";
import { listEventDetails } from "../../features/actions/eventActions";

function AttendingModal({ handleClose, event, is_attending }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [NewError, setNewError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const userLogin = useSelector((state) => state.userLogin);

  const { userInfo } = userLogin;



  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("user", userInfo.id);

    fetch(`${REACT_API_URL}/events/attending/add/${event._id}/`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch(listEventDetails(event?._id))
        setLoading(false);
        handleClose();

      })
      .catch((error) => {
        setLoading(false);
        setNewError(true);
        setMessage(error);
      });
  };
  const handleRemove = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("user", userInfo.id);

    fetch(`${REACT_API_URL}/events/attending/remove/${event._id}/`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch(listEventDetails(event?._id))
        setLoading(false);
        handleClose();

      })
      .catch((error) => {
        setLoading(false);
        setNewError(true);
        setMessage(error);
      });
  };

  return (
    <Backdrop onClick={handleClose}>
      <motion.div
        onClick={(e) => e.stopPropagation()}
        className=" min-w-[90%] h-auto md:min-w-[400px] md:right-10  rounded-xl  flex flex-col items-start content-start fixed bg-white z-40   shadow-xl shadow-gray-600/80 bottom-4"
      >


        {/* items */}



       

        {loading ? (
          <div className="w-full flex flex-col gap-2 items-center content-center justify-center h-[200px]">


            <div className=" h-auto flex flex-row items-center content-center justify-center gap-1 md:gap-2  "><BiLoader className="animate-spin" />
              <h1 className="text-gray-500 text-[12px] md:text-[14px]">Loading...</h1></div>

          </div>
        ) : (
          <div className="flex flex-col w-full">
            <div className="flex flex-col gap-2 w-full p">
              <img
                src={modifyImageUrl(event?.event_cover)}
                loading="lazy"
                className="w-full h-[200px] md:w-full md:h-[200px]  object-cover object-center drop-shadow-xl rounded-t-xl"
                alt=""
              />
            </div>

            <div className="flex flex-col justify-center content-center items-start p-2 md:p-3">
              {/* title & venue */}
              <div className="flex flex-row justify-between items-center content-center w-full">
                {/* title */}
                <div className="text-[11px] md:text-[14px] w-full">
                  <h1 className="font-[500] text-gray-800">Title</h1>
                  <p className="text-gray-600 text-lg font-[500] truncate w-[80%]">{event?.title}</p>

                </div>


              </div>

            </div>

            {is_attending ? (
              <p className="p-2 bg-gray-100 border border-gray-100 rounded-xl flex flex-row items-center content-center justify-start gap-2  text-[12px]  md:text-[13px]">
                <BsInfoCircleFill className="text-yellow-500" />
                Remove from list
              </p>
            ) : (
              <p className="p-2 bg-gradient-to-b    border-b border-gray-200  text-[12px]  md:text-[13px] flex flex-row items-center content-center justify-start gap-2 ">
                <BsInfoCircleFill className="text-yellow-500" /> Confirm attending
                this event{""}<span>|{" "}{event?.location}</span>
              </p>
            )}

            {NewError && <Message type="error">{message}</Message>}

            <div className="flex flex-row justify-between items-center content-center  h-full text-[11px] md:text-[13px]">
              <div
                onClick={handleClose}
                className=" flex flex-row items-center content-center justify-center gap-1 p-1 text-[12px] md:text-[14px] h-[40px] text-gray-600  rounded-bl-xl bg-gray-100 border-l hover:bg-gray-600 hover:text-white w-[100px]  cursor-pointer"
              >
                <h1 className="">Close</h1>
                <BsFillXCircleFill className=" rounded-full" />
              </div>

              {is_attending ? (
                <div
                  onClick={(e) => handleRemove(e)}
                  className=" flex flex-row items-center content-center justify-center gap-1 p-1 text-[12px] md:text-[14px] h-[40px] text-gray-600  rounded-br-xl bg-gray-100 border-l hover:bg-red-600 hover:text-white w-[100px] cursor-pointer "
                >

                  <h1>Remove</h1>
                  <BiSolidMinusCircle />
                </div>
              ) : (
                <div
                  onClick={(e) => handleSubmit(e)}
                  className=" flex flex-row items-center content-center justify-center gap-1 p-1 text-[12px] md:text-[14px] h-[40px]  text-green-600  rounded-br-xl bg-gray-100 border-l hover:bg-green-600 hover:text-white w-[100px] cursor-pointer "
                >

                  <h1>Confirm</h1>
                  <BiSolidPlusCircle />
                </div>
              )}


            </div>
          </div>
        )}
      </motion.div>
    </Backdrop>
  );
}

export default AttendingModal;
