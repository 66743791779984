
import { EVENT_COMMENT_CREATE_FAIL, EVENT_COMMENT_CREATE_REQUEST, EVENT_COMMENT_CREATE_RESET, EVENT_COMMENT_CREATE_SUCCESS, EVENT_COMMENT_DELETE_FAIL, EVENT_COMMENT_DELETE_REQUEST, EVENT_COMMENT_DELETE_SUCCESS, EVENT_COMMENT_DETAILS_FAIL, EVENT_COMMENT_DETAILS_REQUEST, EVENT_COMMENT_DETAILS_SUCCESS, EVENT_COMMENT_LIST_FAIL, EVENT_COMMENT_LIST_REQUEST, EVENT_COMMENT_LIST_SUCCESS, EVENT_COMMENT_UPDATE_FAIL, EVENT_COMMENT_UPDATE_REQUEST, EVENT_COMMENT_UPDATE_RESET, EVENT_COMMENT_UPDATE_SUCCESS } from "../constants/eventCommentConstants";


/* REDUCER USED IN HomeScreen COMPONENT */
export const eventCommentListReducer = (state = { comments: [] }, action) => {
    switch (action.type) {
        case EVENT_COMMENT_LIST_REQUEST:
            return {
                loading: true,
            };

        case EVENT_COMMENT_LIST_SUCCESS:
            return {
                loading: false,
                comments: action.payload.comments,
                count: action.payload.count,
            };

        case EVENT_COMMENT_LIST_FAIL:
            return {
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
};

/* REDUCER USED IN ProductScreen COMPONENT */
export const eventCommentDetailsReducer = (state = { comment: {} }, action) => {
    switch (action.type) {
        case EVENT_COMMENT_DETAILS_REQUEST:
            return {
                loading: true,
                ...state,
            };

        case EVENT_COMMENT_DETAILS_SUCCESS:
            return {
                loading: false,
                comment: action.payload.comment,
            };

        case EVENT_COMMENT_DETAILS_FAIL:
            return {
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
};

/* REDUCER USED IN ProductListScreen COMPONENT */
export const eventCommentDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case EVENT_COMMENT_DELETE_REQUEST:
            return {
                loading: true,
            };

        case EVENT_COMMENT_DELETE_SUCCESS:
            return {
                loading: false,
                success: true,
            };

        case EVENT_COMMENT_DELETE_FAIL:
            return {
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
};

/* REDUCER USED IN ProductListScreen COMPONENT */
export const eventCommentCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case EVENT_COMMENT_CREATE_REQUEST:
            return {
                loading: true,
            };

        case EVENT_COMMENT_CREATE_SUCCESS:
            return {
                loading: false,
                success: true,
                comment: action.payload,
            };

        case EVENT_COMMENT_CREATE_FAIL:
            return {
                loading: false,
                error: action.payload,
            };

        case EVENT_COMMENT_CREATE_RESET:
            return {};

        default:
            return state;
    }
};

/* REDUCER USED IN ProductEditScreen COMPONENT */
export const eventCommentUpdateReducer = (state = { comment: {} }, action) => {
    switch (action.type) {
        case EVENT_COMMENT_UPDATE_REQUEST:
            return {
                loading: true,
            };

        case EVENT_COMMENT_UPDATE_SUCCESS:
            return {
                loading: false,
                success: true,
                comment: action.payload,
            };

        case EVENT_COMMENT_UPDATE_FAIL:
            return {
                loading: false,
                error: action.payload,
            };

        case EVENT_COMMENT_UPDATE_RESET:
            return { comment: {} };

        default:
            return state;
    }
};
