import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { deleteEvent, likeEvent, listEventDetails, unlikeEvent } from "../../features/actions/eventActions";
import { AnimatePresence, motion } from "framer-motion";
import {
  BsCalendar, BsCalendar2Check, BsCalendar2X, BsChevronLeft, BsChevronUp, BsHouse, BsPersonWorkspace, BsPinMap,
  BsTwitterX,
  BsXCircleFill
} from "react-icons/bs";
import { modifyImageUrl, REACT_API_URL } from "../../utilities/utils";
import { AiFillHeart, AiOutlineClockCircle, AiOutlineComment, AiOutlineHeart, AiOutlineShareAlt } from "react-icons/ai";
import AttendingModal from "../../components/events/AttendingModal";
import { format } from "timeago.js";
import EventCommentForm from "../../components/events/comments/EventCommentForm";
import EventComments from "../../components/events/comments/EventComments";
import { EVENT_DELETE_RESET } from "../../features/constants/eventCostants";
import { BiCopy, BiDotsHorizontalRounded, BiLoader, BiLogoFacebook, BiLogoWhatsapp, BiSolidTrashAlt } from "react-icons/bi";
import Loading from "../../components/loading/Loading";


const formatNumber = (num) => {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + 'M'; // 1.1M
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + 'k'; // 1.2k
  } else {
    return num;
  }
};


function EventsDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [option, setOptions] = useState(false);

  const [newImageUrl, setNewImageurl] = useState("/placeholder.jpg")

  const [modal, SetModal] = useState(false);
  const [isAttending, SetIsAttending] = useState(false);

  const [WriteComment, setWriteComment] = useState(false);
  const [shareOptions, setShareOptions] = useState(false);

  const getId = useParams();
  const eventId = getId.id;


  const url = window.location.href;

  const userLogin = useSelector((state) => state.userLogin);

  const { userInfo } = userLogin;

  const eventDetails = useSelector((state) => state.eventDetails);
  const { loading, event, success, error } = eventDetails;


  const eventDelete = useSelector((state) => state.eventDelete);
  const { loading: deleteLoading, success: deleteSucces, error: deleteError } = eventDelete;


  const eventLike = useSelector((state) => state.eventLike);
  const { loading: likeLoading, success: likeSuccess, error: likeError } = eventLike;


  const eventUnLike = useSelector((state) => state.eventUnLike);
  const { loading: unlikeLoading, success: unlikeSuccess, error: unlikeError } = eventUnLike;










  useEffect(() => {
    dispatch(listEventDetails(eventId));
  }, [dispatch, eventId]);


  useEffect(() => {

    if (success && event) {
      SetIsAttending(event?.is_attending);
   
    }


  }, [success, event]);


  useEffect(() => {

    if (likeSuccess || unlikeSuccess) {
      dispatch(listEventDetails(eventId))
    }


  }, [dispatch, likeSuccess, unlikeSuccess, eventId])





  useEffect(() => {

    if (event && event?.event_cover) {
      setNewImageurl(modifyImageUrl(event?.event_cover))
    }

  }, [success, event])



  const handleModal = () => {
    SetModal(!modal);
  };



  const handleCommentForm = () => {

    setWriteComment(!WriteComment);

  }



  const handleLike = (id) => {
    dispatch(likeEvent(id));
  };


  const handleUnLike = (id) => {
    dispatch(unlikeEvent(id));
  };

  const handleDelete = async (e) => {
    e.preventDefault();

    dispatch(deleteEvent(event?._id));
  };

  useEffect(() => {
    let _;
    if (deleteSucces === true) {

      dispatch({
        type: EVENT_DELETE_RESET,
      });

      navigate('/events')


    }




  }, [dispatch, deleteSucces]);

  const handleOptions = (e) => {
    setOptions(!option);
  }

  const handleShareOptions = (e) => {
    setShareOptions(!shareOptions);
  }


  const handleCopy = () => {
    navigator.clipboard.writeText(url)
      .then(() => {
        alert('Text copied to clipboard')
      })
      .catch(err => {
        alert(err)
      })

  }











  return (
    <div className="subpixel-antialiased min-h-screen inset-0 flex flex-col justify-center items-center content-center font-sans    mx-auto  lg:align-center lg:text-centen rounded-b-2xl p-3 mt-[10px] lg:mt-[50px] ">
      {/* central div */}

      {loading ? (
        <div className="flex flex-col justify-center items-center content-center text-center  gap-3 md:gap-5 p-2 md:p-3  w-full md:w-[80%] lg:w-[80%]">
           <Loading />
        </div>
      ) : (
        <div className="flex flex-col justify-center items-start content-center gap-3 md:gap-5 p-2 md:p-3  w-full md:w-[100%] lg:w-[80%]">
          {/* back button */}
          <motion.div
            onClick={() => navigate(-1)}
            className="mb-[20px] flex flex-row items-center content-center justify-center text-gray-500 hover:text-black min-w-[100px] rounded-full p-1 md:p-2 gap-1 md:gap-2 cursor-pointer text-[14px]"
          >
            <BsChevronLeft />
            <h1>Back</h1>
          </motion.div>

          <div className="flex flex-col gap-2 lg:grid lg:grid-cols-2 md:gap-3 w-full">
            {/* image and title */}
            <div className="flex flex-col gap-2">
              {/* title */}
              <div className="flex flex-col gap-2 w-full">
                <img
                  src={newImageUrl}
                  className="w-full h-[250px] lg:w-[50vw] md:h-[400px] rounded-xl md:[600px] object-cover object-center drop-shadow-xl"
                  alt=""
                />
              </div>

              <div className="flex flex-row items-end content-start justify-end gap-3 p-1 md:p-2 mt-[10px] border-b border-opacity-65 relative">
                <div className="flex flex-row items-center content-center justify-start gap-1">

                  {event?.has_liked ? (<AiFillHeart onClick={() => handleUnLike(event?._id)} className="text-red-500 cursor-pointer text-[1.4rem]" />

                  ) : (<AiOutlineHeart onClick={() => handleLike(event?._id)} className="cursor-pointer text-[1.4rem]" />)}




                  <p className="text-[0.8rem]">{formatNumber(event?.LikesCount)}</p>
                </div>

                <div className="flex flex-row items-center content-center justify-start gap-1">
                  <AiOutlineComment className="text-blue-700 cursor-pointer text-[1.4rem]" />
                  <p className="text-[0.8rem]">{formatNumber(event.CommentsCount)}</p>
                </div>

                <div
                  onClick={(e) => handleShareOptions(e)}
                  className="flex flex-row items-center content-center justify-start gap-1">
                  <AiOutlineShareAlt className="text-green-800 cursor-pointer text-[1.4rem]" />
                </div>

                {shareOptions && (
                  <motion.div
                    initial={{ bottom: 15, opacity: 0 }}
                    animate={{ bottom: 0, opacity: 1 }}
                    exit={{ bottom: 20, opacity: 0 }}
                    className="absolute bottom-[2rem] bg-white w-auto border p-1 md:p-2 rounded-lg shadow-lg">
                    <div className="flex flex-row items-center content-center justify-between w-full text-[12px] md:text-[14px] p-1 md:p-2">
                      <h1 className="font-[500] ">Share</h1>

                      <BsXCircleFill onClick={(e) => handleShareOptions(e)} className="text-[1rem] cursor-pointer" />
                    </div>
                    <ul className="flex flex-row items-center content-center justify-evenly  gap-1 md:gap-2 p-1 md:p-2">
                      <li className="flex flex-col items-center justify-center content-center gap-1 md:gap-2 p-1">
                        <div
                        onClick={() =>  handleCopy()}
                         className="bg-gray-100 flex flex-row items-center content-center justify-center text-center h-[40px] w-[40px] rounded-full border border-gray-200 hover:bg-gray-400 hover:border-gray-400 hover:text-white cursor-pointer">
                          <BiCopy className=" text-[1rem]" />

                        </div>

                        <h1 className="text-[11px] md:text-[12px]">Copy</h1>
                      </li>
                      <li className="flex flex-col items-center justify-center content-center gap-1 md:gap-2 p-1">
                        <a
                          href={`https://wa.me/?text=${encodeURIComponent('Check this out:' + url)}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="bg-gray-100 flex flex-row items-center content-center justify-center text-center h-[40px] w-[40px] rounded-full border border-gray-200 hover:text-white hover:bg-green-500 hover:border-green-500 cursor-pointer">
                          <BiLogoWhatsapp className=" text-[1rem]" />


                        </a>

                        <h1 className="text-[11px] md:text-[12px]">Whatsapp</h1>
                      </li>
                      <li className="flex flex-col items-center justify-center content-center gap-1 md:gap-2 p-1">
                        <a
                          href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(event?.title + '' + url)}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="bg-gray-100 flex flex-row items-center content-center justify-center text-center h-[40px] w-[40px] rounded-full border border-gray-200 hover:text-white hover:bg-blue-500 hover:border-blue-500 cursor-pointer">
                          <BsTwitterX className=" text-[1rem]" />


                        </a>

                        <h1 className="text-[11px] md:text-[12px]">Twitter</h1>
                      </li>
                      <li className="flex flex-col items-center justify-center content-center gap-1 md:gap-2 p-1">
                        <a
                          href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="bg-gray-100 flex flex-row items-center content-center justify-center text-center h-[40px] w-[40px] rounded-full border border-gray-200 hover:text-white hover:bg-blue-500 hover:border-blue-500 cursor-pointer">
                          <BiLogoFacebook className=" text-[1rem]" />


                        </a>

                        <h1 className="text-[11px] md:text-[12px]">Facebook</h1>
                      </li>
                    </ul>


                  </motion.div>)}
              </div>
            </div>

            {/* description */}
            <div className=" flex flex-col gap-2 sm:gap-3 p-1 md:p-2">
              <div className=" relative flex flex-row justify-between items-center content-center w-full">
                <div className="flex flex-row items-center content-start justify-start text-[12px] text-gray-500 gap-1 relative">

                  <AiOutlineClockCircle />
                  <p className="text-gray-700">
                    {format(event?.timestamp)}

                  </p>


                </div>
                <div>
                  {userInfo?.id === event?.user ? (

                    <div

                      onClick={handleOptions}
                      className="border border-gray-400 rounded-full p-[2px] text-center text-black cursor-pointer hover:bg-gray-600 hover:text-white hover:border-gray-600">
                      <BiDotsHorizontalRounded

                        className="text-[0.8rem] md:text-[14px]" />

                    </div>

                  ) : ""}


                  {option ? (<div className="absolute bg-white border top-6 right-1 rounded-md p-1 md:p-2 drop-shadow-xl text-[12px] md:text-[14px] min-w-[150px] md:min-w-[200px] z-30">
                    <ul className="flex flex-col justify-evenly items-end  gap-2 md:gap-3 w-full">
                      <li
                        onClick={(e) => handleDelete(e)}
                        className="flex flex-row justify-between content-center items-center gap-2 p-1 hover:bg-gray-600 hover:text-white group cursor-pointer rounded-sm w-full">
                        <h1>Delete This Post</h1>
                        <BiSolidTrashAlt className="group-hover:text-red-600" />
                      </li>

                    </ul>


                  </div>) : ""}

                </div>


              </div>

              {/* profile and name */}
              <div className="flex flex-row items-start content-start justify-start gap-2 w-full p-1 md:p-2">

                {/* image */}
                <img src={event?.User?.profile_photo ? `${REACT_API_URL}${event?.User?.profile_photo}` : "noavatar.jpg"} className="w-[50px] h-[50px] rounded-full object-cover object-center border border-gray-200" alt="profile-photo" />

                {/* name,title and time */}
                <div className="w-full flex flex-col gap-1">

                  <div className="flex flex-row items-start content-start justify-between w-full">

                    <div className="flex flex-col items-start content-start justify-start  h-auto">
                      <h1 className="flex flex-col gap-2 text-[0.8rem] text-gray-800 font-[400]">
                        {"@"}{event?.User?.sticker_name}
                      </h1>
                      <p className="font-[500] text-[0.9rem]">
                        {event?.User?.username}
                      </p>

                    </div>


                  </div>




                </div>





              </div>
              <div className="flex flex-col gap-2 text-lg font-[500]">


                <h1 className="text-[1rem]">{event.title}</h1>

              </div>

              <div className="flex flex-row justify-between p-1 md:p-2  ">
                <div className="flex flex-col gap-1 md:gap-2 justify-start  text-md items-start content-start">
                  <div className="flex flex-row items-center content-start justify-start gap-1 text-[12px]  text-gray-500">
                    <BsCalendar className="" />
                    <h1>Start Date</h1>
                  </div>
                  <p className="text-[12px] md:text-[14px] ">
                    {event?.start_date}
                  </p>
                  <h2 className="text-[11px] text-green-800">{format(event?.start_date)}</h2>
                </div>
                <div className="flex flex-col gap-1 md:gap-2 justify-end  text-md items-end content-start">
                  <div className="flex flex-row items-center content-start justify-end gap-1 text-[12px]  text-gray-500">
                    <BsCalendar className="" />
                    <h1>End Date  </h1>
                  </div>
                  <p className="text-[12px] md:text-[14px] ">
                    {event?.end_date}
                  </p>
                  <h2 className="text-[11px] text-green-800">{format(event?.end_date)}</h2>
                </div>

              </div>
              <div className="flex flex-row justify-between p-1 md:p-2  ">
                <div className="flex flex-col gap-1 md:gap-2 justify-start  text-md items-start content-start">
                  <div className="flex flex-row items-center content-start justify-end gap-1 text-[12px]  text-gray-500">
                    <BsPinMap className="" />
                    <h1>Location</h1>
                  </div>
                  <p className="text-[12px] md:text-[14px] ">
                    {event.location}
                  </p>

                </div>
                <div className="flex flex-col gap-1 md:gap-2 justify-end  text-md items-end content-start">
                  <div className="flex flex-row items-center content-start justify-end gap-1 text-[12px]  text-gray-500">
                    <BsHouse className="" />
                    <h1>Venue</h1>
                  </div>
                  <p className="text-[12px] md:text-[14px] ">
                    {event.venue}
                  </p>

                </div>
              </div>

              <div className="flex flex-col gap-1 md:gap-2 justify-end  text-md items-end content-start p-1 md:p-2">
                <div className="flex flex-row items-center content-start justify-end gap-1 text-[12px]  text-gray-500">
                  <BsPersonWorkspace className="" />
                  <h1>Attending</h1>
                </div>
                <p className="text-[12px] md:text-[14px] ">
                  {event?.attendees_count}
                </p>

              </div>

              <div className="text-[12px] md:text-[13px]">
                <p className="">{event?.description}</p>
              </div>



              {modal && (
                <AnimatePresence exitBeforeEnter={false}>
                  <AttendingModal
                    event={event}
                    is_attending={event?.is_attending}
                    handleClose={handleModal}
                  />
                </AnimatePresence>
              )}


              <div className="flex flex-col justify-end items-end content-end gap-1 w-full">

                {!event?.is_attending && (<div className="w-full">
                  <button
                    onClick={handleModal}
                    className="min-w-full md:min-w-full h-[50px] bg-green-600 text-[13px] md:text-[14px] rounded-md flex flex-row items-center content-center justify-center gap-2 text-white  hover:bg-green-500 p-1 md:p-2"

                  >
                    Add me to Attending List
                    <BsCalendar2Check />
                  </button>
                </div>)}



                {event?.is_attending && (<div className="w-full">
                  <button
                    onClick={handleModal}
                    className="min-w-full md:min-w-full h-[50px] bg-gray-600 text-[13px] md:text-[14px] rounded-md flex flex-row items-center content-center justify-center gap-2 text-white  hover:bg-gray-500 p-1 md:p-2"
                  >
                    Remove me from Attending list
                    <BsCalendar2X />
                  </button>
                </div>)}


              </div>


              {/* comment form */}
              <div className="p-1 border-t w-full">
                {/* Write a comment actions */}
                <div className="flex flex-row items-center content-center justify-end gap-1 text-[12px] cursor-pointer     w-full mb-2">
                  <div onClick={handleCommentForm} className="flex flex-row items-center content-center justify-end gap-1 text-[12px] cursor-pointer hover:bg-gray-200 rounded-md    p-1 md:p-2  ">

                    {WriteComment === true ? (<BsChevronUp className="font-[600]" />) : (<AiOutlineComment className="font-[600]" />)}
                    {WriteComment === true ? (<h1 className="font-[500]">Hide </h1>) : (<h1 className="font-[500]">Write a Comment</h1>)}



                  </div>
                </div>

                {WriteComment ? (<EventCommentForm eventId={eventId} />) : ("")}
              </div>
              {/* comment list */}
              <EventComments />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default EventsDetails;
