import React, {  useMemo, useState } from "react";
import PollItem from "./PollItem";



// Utility function to format percentages
const formatPercentage = (percentage) => {
  return percentage > 0 ? `${percentage.toFixed(1)}%` : '0%';
};




function PostPoll({choices}) {

  // Calculate the total number of votes
  // Calculate the total number of votes
  const totalVotes = useMemo(() => choices?.reduce((sum, choice) => sum + choice.votes, 0) || 0, [choices]);

 

   

  return (
    <div className="w-full p-1 md:p-2 border-t">

      <h1 className="flex flex-col items-start justify-start content-start gap-2 font-[500] text-[14px]">
        Votes
      </h1>
              <div>
                <ul>
                  {choices?.map((choice) => {
                    // Calculate percentage for the current choice
                    const percentage = totalVotes > 0 ? (choice.votes / totalVotes) * 100 : 0;

                    return (
                      <PollItem choice={choice} percentage={percentage} />
                    );
                  })}
                </ul>
              </div>
            </div>
  );
}

export default PostPoll;
