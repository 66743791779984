import React, { useEffect, useState } from 'react'
import { CancelAuthActions, usernameAvailability } from '../../../features/actions/AuthActions';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { BsArrowRight, BsCheckCircleFill, BsFilePersonFill, BsPersonFill } from 'react-icons/bs';
import Message from '../../../components/message/Message';
import { getUserDetails, updateUserProfile } from '../../../features/actions/userActions';
import { USER_UPDATE_PROFILE_RESET } from '../../../features/constants/userConstants';
import Loading from '../../../components/loading/Loading';
import { BiSolidChevronLeftCircle } from 'react-icons/bi';

function ChangeUsernamePage() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [username, setUsername] = useState("")
    const [usernameAccepted, setUsernameAccepted] = useState("");
    const [UsernameMessage, setUsernameMessage] = useState("");

    const usernameAvailabilityCheck = useSelector((state) => state.usernameAvailabilityCheck);

    const { success: usernameAvailabilitySuccess, invalid, loading: usernameLoading, message, error: usernameError } = usernameAvailabilityCheck;

    // makign sure user is logged in
    const userLogin = useSelector((state) => state.userLogin);

    const { userInfo } = userLogin;

    // get user details
    const userDetails = useSelector((state) => state.userDetails);

    const { user, loading, error } = userDetails;



    // updating user details
    const userUpdateProfle = useSelector((state) => state.userUpdateProfle);

    const { success, loading: updateLoading, } = userUpdateProfle;


    // redirect
    const redirect = location.state?.from || "/";

    useEffect(() => {
        if (usernameAvailabilitySuccess) {
            setUsernameAccepted(message?.username);
        }
    }, [username, usernameAvailabilitySuccess, message])



    useEffect(() => {
        if (!userInfo) {
            navigate(redirect);
        } else {
            if (!user || !user.email || success || userInfo._id !== user._id) {

                dispatch({ type: USER_UPDATE_PROFILE_RESET });

                dispatch(getUserDetails("profile"));
                dispatch(CancelAuthActions());
                navigate("/account");
            } else {

                setUsername(user.username)

            }
        }
    }, [dispatch, navigate, user, userInfo, redirect, success]);






    const handleCheckUsername = (e) => {
        e.preventDefault();
        dispatch(usernameAvailability(username));
    }

    const handleCancel = (e) => {
       
        dispatch(CancelAuthActions());
        navigate(-1);
    }


    const handleSave = (e) => {
        e.preventDefault();
        dispatch(
            updateUserProfile({
                id: user._id,
                first_name: userInfo.first_name,
                last_name: userInfo.last_name,
                username: usernameAccepted,
                sticker_name: userInfo.sticker_name,
                mobile: userInfo.mobile,
                country: userInfo.country,
                bio: userInfo.bio,
                company: userInfo.company,
            })
        );
    };


    const handleUsernameRegex = (event) => {
        const { value } = event.target;
        const regex = /^[a-zA-Z0-9]*$/;

        if (regex.test(value)) {
            setUsername(value);
        }
    }
    return (
        <div className="">
            <main className="min-h-[500px] ">
                <div className="subpixel-antialiased  bg-hero-cover bg-no-repeat bg-center bg-cover   flex flex-row justify-center items-center content-center font-sans    mx-auto lg:justify-around lg:align-center lg:text-center  p-3 lg:p-4 min-h-screen">


                    <div className='inset-0 flex flex-row justify-center items-center content-center font-sans    w-full sm:w-[70vw]  md:w-[60vw] lg:w-[50%]'>
                        <div className='flex flex-col justify-center content-center items-center w-full gap-1 md:gap-2'>

                            <div onClick={handleCancel} className="flex flex-row self-start justify-start  items-center content-center gap-1 md:gap-2 cursor-pointer text-[12px] md:text-[14px] mb-[10px] text-blue-600 hover:text-blue-500">

                                <span className=""><BiSolidChevronLeftCircle className='cursor-pointer text-[1.4rem]' /></span>
                                <h1>Cancel</h1>

                            </div>





                            <div className='text-[1rem] md:text-[1.4rem] font-[500] flex flex-row items-center content-center justify-center gap-1 md:gap-2 mb-[20px]'>
                                <h1>Change Username</h1>
                                <BsPersonFill/>

                            </div>
                            {/* central div */}
                            {/* username */}
                            <div className="flex flex-col  justify-start content-start  items-start gap-1 md:gap-2 p-1 md:p-2 text-[12px] md:text-[13px]  w-full ">

                                <div className='flex flex-row justify-between w-full'>

                                    {/* username */}
                                    <div className='flex flex-row items-center content-center justify-start gap-1 md:gap-2 h-auto'>
                                        <h1 className="font-[500] text-gray-900">
                                            Username</h1>
                                        <span className='text-gray-800 text-[12px] md:text-[14px]'>
                                            <BsPersonFill />
                                        </span>
                                    </div>

                                    {/* check username button */}
                                    <div
                                        onClick={(e) => handleCheckUsername(e)}
                                        className='text-[12px] flex flex-row gap-1 md:gap-2 items-center content-center justify-center cursor-pointer md:text-[14px] text-green-600 hover::text-green-500'>

                                        <h1>Check Username</h1>
                                        <BsArrowRight />

                                    </div>

                                </div>


                                <input
                                    className="p-1 md:p-2  border-b bg-none outline-none  placeholder:text-gray-400 placeholder:text-[11px] placeholder:pl-4  w-full md:min-w-full lg:min-w-full h-[50px] md:h-[60px] text-[13px]  font-[600] "
                                    type="text"
                                    value={username}
                                    onChange={handleUsernameRegex}
                                    placeholder="username (unique)"

                                />

                                <p className="text-[11px] md:text-[12px] bg-gray-50 p-1 text-left rounded text-gray-500">Only Aphanumeric Characters</p>

                                {usernameAvailabilitySuccess ? (
                                    <div className="flex flex-row justify-start items-center content-center gap-1 md:gap-2 text-[12px] md:text-[14px]">
                                        <BsCheckCircleFill className="text-green-600" />

                                        <span className="text-green-600">{usernameAccepted}</span>
                                    </div>

                                ) : ""}

                                <div>

                                    {usernameLoading === true ? (<div className='w-full text-[12px] md:text-[13px] animate-pulse'>
                                        <Loading />

                                    </div>) : ""}

                                    {usernameAvailabilitySuccess & username === usernameAccepted ? (<Message type="success">{message?.detail}</Message>) : ("")}
                                    {invalid || usernameError ? (<Message type="error">{usernameError?.detail || usernameError}</Message>) : ("")}


                                </div>
                            </div>



                            {usernameAvailabilitySuccess === true & !loading & username ===  usernameAccepted ? (
                                <div
                                    onClick={(e) => handleSave(e)}
                                    className="p-2 flex flex-col items-center content-center justify-center   bg-none outline-none rounded-xl bg-blue-600 text-white  hover:bg-blue-700  w-full h-[60px] text-[11px] md:text-[14px]  m-1 drop-shadow-lg shadow-blue-500/50 text-center cursor-pointer">
                                    Save Username
                                </div>

                            ) : ""}



                            {loading ? (
                                <div

                                    className="w-full  ">
                                    <Loading />
                                </div>
                            ) : ("")}









                        </div>

                    </div>


                </div>
            </main>
        </div>
    )
}

export default ChangeUsernamePage