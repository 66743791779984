import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Paginate from "../dashboard/navigation/Paginate";
import { useLocation, useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { listMyEvents } from "../../features/actions/eventActions";
import EventLoading from "../loading/EventLoading";
import EventItem from "./EventItem";
import { BsChevronLeft } from "react-icons/bs";

function AllProfileEvents() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [choices, SetChoices] = useState(false);

  const eventMyList = useSelector((state) => state.eventMyList);

  const { events, page, pages, loading, error } = eventMyList;


  const eventLike = useSelector((state) => state.eventLike);
  const { loading: likeLoading, success: likeSuccess, error: likeError } = eventLike;


  const eventUnLike = useSelector((state) => state.eventUnLike);
  const { loading: unlikeLoading, success: unlikeSuccess, error: unlikeError } = eventUnLike;




  const { search } = useLocation();

  const route = location.route;

  /* FIRING OFF THE ACTION CREATORS USING DISPATCH */

  let keyword =
    location.search; /* IF USER SEARCHES FOR ANYTHING THEN THIS KEYWORD CHANGES AND USE EFFECT GETS TRIGGERED */

  useEffect(() => {
    dispatch(listMyEvents(keyword));
  }, [dispatch, keyword]);


  useEffect(() => {

    if (likeSuccess || unlikeSuccess) {
      dispatch(listMyEvents(keyword));
    }


  }, [dispatch, likeSuccess, unlikeSuccess, keyword])






  return (
    <div className=" p-1 md:p-2   flex flex-col gap-3 justify-center content-center items-center text-black  mx-auto bg-white w-full lg:mt-[100px]">

      <motion.div
        onClick={() => navigate(-1)}
        className=" flex flex-row items-center content-center justify-start text-gray-500 hover:text-black w-full   gap-1 md:gap-2 cursor-pointer mb-[20px] text-[14px]"
      >
        <BsChevronLeft />
        <h1>Back</h1>
      </motion.div>



      {/* center items */}
      {loading ? <div className="flex flex-col  justify-center content-center items-center gap-3 md:gap-4  w-full ">
        <EventLoading />
      </div>
        : ""}

      {events === 0 & !loading ? (
        <div className="flex flex-col  justify-center items-center content-center w-full">
          <h1 className="text-gray-500 text-md md:text-xl">No Events Found</h1>
        </div>


      ) : (
        <div className="flex flex-col  justify-center content-center items-center gap-3 md:gap-4  w-full ">
          {events?.map((event) => (
            <EventItem key={event._id} event={event} />
          )
          )}



        </div>

      )}


      {/* pagination */}
      <div className="p-2 md:p-3 mt-[20px]">
        <Paginate page={page} pages={pages} keyword={keyword} route="/account/events" />
      </div>



    </div>
  );
}

export default AllProfileEvents;
