import React, { useEffect, useState } from "react";
import { AiFillHeart, AiOutlineClockCircle, AiOutlineComment, AiOutlineHeart, AiOutlineShareAlt } from "react-icons/ai";
import { REACT_API_URL, modifyImageUrl } from "../../utilities/utils";
import { useLocation, useNavigate } from "react-router-dom";
import {  BsHouseCheck, BsPinMap, BsTwitterX, BsXCircleFill } from "react-icons/bs";
import {  BiCalendarCheck, BiCalendarX, BiChevronRight, BiCopy, BiDotsHorizontalRounded, BiLogoFacebook, BiLogoWhatsapp, BiSolidTime, BiSolidTrashAlt } from "react-icons/bi";
import { format } from "timeago.js";
import { useDispatch, useSelector } from "react-redux";
import { deleteEvent, likeEvent, listEvents, unlikeEvent } from "../../features/actions/eventActions";
import { EVENT_DELETE_RESET } from "../../features/constants/eventCostants";
import { motion } from "framer-motion";


const formatNumber = (num) => {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + 'M'; // 1.1M
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + 'k'; // 1.2k
  } else {
    return num;
  }
};

function EventItem({ event }) {

  const location = useLocation();

  const navigate = useNavigate();

  const dispatch = useDispatch();


  const url = window.location.origin;

  const [option, setOptions] = useState(false);
  const [shareOptions, setShareOptions] = useState(false);


  const description = event.description.length > 100 ? `${event.description.substring(0, 100)}...` : event.description;


  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const eventDelete = useSelector((state) => state.eventDelete);
  const { loading, success, error, } = eventDelete;






  const handleLike = (id) => {
    dispatch(likeEvent(id));
  };


  const handleUnLike = (id) => {
    dispatch(unlikeEvent(id));
  };


  const handleDelete = async (e) => {
    e.preventDefault();

    dispatch(deleteEvent(event?._id));
  };

  let keyword =
    location.search; /* IF USER SEARCHES FOR ANYTHING THEN THIS KEYWORD CHANGES AND USE EFFECT GETS TRIGGERED */

  useEffect(() => {
    let _;
    if(success === true) {
      dispatch(listEvents(keyword));
      dispatch({
        type: EVENT_DELETE_RESET,
      });
      
    }

    


  }, [dispatch, keyword, success]);

  const handleOptions = (e) => {
    setOptions(!option);
  }

  const handleShareOptions = (e) => {
    setShareOptions(!shareOptions);
  }

  
  const handleCopy = () => {
    navigator.clipboard.writeText(url +"/events/" + event?._id)
    .then(()=> {
      alert('Text copied to clipboard')
    })
    .catch(err => {
      alert(err)
    })

  }





  return (
    <div className=" w-full sm:w-[90vw] md:w-[90vw] flex flex-col justify-center content-center items-center">


      <div className="flex flex-col sm:flex-row items-start content-start justify-center gap-2 w-full md:w-[80%] p-2 sm:p-3 border rounded-lg bg-gray-50 boder-gray-100">
        {/* image section */}
        <div className="w-full  relative">
          <img 
          loading="lazy"
          src={modifyImageUrl(event?.event_cover)} className="w-[100vw] h-[200px] md:h-[300px] object-cover object-center rounded-lg" alt="" />

        </div>

        {/* other details */}

        <div className=" flex flex-col items-start content-start justify-between h-full w-full p-1 sm:p-2 gap-2">

        <div className=" relative flex flex-row justify-between items-center content-center w-full">
            <div className="flex flex-row items-center content-start justify-start text-[12px] text-gray-500 gap-1 relative">

              <AiOutlineClockCircle />
              <p className="text-gray-700">
                {format(event?.timestamp)}

              </p>


            </div>
            <div>
              {userInfo?.id === event?.user ? (

                <div

                  onClick={handleOptions}
                  className="border border-gray-400 rounded-full p-[2px] text-center text-black cursor-pointer hover:bg-gray-600 hover:text-white hover:border-gray-600">
                  <BiDotsHorizontalRounded

                    className="text-[0.8rem] md:text-[14px]" />

                </div>

              ) : ""}


              {option ? (<div className="absolute bg-white border top-6 right-1 rounded-md p-1 md:p-2 drop-shadow-xl text-[12px] md:text-[14px] min-w-[150px] md:min-w-[200px] z-30">
                <ul className="flex flex-col justify-evenly items-end  gap-2 md:gap-3 w-full">
                  <li
                    onClick={(e) => handleDelete(e)}
                    className="flex flex-row justify-between content-center items-center gap-2 p-1 hover:bg-gray-600 hover:text-white group cursor-pointer rounded-sm w-full">
                    <h1>Delete This Post</h1>
                    <BiSolidTrashAlt className="group-hover:text-red-600" />
                  </li>

                </ul>


              </div>) : ""}

            </div>


          </div>


          <div className="relative  p-1 flex flex-row items-start content-start justify-between gap-2 w-full h-full">

            {/* profile and name */}
            <div className="  flex flex-row items-start content-start justify-start gap-2 w-full md:w-full p-1 h-full">

              {/* image */}
              <img
              loading="lazy"
               src={event?.User?.profile_photo ? `${REACT_API_URL}${event?.User?.profile_photo}` : "noavatar.jpg"} className="w-[20px] h-[20px]  rounded-full object-cover object-center border border-gray-200" alt="profile-photo" />

              {/* name,title and time */}
              <div className="w-full flex flex-row justify-between items-start content-start gap-1">

                <div className="flex flex-row items-start content-start justify-between w-full">
                  <h1 className="flex flex-row items-center content-center justify-start text-[0.7rem] md:text-[0.8rem] text-gray-800 font-[500] truncate">
                    <span className="font-[400]">{event?.User?.username}{" "}</span>
                    {"@"}{event?.User?.sticker_name}
                  </h1>
                </div>
                
              </div>
            </div>
          </div>

          <div className="flex flex-col items-start content-start justify-start w-full text-[14px] text-left gap-1 h-full">
            <h1 className="font-[500]">
              {event?.title}
            </h1>
            <p className="text-[12px]">
              {event.description ? (event.description.length > 100 ? `${event.description.substring(0, 100)}...` : event.description) :""}
            </p>
          </div>

          <div className="flex flex-grow items-center content-center justify-between gap-2 md:gap-3 w-full h-full">

            <div className="flex flex-col items-start content-start justify-start w-full text-[14px] text-left gap-1">
              <div className="flex flex-row items-center content-center justify-start gap-1 md:gap-2">
                <BiCalendarCheck className="text-gray-500" />

                <h1 className="text-[12px]">{format(event?.start_date)}</h1>
              </div>
              <div className="flex flex-row items-center content-center justify-start gap-1 md:gap-2">
                <BiCalendarX className="text-gray-500" />

                <h1 className="text-[12px]">{format(event?.end_date)}</h1>
              </div>

            </div>
            <div className="flex flex-col items-end content-start justify-end w-full text-[14px] text-left gap-1">
              <div className="flex flex-row items-center content-center justify-end gap-1 md:gap-2">
                <BsHouseCheck className="text-gray-500" />

                <h1 className="text-[12px]">{event?.venue}</h1>
              </div>
              <div className="flex flex-row items-center content-center justify-end gap-1 md:gap-2">
                <BsPinMap className="text-gray-500" />

                <h1 className="text-[12px]">{event?.location}</h1>
              </div>

            </div>


          </div>

          <div className="flex flex-row items-center content-start justify-between gap-3 p-1 md:p-2 border-t border-gray-200 pt-[5px] w-full h-full">
            <div className="flex flex-row items-start content-start justify-start gap-3 relative">
              <div className="flex flex-row items-center content-center justify-start gap-1">

                {event?.has_liked ? (<AiFillHeart onClick={() => handleUnLike(event?._id)} className="text-red-500 cursor-pointer text-[1.4rem]" />

                ) : (<AiOutlineHeart onClick={() => handleLike(event?._id)} className="cursor-pointer text-[1.4rem]" />)}




                <p className="text-[0.8rem]">{formatNumber(event?.LikesCount)}</p>
              </div>

              <div className="flex flex-row items-center content-center justify-start gap-1">
                <AiOutlineComment className="text-blue-700 cursor-pointer text-[1.4rem]" />
                <p className="text-[0.8rem]">{formatNumber(event.CommentsCount)}</p>
              </div>

              <div
              onClick={(e) => handleShareOptions(e)}
              className="flex flex-row items-center content-center justify-start gap-1">
              <AiOutlineShareAlt className="text-green-800 cursor-pointer text-[1.4rem]" />
            </div>

            {shareOptions && (
              <motion.div
              initial={{bottom:15, opacity:0}}
              animate={{bottom:0, opacity:1}}
              exit={{bottom:20, opacity:0}}
               className="absolute bottom-[2rem] bg-white w-auto border p-1 md:p-2 rounded-lg shadow-lg">
              <div className="flex flex-row items-center content-center justify-between w-full text-[12px] md:text-[14px] p-1 md:p-2">
                <h1 className="font-[500] ">Share</h1>

                <BsXCircleFill onClick={(e) => handleShareOptions(e)} className="text-[1rem] cursor-pointer" />
              </div>
              <ul className="flex flex-row items-center content-center justify-evenly  gap-1 md:gap-2 p-1 md:p-2">
                <li className="flex flex-col items-center justify-center content-center gap-1 md:gap-2 p-1">
                  <div 
                  onClick={() =>handleCopy()}
                  className="bg-gray-100 flex flex-row items-center content-center justify-center text-center h-[40px] w-[40px] rounded-full border border-gray-200 hover:bg-gray-400 hover:border-gray-400 hover:text-white cursor-pointer">
                    <BiCopy className=" text-[1rem]" />

                  </div>

                  <h1 className="text-[11px] md:text-[12px]">Copy</h1>
                </li>
                <li className="flex flex-col items-center justify-center content-center gap-1 md:gap-2 p-1">
                  <a
                    href={`https://wa.me/?text=${encodeURIComponent('Check this out:' + url +"/events/"  + event?._id)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-gray-100 flex flex-row items-center content-center justify-center text-center h-[40px] w-[40px] rounded-full border border-gray-200 hover:text-white hover:bg-green-500 hover:border-green-500 cursor-pointer">
                    <BiLogoWhatsapp className=" text-[1rem]" />


                  </a>

                  <h1 className="text-[11px] md:text-[12px]">Whatsapp</h1>
                </li>
                <li className="flex flex-col items-center justify-center content-center gap-1 md:gap-2 p-1">
                  <a
                    href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(event?.title  + url + "/events/"  + event?._id)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-gray-100 flex flex-row items-center content-center justify-center text-center h-[40px] w-[40px] rounded-full border border-gray-200 hover:text-white hover:bg-blue-500 hover:border-blue-500 cursor-pointer">
                    <BsTwitterX className=" text-[1rem]" />


                  </a>

                  <h1 className="text-[11px] md:text-[12px]">Twitter</h1>
                </li>
                <li className="flex flex-col items-center justify-center content-center gap-1 md:gap-2 p-1">
                  <a
                    href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url + "/events/"  + event?._id)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-gray-100 flex flex-row items-center content-center justify-center text-center h-[40px] w-[40px] rounded-full border border-gray-200 hover:text-white hover:bg-blue-500 hover:border-blue-500 cursor-pointer">
                    <BiLogoFacebook className=" text-[1rem]" />


                  </a>

                  <h1 className="text-[11px] md:text-[12px]">Facebook</h1>
                </li>
              </ul>


            </motion.div>)}

            </div>
            {/* view */}
            <div
              onClick={() => navigate(`/events/${event._id}`)}
              className="flex flex-row gap-1 items-center contnt-center justify-center bg-gray-50 border border-gray-200 p-2 text-[0.9rem] rounded-full min-w-[80px] hover:bg-gray-100 hover:border-gray-200 cursor-pointer">
              <h1 className="text-gray-600">view</h1>
              <BiChevronRight />
            </div>
          </div>

        </div>
      </div>

    </div>
  );
}

export default EventItem;
