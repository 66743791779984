
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { REACT_API_URL } from "../../utilities/utils";
import { AiFillHeart, AiOutlineClockCircle, AiOutlineHeart, AiOutlineComment, AiOutlineShareAlt } from "react-icons/ai";
import { BiChevronRight, BiCopy, BiDotsHorizontalRounded, BiLogoFacebook, BiLogoTwitter, BiLogoWhatsapp, BiSolidTrashAlt } from "react-icons/bi"
import { format } from "timeago.js"
import { deletePost, likePost, listPosts, unlikePost } from "../../features/actions/postActions";
import FeedPollItem from "./polls/FeedPollItem";
import { POST_DELETE_RESET } from "../../features/constants/postConstants";
import { BsTwitterX, BsXCircleFill } from "react-icons/bs";
import { motion } from "framer-motion"


const formatNumber = (num) => {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + 'M'; // 1.1M
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + 'k'; // 1.2k
  } else {
    return num;
  }
};

// Utility function to format percentages
const formatPercentage = (percentage) => {
  return percentage > 0 ? `${percentage.toFixed(1)}%` : '0%';
};



function PostItem({ post }) {
  const { User, choices, is_poll, has_liked, title, content, timestamp, _id } = post || {};


  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const url = window.location.origin;



  const [option, setOptions] = useState(false);
  const [shareOptions, setShareOptions] = useState(false);

  const postDelete = useSelector((state) => state.postDelete);
  const { loading, success, error, } = postDelete;


  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;



  let keyword =
    location.search; /* IF USER SEARCHES FOR ANYTHING THEN THIS KEYWORD CHANGES AND USE EFFECT GETS TRIGGERED */


  const handleLike = (id) => {
    dispatch(likePost(id));
  };


  const handleUnLike = (id) => {
    dispatch(unlikePost(id));
  };

  // Calculate the total number of votes
  const totalVotes = useMemo(() => post?.choices?.reduce((sum, choice) => sum + choice.votes, 0) || 0, [post?.choices]);

  const handleOptions = (e) => {
    setOptions(!option);
  }
  const handleShareOptions = (e) => {
    setShareOptions(!shareOptions);
  }


  const handleDelete = async (e) => {
    e.preventDefault();

    dispatch(deletePost(post?._id));
  };

  useEffect(() => {
    if (success) {
      dispatch(listPosts());
      dispatch({
        type: POST_DELETE_RESET,
      });
    }
  }, [dispatch, success])




  const handleCopy = () => {
    navigator.clipboard.writeText(url + "/post/" + post._id)
      .then(() => {
        alert('Text copied to clipboard')
      })
      .catch(err => {
        alert(err)
      })

  }








  return (
    <div className="w-[90vw] md:w-[70vw]">

      <div className={loading ? "animate-pulse min-w-full border rounded-lg p-1 md:p-2" : "min-w-full border rounded-lg p-1 md:p-2"}>


        {/* profile and time section */}

        <div className="p-1 flex flex-row items-start content-start justify-between gap-2 ">

          {/* profile and name */}
          <div className="flex flex-row items-start content-start justify-start gap-2 w-full p-1 md:p-2">

            {/* image */}
            <img src={post?.User?.profile_photo ? `${REACT_API_URL}${post?.User?.profile_photo}` : "noavatar.jpg"} className="w-[50px] h-[50px] rounded-full object-cover object-center border border-gray-200" alt="profile-photo" />

            {/* name,title and time */}
            <div className="w-full flex flex-col gap-1">

              <div className="flex flex-row items-start content-start justify-between w-full">

                <div className="flex flex-col items-start content-start justify-start  h-auto">
                  <h1 className="flex flex-col gap-2 text-[0.8rem] text-gray-800 font-[400]">
                    {"@"}{User?.sticker_name}
                  </h1>
                  <p className="font-[500] text-[0.9rem]">
                    {User?.username}
                  </p>

                </div>

                <div className="flex flex-row items-center content-start justify-start text-[12px] text-gray-500 gap-1 relative">

                  <AiOutlineClockCircle />
                  <p className="text-gray-700">
                    {format(timestamp)}

                  </p>


                  {userInfo?.id === post?.user ? (

                    <div

                      onClick={handleOptions}
                      className="border border-gray-400 rounded-full p-[2px] text-center text-black cursor-pointer hover:bg-gray-600 hover:text-white hover:border-gray-600">
                      <BiDotsHorizontalRounded

                        className="text-[0.8rem] md:text-[14px]" />

                    </div>

                  ) : ""}


                  {option ? (<div className="absolute bg-white border top-6 right-1 rounded-md p-1 md:p-2 drop-shadow-xl text-[12px] md:text-[14px] min-w-[150px] md:min-w-[200px]">
                    <ul className="flex flex-col justify-evenly items-end  gap-2 md:gap-3 w-full">
                      <li
                        onClick={(e) => handleDelete(e)}
                        className="flex flex-row justify-between content-center items-center gap-2 p-1 hover:bg-gray-600 hover:text-white group cursor-pointer rounded-sm w-full">
                        <h1>Delete This Post</h1>
                        <BiSolidTrashAlt className="group-hover:text-red-600" />
                      </li>

                    </ul>


                  </div>) : ""}






                </div>

              </div>




            </div>





          </div>
        </div>
        {/* title , description, cover image */}
        <div className=" flex flex-col items-start content-start justify-start gap-2 p-1 md:p-2 ">
          <div className="text-gray-900 text-[0.9rem] font-[500]">
            <h1 >
              {title}
            </h1>
          </div>
          <p className="text-[0.8rem]">
            {content ? (post?.content.length > 100 ? `${post?.content.substring(0, 100)}...` : post?.content) : ""}
          </p>
          {is_poll ? (
            <FeedPollItem choices={post?.choices} totalVotes={totalVotes} />
          ) : (

            ""
          )}

          {post?.cover_photo ? (<img src={post?.cover_photo ? `${REACT_API_URL}${post?.cover_photo}` : ""} className="w-full h-[180px] object-cover object-center rounded-md" alt="" />) : ""}



        </div>
        <div className="flex flex-row items-center content-start justify-between gap-3 p-1 md:p-2 border-t border-gray-200 pt-[5px]">
          <div className="flex flex-row items-start content-start justify-start gap-3 relative">
            <div className="flex flex-row items-center content-center justify-start gap-1">

              {has_liked ? (<AiFillHeart onClick={() => handleUnLike(post?._id)} className="text-red-500 cursor-pointer text-[1.4rem]" />

              ) : (<AiOutlineHeart onClick={() => handleLike(post?._id)} className="cursor-pointer text-[1.4rem]" />)}



              <p className="text-[0.8rem]">{formatNumber(post.LikesCount)}</p>
            </div>

            <div className="flex flex-row items-center content-center justify-start gap-1">
              <AiOutlineComment className="text-blue-700 cursor-pointer text-[1.4rem]" />
              <p className="text-[0.8rem]">{formatNumber(post.CommentsCount)}</p>
            </div>

            <div
              onClick={(e) => handleShareOptions(e)}
              className="flex flex-row items-center content-center justify-start gap-1">
              <AiOutlineShareAlt className="text-green-800 cursor-pointer text-[1.4rem]" />
            </div>

            {shareOptions && (
              <motion.div
                initial={{ bottom: 15, opacity: 0 }}
                animate={{ bottom: 0, opacity: 1 }}
                exit={{ bottom: 20, opacity: 0 }}
                className="absolute bottom-[2rem] bg-white w-auto border p-1 md:p-2 rounded-lg shadow-lg">
                <div

                  className="flex flex-row items-center content-center justify-between w-full text-[12px] md:text-[14px] p-1 md:p-2">
                  <h1 className="font-[500] ">Share</h1>

                  <BsXCircleFill onClick={(e) => handleShareOptions(e)} className="text-[1rem] cursor-pointer" />
                </div>
                <ul className="flex flex-row items-center content-center justify-evenly  gap-1 md:gap-2 p-1 md:p-2">
                  <li className="flex flex-col items-center justify-center content-center gap-1 md:gap-2 p-1">
                    <div
                      onClick={() => handleCopy()}
                      className="bg-gray-100 flex flex-row items-center content-center justify-center text-center h-[40px] w-[40px] rounded-full border border-gray-200 hover:bg-gray-400 hover:border-gray-400 hover:text-white cursor-pointer">
                      <BiCopy className=" text-[1rem]" />

                    </div>

                    <h1 className="text-[11px] md:text-[12px]">Copy</h1>
                  </li>
                  <li className="flex flex-col items-center justify-center content-center gap-1 md:gap-2 p-1">
                    <a
                      href={`https://wa.me/?text=${encodeURIComponent('Check this out:' + url + "/post/" + post._id)}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="bg-gray-100 flex flex-row items-center content-center justify-center text-center h-[40px] w-[40px] rounded-full border border-gray-200 hover:text-white hover:bg-green-500 hover:border-green-500 cursor-pointer">
                      <BiLogoWhatsapp className=" text-[1rem]" />


                    </a>

                    <h1 className="text-[11px] md:text-[12px]">Whatsapp</h1>
                  </li>
                  <li className="flex flex-col items-center justify-center content-center gap-1 md:gap-2 p-1">
                    <a
                      href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(post?.title + '' + url + "/post/" + post._id)}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="bg-gray-100 flex flex-row items-center content-center justify-center text-center h-[40px] w-[40px] rounded-full border border-gray-200 hover:text-white hover:bg-blue-500 hover:border-blue-500 cursor-pointer">
                      <BsTwitterX className=" text-[1rem]" />


                    </a>

                    <h1 className="text-[11px] md:text-[12px]">Twitter</h1>
                  </li>
                  <li className="flex flex-col items-center justify-center content-center gap-1 md:gap-2 p-1">
                    <a
                      href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url + "/post/" + post._id)}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="bg-gray-100 flex flex-row items-center content-center justify-center text-center h-[40px] w-[40px] rounded-full border border-gray-200 hover:text-white hover:bg-blue-500 hover:border-blue-500 cursor-pointer">
                      <BiLogoFacebook className=" text-[1rem]" />


                    </a>

                    <h1 className="text-[11px] md:text-[12px]">Facebook</h1>
                  </li>
                </ul>


              </motion.div>)}




          </div>
          {/* view */}
          <div
            onClick={() => navigate(`/post/${_id}`)}
            className="flex flex-row gap-1 items-center contnt-center justify-center bg-gray-50 border border-gray-200 p-2 text-[0.9rem] rounded-full min-w-[80px] hover:bg-gray-100 hover:border-gray-200 cursor-pointer">
            <h1 className="text-gray-600">view</h1>
            <BiChevronRight />
          </div>
        </div>

      </div>
    </div>
  );
}

export default PostItem;
