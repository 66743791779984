import React, { useEffect, useState } from "react";
import { BsArrowLeft, BsBullseye, BsChevronDown, BsChevronLeft, BsChevronUp, BsPaypal, BsPiggyBank, BsTwitterX, BsXCircleFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { deleteDonation, likeDonation, listDonationDetails, unlikeDonation } from "../../features/actions/donationsActions";
import { motion } from "framer-motion";
import { modifyImageUrl, REACT_API_URL } from "../../utilities/utils";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { AiFillHeart, AiOutlineClockCircle, AiOutlineComment, AiOutlineHeart, AiOutlineMobile, AiOutlineShareAlt } from "react-icons/ai";
import { format } from "timeago.js";
import DonationCommentForm from "../../components/donations/comment/DonationCommentForm";
import DonationComments from "../../components/donations/comment/DonationComments";
import { DONATION_DELETE_RESET } from "../../features/constants/donationConstants";
import { BiCopy, BiDotsHorizontalRounded, BiInfoCircle, BiLoader, BiLogoFacebook, BiLogoWhatsapp, BiSolidTrashAlt } from "react-icons/bi";
import Loading from "../../components/loading/Loading";




const formatNumber = (num) => {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + 'M'; // 1.1M
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + 'k'; // 1.2k
  } else {
    return num;
  }
};



function DonationDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [newImageUrl, setNewImageurl] = useState("/placeholder.jpg")
  const [amount, setAmount] = useState(0);
  const [donate, setDonate] = useState(false);


  const url = window.location.href;

  const [option, setOptions] = useState(false);
  const [shareOptions, setShareOptions] = useState(false);


  const [WriteComment, setWriteComment] = useState(false);



  const getId = useParams();
  const donationId = getId.id;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const donationDetails = useSelector((state) => state.donationDetails);
  const { loading, success, donation, error } = donationDetails;

  const donationDelete = useSelector((state) => state.donationDelete);
  const { loading: deleteLoading, success: deleteSucces, error: deleteError } = donationDelete;


  const donationLike = useSelector((state) => state.donationLike);
  const { loading: likeLoading, success: likeSuccess, error: likeError } = donationLike;


  const donationUnLike = useSelector((state) => state.donationUnLike);
  const { loading: unlikeLoading, success: unlikeSuccess, error: unlikeError } = donationUnLike;




  useEffect(() => {

    if (donation && donation.donation_cover) {
      setNewImageurl(modifyImageUrl(donation?.donation_cover))
    }

  }, [success, donation])





  useEffect(() => {
    dispatch(listDonationDetails(donationId));
  }, [dispatch, donationId]);



  useEffect(() => {

    if (likeSuccess || unlikeSuccess) {
      dispatch(listDonationDetails(donationId))
    }


  }, [dispatch, likeSuccess, unlikeSuccess, donationId])


  const paypalOptions = {
    "client-id": "YOUR_PAYPAL_CLIENT_ID",
    currency: "USD",
    intent: "capture",
  };

  // Define PayPal button styles
  const buttonStyles = {
    layout: "horizontal",
    color: "gold",
    shape: "rect",
    label: "paypal",

  };

  // Define onApprove and onError functions
  const onApprove = (data, actions) => {
    // Capture the payment and redirect to success page
    return actions.order.capture().then(function (details) {
      // TODO: Implement success page
      console.log("Payment successful", details);
    });
  };


  const handleDonate = () => {

    setDonate(!donate)
  }

  const handleCommentForm = () => {

    setWriteComment(!WriteComment);

  }



  const handleLike = (id) => {
    dispatch(likeDonation(id));
  };


  const handleUnLike = (id) => {
    dispatch(unlikeDonation(id));
  };

  const handleDelete = async (e) => {
    e.preventDefault();

    dispatch(deleteDonation(donation?._id));
  };

  useEffect(() => {

    if (deleteSucces === true) {

      dispatch({
        type: DONATION_DELETE_RESET,
      });

      navigate('/donate')
    }
  }, [dispatch, deleteSucces])


  const handleOptions = (e) => {
    setOptions(!option);
  }

  const handleShareOptions = (e) => {
    setShareOptions(!shareOptions);
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(url)
      .then(() => {
        alert('Text copied to clipboard')
      })
      .catch(err => {
        alert(err)
      })

  }









  return (

    <PayPalScriptProvider>
      <div className="subpixel-antialiased min-h-screen inset-0 flex flex-col justify-center items-center content-center font-sans    mx-auto  lg:align-center lg:text-center rounded-b-2xl p-3 mt-[10px] lg:mt-[50px] ">
        {/* central div */}

        {loading ? (
          <div className="flex flex-col justify-center items-center content-center text-center  gap-3 md:gap-5 p-2 md:p-3  w-full md:w-[80%] lg:w-[80%]">
             <Loading />
          </div>
        ) : (
          <div className="flex flex-col justify-center items-start content-center gap-3 md:gap-5 p-2 md:p-3  w-full md:w-[100%] lg:w-[80%]">
            {/* back button */}
            <motion.div
              onClick={() => navigate(-1)}
              className="mb-[20px] flex flex-row items-center content-center justify-center text-gray-500 hover:text-black min-w-[100px] rounded-full p-1 md:p-2 gap-1 md:gap-2 cursor-pointer text-[14px]"
            >
              <BsChevronLeft />
              <h1>Back</h1>
            </motion.div>

            <div className="flex flex-col gap-2 lg:grid lg:grid-cols-2 md:gap-3 w-full">
              {/* image and title */}
              <div className="flex flex-col gap-2">
                {/* title */}
                <div className="flex flex-col gap-2 w-full">


                  <img
                    src={newImageUrl}
                    className="w-full h-[250px] lg:w-[50vw] md:h-[400px] rounded-xl md:[600px] object-cover object-center drop-shadow-xl"
                    alt=""
                  />



                </div>

                <div className="flex flex-row items-end content-start justify-end gap-3 p-1 md:p-2 mt-[10px] border-b border-opacity-65 relative">
                  <div className="flex flex-row items-center content-center justify-start gap-1">

                    {donation?.has_liked ? (<AiFillHeart onClick={() => handleUnLike(donationId)} className="text-red-500 cursor-pointer text-[1.4rem]" />

                    ) : (<AiOutlineHeart onClick={() => handleLike(donationId)} className="cursor-pointer text-[1.4rem]" />)}




                    <p className="text-[0.8rem]">{formatNumber(donation?.LikesCount)}</p>
                  </div>

                  <div className="flex flex-row items-center content-center justify-start gap-1">
                    <AiOutlineComment className="text-blue-700 cursor-pointer text-[1.4rem]" />
                    <p className="text-[0.8rem]">{formatNumber(donation?.CommentsCount)}</p>
                  </div>

                  <div
                    onClick={(e) => handleShareOptions(e)}
                    className="flex flex-row items-center content-center justify-start gap-1">
                    <AiOutlineShareAlt className="text-green-800 cursor-pointer text-[1.4rem]" />
                  </div>

                  {shareOptions && (
                    <motion.div
                      initial={{ bottom: 15, opacity: 0 }}
                      animate={{ bottom: 0, opacity: 1 }}
                      exit={{ bottom: 20, opacity: 0 }}
                      className="absolute bottom-[2rem] bg-white w-auto border p-1 md:p-2 rounded-lg shadow-lg">
                      <div className="flex flex-row items-center content-center justify-between w-full text-[12px] md:text-[14px] p-1 md:p-2">
                        <h1 className="font-[500] ">Share</h1>

                        <BsXCircleFill onClick={(e) => handleShareOptions(e)} className="text-[1rem] cursor-pointer" />
                      </div>
                      <ul className="flex flex-row items-center content-center justify-evenly  gap-1 md:gap-2 p-1 md:p-2">
                        <li className="flex flex-col items-center justify-center content-center gap-1 md:gap-2 p-1">
                          <div
                          onClick={() => handleCopy()}
                           className="bg-gray-100 flex flex-row items-center content-center justify-center text-center h-[40px] w-[40px] rounded-full border border-gray-200 hover:bg-gray-400 hover:border-gray-400 hover:text-white cursor-pointer">
                            <BiCopy className=" text-[1rem]" />

                          </div>

                          <h1 className="text-[11px] md:text-[12px]">Copy</h1>
                        </li>
                        <li className="flex flex-col items-center justify-center content-center gap-1 md:gap-2 p-1">
                          <a
                            href={`https://wa.me/?text=${encodeURIComponent('Check this out:' + url)}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="bg-gray-100 flex flex-row items-center content-center justify-center text-center h-[40px] w-[40px] rounded-full border border-gray-200 hover:text-white hover:bg-green-500 hover:border-green-500 cursor-pointer">
                            <BiLogoWhatsapp className=" text-[1rem]" />


                          </a>

                          <h1 className="text-[11px] md:text-[12px]">Whatsapp</h1>
                        </li>
                        <li className="flex flex-col items-center justify-center content-center gap-1 md:gap-2 p-1">
                          <a
                            href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(donation?.title + '' + url)}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="bg-gray-100 flex flex-row items-center content-center justify-center text-center h-[40px] w-[40px] rounded-full border border-gray-200 hover:text-white hover:bg-blue-500 hover:border-blue-500 cursor-pointer">
                            <BsTwitterX className=" text-[1rem]" />


                          </a>

                          <h1 className="text-[11px] md:text-[12px]">Twitter</h1>
                        </li>
                        <li className="flex flex-col items-center justify-center content-center gap-1 md:gap-2 p-1">
                          <a
                            href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="bg-gray-100 flex flex-row items-center content-center justify-center text-center h-[40px] w-[40px] rounded-full border border-gray-200 hover:text-white hover:bg-blue-500 hover:border-blue-500 cursor-pointer">
                            <BiLogoFacebook className=" text-[1rem]" />




                          </a>

                          <h1 className="text-[11px] md:text-[12px]">Facebook</h1>
                        </li>
                      </ul>


                    </motion.div>)}
                </div>
              </div>

              {/* description */}
              <div className=" flex flex-col gap-2 sm:gap-3 p-1 md:p-2">
                <div className=" relative flex flex-row justify-between items-center content-center w-full">
                  <div className="flex flex-row items-center content-start justify-start text-[12px] text-gray-500 gap-1 relative">

                    <AiOutlineClockCircle />
                    <p className="text-gray-700">
                      {format(donation?.timestamp)}

                    </p>


                  </div>
                  <div>
                    {userInfo?.id === donation?.user ? (

                      <div

                        onClick={handleOptions}
                        className="border border-gray-400 rounded-full p-[2px] text-center text-black cursor-pointer hover:bg-gray-600 hover:text-white hover:border-gray-600">
                        <BiDotsHorizontalRounded

                          className="text-[0.8rem] md:text-[14px]" />

                      </div>

                    ) : ""}


                    {option ? (<div className="absolute bg-white border top-6 right-1 rounded-md p-1 md:p-2 drop-shadow-xl text-[12px] md:text-[14px] min-w-[150px] md:min-w-[200px] z-30">
                      <ul className="flex flex-col justify-evenly items-end  gap-2 md:gap-3 w-full">
                        <li
                          onClick={(e) => handleDelete(e)}
                          className="flex flex-row justify-between content-center items-center gap-2 p-1 hover:bg-gray-600 hover:text-white group cursor-pointer rounded-sm w-full">
                          <h1>Delete This Post</h1>
                          <BiSolidTrashAlt className="group-hover:text-red-600" />
                        </li>

                      </ul>


                    </div>) : ""}

                  </div>


                </div>


                {/* profile and name */}
                <div className="flex flex-row items-start content-start justify-start gap-2 w-full p-1 md:p-2">

                  {/* image */}
                  <img src={donation?.User?.profile_photo  ? `${REACT_API_URL}${donation?.User?.profile_photo}` : "noavatar.jpg"} className="w-[50px] h-[50px] rounded-full object-cover object-center border border-gray-200" alt="profile-photo" />

                  {/* name,title and time */}
                  <div className="w-full flex flex-col gap-1">

                    <div className="flex flex-row items-start content-start justify-between w-full">

                      <div className="flex flex-col items-start content-start justify-start  h-auto">
                        <h1 className="flex flex-col gap-2 text-[0.8rem] text-gray-800 font-[400]">
                          {"@"}{donation?.User?.sticker_name}
                        </h1>
                        <p className="font-[500] text-[0.9rem]">
                          {donation?.User?.username}
                        </p>

                      </div>



                    </div>




                  </div>





                </div>
                <div className="flex flex-col gap-2 text-lg font-[500] text-start">



                  <h1 className="text-[1rem]">{donation?.title}</h1>

                </div>

                <div className="flex flex-row justify-between p-1 md:p-2  ">
                  <div className="flex flex-col gap-1 md:gap-2 justify-start  text-md items-start content-start">
                    <div className="flex flex-row items-center content-start justify-start gap-1 text-[12px]  text-gray-500">
                      <BsBullseye className="" />
                      <h1>Target</h1>
                    </div>
                    <p className="text-[12px] md:text-[14px] ">
                      {formatNumber(donation?.target)}{""}<span>{donation?.currency}</span>
                    </p>

                  </div>
                  <div className="flex flex-col gap-1 md:gap-2 justify-end  text-md items-end content-start">
                    <div className="flex flex-row items-center content-start justify-end gap-1 text-[12px]  text-gray-500">
                      <BsPiggyBank className="" />
                      <h1>Collected</h1>
                    </div>
                    <p className="text-[12px] md:text-[14px] ">

                      {formatNumber(donation?.collected)}{" "}<span>{donation?.currency}</span>
                    </p>

                  </div>

                </div>


                <div className="text-[12px] md:text-[13px] text-start">
                  <p className="">{donation?.description}</p>
                </div>







                <div className="p-1 border-t w-full">




                  {/* button to handle donation */}
                  <div className="flex flex-row items-center content-center justify-end gap-1 text-[12px] cursor-pointer     w-full mb-2">
                    <div onClick={handleDonate} className="flex flex-row items-center content-center justify-end gap-1 text-[12px] cursor-pointer hover:bg-gray-200 rounded-md    p-1 md:p-2  ">

                      {donate === true ? (<BsChevronUp className="font-[600]" />) : (<BsChevronDown className="font-[600]" />)}
                      {donate === true ? (<h1 className="font-[500]">Hide Options</h1>) : (<h1 className="font-[500] flex flex-row gap-1 md:gap-2  items-center justify-center content-center">Make a Donation <BiInfoCircle className="text-yellow-600"/></h1>)}



                    </div>

                  </div>



                  {/* transfer methods */}
                  {donate === true ? (<div className="flex flex-col justify-end items-end content-end gap-1 w-full p-1 md:p-2">

                    <h1 className=" flex flex-row gap-1 md:gap-2  items-center justify-center content-center text-[12px] font-[500] ">Select Transfer Method{" "}<span><BiInfoCircle className="text-yellow-600"/></span></h1>

                    <div className="flex flex-col md:flex-row gap-2 md:gap-3 justify-start md:justify-between content-start w-full">

                      {/* <PayPalButtons
                      className="rounded-xl"
                      createOrder={(data, actions) => {
                        // Create a PayPal order with the specified amount
                        return actions.order.create({
                          purchase_units: [
                            {
                              amount: {
                                value: amount,
                              },
                            },
                          ],
                        });
                      }}
                      onApprove={onApprove}
                      onError={onError}
                      options={paypalOptions}
                      style={buttonStyles}
                    /> */}

                      {/* <button className="w-full h-[40px] md:h-[50px] bg-gray-50 border border-gray-100 rounded-md md:rounded-lg text-[12px] md:text-14px flex flex-row items-center content-center justify-center gap-1 md:gap-2 hover:bg-gray-100   hover:border-gray-300">

                        <BsPaypal className="text-blue-800" />

                        Paypal

                      </button> */}
                      {/* <button className="w-full h-[40px] md:h-[50px] bg-gray-50 border border-gray-100 rounded-md md:rounded-lg text-[12px] md:text-14px flex flex-row items-center content-center justify-center gap-1 md:gap-2 hover:bg-gray-100   hover:border-gray-300">

                        <AiOutlineMobile className="text-red-800" />

                        M-Pesa

                      </button> */}

                      <div className="flex flex-col items-start justify-start content-center gap-1 md:gap-2 border rounded-lg  p-1 md:p-2 bg-yellow-100 w-full">
                        <BiInfoCircle className="text-yellow-600" />
                        <h1 className="text-left p-1 text-[12px] md:text-[12px]">We are still working hard to provide you with the safest way to Donate</h1>
                      </div>


                    </div>









                  </div>

                  ) : ("")}


                </div>


                {/* comment form */}
                <div className="p-1 border-t w-full">
                  {/* Write a comment actions */}
                  <div className="flex flex-row items-center content-center justify-end gap-1 text-[12px] cursor-pointer     w-full mb-2">
                    <div onClick={handleCommentForm} className="flex flex-row items-center content-center justify-end gap-1 text-[12px] cursor-pointer hover:bg-gray-200 rounded-md    p-1 md:p-2  ">

                      {WriteComment === true ? (<BsChevronUp className="font-[600]" />) : (<AiOutlineComment className="font-[600]" />)}
                      {WriteComment === true ? (<h1 className="font-[500]">Hide </h1>) : (<h1 className="font-[500]">Write a Comment</h1>)}



                    </div>


                  </div>


                  {WriteComment ? (

                    <DonationCommentForm donationId={donationId} />
                  ) : ("")}






                </div>



                {/* comment list */}




                <DonationComments />












              </div>
            </div>
          </div>
        )}
      </div>

    </PayPalScriptProvider>

  );
}

export default DonationDetails;
