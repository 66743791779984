import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { listMyPosts } from "../../features/actions/postActions";
import Paginate from "../dashboard/navigation/Paginate";
import { motion } from "framer-motion";
import PostLoading from "../loading/PostLoading";
import PostItem from "./PostItem";
import { BsChevronLeft } from "react-icons/bs";

function AllProfilePosts() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [choices, SetChoices] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);

  const { userInfo } = userLogin;

  const postMyList = useSelector((state) => state.postMyList);

  const { posts, page, pages, loading, error } = postMyList;



  const pollUpdate = useSelector((state) => state.pollUpdate);

  const { loading: pollLoading, success: pollSuccess, error: pollError } = pollUpdate;

  const postLike = useSelector((state) => state.postLike);
  const { loading: likeLoading, success: likeSuccess, error: likeError } = postLike;


  const postUnLike = useSelector((state) => state.postUnLike);
  const { loading: unlikeLoading, success: unlikeSuccess, error: unlikeError } = postUnLike;


  const { search } = useLocation();

  const route = location.route;

  /* FIRING OFF THE ACTION CREATORS USING DISPATCH */

  let keyword =
    location.search; /* IF USER SEARCHES FOR ANYTHING THEN THIS KEYWORD CHANGES AND USE EFFECT GETS TRIGGERED */

  useEffect(() => {
    let _;

    if (!userInfo) {
      navigate("/login");
    } else {
      dispatch(listMyPosts(keyword));
    }
  }, [dispatch, keyword]);

  useEffect(() => {

    if (pollSuccess || likeSuccess || unlikeSuccess) {
      dispatch(listMyPosts(keyword, userInfo._id));
    }


  }, [dispatch, pollSuccess, likeSuccess, unlikeSuccess, userInfo, keyword])
  return (
    <motion.div className=" p-1 md:p-2   flex flex-col gap-3 justify-center content-center items-center text-black  mx-auto bg-white mt-[100px]">

      <motion.div
        onClick={() => navigate(-1)}
        className=" flex flex-row items-center content-center justify-start text-gray-500 hover:text-black w-full   gap-1 md:gap-2 cursor-pointer mb-[20px] text-[14px]"
      >
        <BsChevronLeft />
        <h1>Back</h1>
      </motion.div>


      {/* center items */}
      {loading ? <PostLoading /> : ""}

      {(posts === 0) & !loading ? (
        <div className="flex flex-col  justify-center items-center content-center w-full">
          <h1 className="text-gray-500 text-md md:text-xl">No Posts Found</h1>
        </div>
      ) : (
        <div className="flex flex-col justify-center content-center items-center gap-2 md:gap-3   w-full md:min-w-[400px]">
          {posts?.map((post) => (
            <PostItem key={post._id} post={post} />
          ))}
        </div>
      )}

      {/* pagination */}
      <div className="p-2 md:p-3 mt-[20px]">
        <Paginate page={page} pages={pages} keyword={keyword} route="/account/posts" />
      </div>


    </motion.div>
  );
}

export default AllProfilePosts;
