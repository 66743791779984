

// post queue reducers

import { QUEUE_ADD_DONATION_ITEM, QUEUE_ADD_EVENT_ITEM, QUEUE_ADD_POST_ITEM, QUEUE_CLEAR_DONATION_ITEM, QUEUE_CLEAR_EVENT_ITEM, QUEUE_CLEAR_POST_ITEM, QUEUE_REMOVE_DONATION_ITEM, QUEUE_REMOVE_EVENT_ITEM, QUEUE_REMOVE_POST_ITEM } from "../constants/uploadQueueConstants"



const initialState = {
    "post":{
        user:"",
          title: "",
          content:"",
          link:"",
          is_poll: false,
          cover_image: "",
          has_photo: false,
          choices: [ ...""]
    },
    "event":{
        user:"",
          title: "",
          venue:"",
          location:"",
          start_date: "",
          end_date: "",
          event_cover: "",
          description: "",
    },
}


export const postQueueReducer = (state=initialState.post, action) => {
    switch (action.type) {
        case QUEUE_ADD_POST_ITEM:
            return {
                ...state,
                post: action.payload,
            }
        case QUEUE_REMOVE_POST_ITEM:
            return {
                ...state,
                post: action.payload,
            }
            // The filter() method creates a new array with all elements that pass the test implemented by the provided function.
        case QUEUE_CLEAR_POST_ITEM:
            return {
                ...state, 
                post: null
            }
  
        default:
            return state
    }

}



export const eventQueueReducer = (state=initialState.post, action) => {
    switch (action.type) {
        case QUEUE_ADD_EVENT_ITEM:
            return {
                ...state,
                event: action.payload,
            }
        case QUEUE_REMOVE_EVENT_ITEM:
            return {
                ...state,
                event: action.payload,
            }
        case QUEUE_CLEAR_EVENT_ITEM:
            return {
                ...state, 
                event: null
            }
  
        default:
            return state
    }

}

export const donationQueueReducer = (state={}, action) => {
    switch (action.type) {
        case QUEUE_ADD_DONATION_ITEM:
            
            return {
                ...state,
                donation: action.payload,
            }
        case QUEUE_REMOVE_DONATION_ITEM:
            return {
                ...state,
                donation: action.payload,
            }
            // The filter() method creates a new array with all elements that pass the test implemented by the provided function.
        case QUEUE_CLEAR_DONATION_ITEM:
            return {
                ...state, 
                donation: null
            }
  
        default:
            return state
    }

}





