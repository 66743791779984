import { REACT_API_URL, REACT_APP_URL } from "../../utilities/utils";

/* AXIOS */
import axios from "axios";

/* ACTION TYPES */
import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_RESET,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_UPDATE_PROFILE_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  USER_LIST_RESET,
  USER_DELETE_REQUEST,
  USER_DELETE_FAIL,
  USER_DELETE_SUCCESS,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  EMAIL_CHECK_REQUEST,
  EMAIL_CHECK_SUCCESS,
  EMAIL_CHECK_FAIL,
  PASSWORD_RESET_REQUEST,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_FAIL,
  USER_REFRESH_REQUEST,
  USER_REFRESH_SUCCESS,
  USER_REFRESH_FAIL,
} from "../constants/userConstants";

/* ACTION CREATOR USED IN USER LOGIN IN LoginScreen COMPONENT & HEADER */
export const login = (email, password) => async (dispatch) => {



  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    /* MAKE POST REQUEST TO GET BACK THE USER TOKEN */
    const config = {
      headers: {
        "Content-Type": "application/json",

      },
    };

    const { data } = await axios.post(
      `${REACT_API_URL}/users/login/`,
      { email: email, password: password },
      config
    );

    /* IF POST REQUEST SUCCESSFULL WE DISPATCH & SEND THE PAYLOAD TO OUR REDUCER */
    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    /* SETTING VALUE OF USER INFO IN LOCAL STORAGE SO WE KNOW USER IS LOGGED IN */
    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};




/* ACTION CREATOR USED IN USER LOGOUT IN LoginScreen COMPONENT & HEADER */
export const logout = () => (dispatch) => {
  /* REMOVE USER INFO FORM LOCAL STORAGE */
  localStorage.removeItem("userInfo");

  /* DISPATCH TO REMOVE USER INFO FORM STORE */
  dispatch({
    type: USER_LOGOUT,
  });
  dispatch({
    type: USER_DETAILS_RESET,
  });

  /* DISPATCH TO RESET THE DETAILS OF USERS LIST */
  dispatch({
    type: USER_LIST_RESET,
  });
};

/* ACTION CREATOR USED IN USER REGISTRATION IN RegisterScreen COMPONENT & HEADER */
export const register = (first_name, last_name, country, email, mobile, username, stickername, password) => async (dispatch) => {
  const csrftoken = getCookie('token');
  try {
    dispatch({
      type: USER_REGISTER_REQUEST,
    });

    /* MAKE POST REQUEST TO GET BACK THE USER TOKEN */

    const config = {
      headers: {
        "Content-Type": "application/json",
        'X-CSRFToken': csrftoken,
      },
    };

    const { data } = await axios.post(
      `${REACT_APP_URL}/users/register/`,
      {
        first_name: first_name,
        last_name: last_name,
        username: username,
        country: country,
        sticker_name: stickername,
        mobile: mobile,
        email: email,
        password: password
      },
      config
    );


    dispatch({
      type: USER_REGISTER_SUCCESS,
      payload: data,
    });

  } catch (error) {
    dispatch({
      type: USER_REGISTER_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};



export const getUserDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`${REACT_API_URL}/users/${id}/`, config);

    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    });
    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};


export const recallUserDetails = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_REFRESH_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`${REACT_API_URL}/users/${userInfo?.id}/`, config);

    dispatch({
      type: USER_REFRESH_SUCCESS,
      payload: data,
    });

    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_REFRESH_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

/* ACTION CREATOR USED IN UPDATING USER DETAILS IN ProfileScreen COMPONENT  */
export const updateUserProfile = (user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_PROFILE_REQUEST,
    });

    // PULLING OUT THE CURRENT USER WE ARE LOGGED IN AS
    const {
      userLogin: { userInfo },
    } = getState();

    /* MAKE PUT REQUEST TO SET THE THE USER DATA */
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}` /* PASSING IN USER TOKEN AND IF THE USER IN AUTHORISED HE'LL HAVE FULL ACCESS TO HIS PROFILE INFORMATION */,
      },
    };

    // USING ${id} BECAUSE WHILE ACCESSING NORMAL USER WE'LL PASS STRING 'profile' BUT WHILE ACCESSING ADMIN PANEL WE'LL PASS THE 'id' SO LINK HAS TO BE DYNAMIC
    const { data } = await axios.put(`${REACT_API_URL}/users/profile/update/`, user, config);

    /* IF PUT REQUEST SUCCESSFULL WE DISPATCH & SEND THE PAYLOAD TO OUR REDUCER */
    dispatch({
      type: USER_UPDATE_PROFILE_SUCCESS,
      payload: data,
    });


    /* SETTING UPDATED VALUE OF USER INFO IN LOCAL STORAGE */
    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_UPDATE_PROFILE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

/* ACTION CREATOR USED TO GET THE LIST OF USERS IN UserList SCREEN  */
export const listUsers = (keyword = "") => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_LIST_REQUEST,
    });

    // PULLING OUT THE CURRENT USER WE ARE LOGGED IN AS
    const {
      userLogin: { userInfo },
    } = getState();

    /* MAKE GET REQUEST TO SET THE THE USERS LIST */
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}` /* PASSING IN USER TOKEN AND IF THE USER IS ADMIN WE'LL BE ABLE TO SEE LIST OF USERS */,
      },
    };

    const { data } = await axios.get(`${REACT_API_URL}/users/${keyword}`, config);

    dispatch({
      type: USER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

/* ACTION CREATOR USED TO DELETE A USER IN UserList SCREEN */
export const deleteUser = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DELETE_REQUEST,
    });

    // PULLING OUT THE CURRENT USER WE ARE LOGGED IN AS
    const {
      userLogin: { userInfo },
    } = getState();

    /* MAKE DELETE REQUEST TO DELETE THE USER */
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}` /* PASSING IN USER TOKEN AND IF THE USER IS ADMIN WE'LL BE ABLE TO DELETE THE USER */,
      },
    };

    const { data } = await axios.delete(`${REACT_API_URL}/users/delete/${id}`, config);

    dispatch({
      type: USER_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

/* ACTION CREATOR USED TO EDIT A USER IN UserUpdate SCREEN */
export const updateUser = (user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_REQUEST,
    });

    // PULLING OUT THE CURRENT USER WE ARE LOGGED IN AS
    const {
      userLogin: { userInfo },
    } = getState();

    /* MAKE PUT REQUEST TO EDIT THE USER */
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}` /* PASSING IN USER TOKEN AND IF THE USER IS ADMIN WE'LL BE ABLE TO EDIT THE USER */,
      },
    };

    const { data } = await axios.put(
      `${REACT_API_URL}/users/update/${user._id}/`,
      user,
      config
    );

    dispatch({
      type: USER_UPDATE_SUCCESS,
    });

    /* AFTER UPDATING WE WANT TO RELOAD THE USER DATA */
    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_UPDATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};


/* ACTION CENTER FOR CHECKING USER EMAIL BEFORE SENDING RESET LINK */
export const userEmailCheck = (email) => async (dispatch) => {

  const csrftoken = getCookie('token');
  try {
    dispatch({
      type: EMAIL_CHECK_REQUEST,
    });

    /* MAKE POST REQUEST TO GET BACK THE USER TOKEN */
    const config = {
      headers: {
        "Content-Type": "application/json",
        'X-CSRFToken': csrftoken,
      },
    };

    const { data } = await axios.post(
      `${REACT_APP_URL}/users/forget-password/`,
      { email: email },
      config
    );

    /* IF POST REQUEST SUCCESSFULL WE DISPATCH & SEND THE PAYLOAD TO OUR REDUCER */
    dispatch({
      type: EMAIL_CHECK_SUCCESS,
      payload: data,
    });

  } catch (error) {
    dispatch({
      type: EMAIL_CHECK_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};



/* ACTION CREATOR USED IN USER REGISTRATION IN RegisterScreen COMPONENT & HEADER */
export const userResetPassword = (password, token, encoded_pk) => async (dispatch) => {

  const csrftoken = getCookie('token');

  try {
    dispatch({
      type: PASSWORD_RESET_REQUEST,
    });

    /* MAKE POST REQUEST TO GET BACK THE USER TOKEN */
    const config = {
      headers: {
        "Content-Type": "application/json",
        'X-CSRFToken': csrftoken,
      },
    };

    const { data } = await axios.patch(
      `${REACT_APP_URL}/users/reset-password/${encoded_pk}/${token}/`,
      {
        password: password

      },
      config
    );

    /* IF POST REQUEST SUCCESSFULL WE DISPATCH & SEND THE PAYLOAD TO OUR REDUCER */
    dispatch({
      type: PASSWORD_RESET_SUCCESS,
      payload: data,
    });

  } catch (error) {
    dispatch({
      type: PASSWORD_RESET_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};



function getCookie(name) {
  let cookieValue = null;

  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();

      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));

        break;
      }
    }
  };

}