import React, { useEffect, useState } from 'react'
import { getUserDetails, logout, updateUserProfile } from '../../features/actions/userActions';
import { USER_UPDATE_PROFILE_RESET } from '../../features/constants/userConstants';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { REACT_APP_URL } from '../../utilities/utils';
import axios from 'axios';
import { BsArrowRight, BsCheckCircleFill, BsChevronLeft, BsChevronRight, BsCloudUpload, BsEraser, BsFillPeopleFill, BsGlobeEuropeAfrica, BsImage, BsInfoCircleFill, BsParagraph, BsPencilFill, BsPersonFill, BsPhoneFill, BsSave2, BsTagFill, BsTextarea } from 'react-icons/bs';
import Message from '../../components/message/Message';
import UploadingLoader from '../../components/loading/UploadingLoader';
import { AiFillLock, AiFillMail } from 'react-icons/ai';
import { CancelAuthActions, usernameAvailability } from '../../features/actions/AuthActions';
import { BiLogOutCircle, BiSolidPen } from 'react-icons/bi';
import Loading from '../../components/loading/Loading';


function ProfileEditPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [uploading, setUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [image, setImage] = useState(null);
  const [displayImage, setDisplayImage] = useState(null);


  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [username, setUsername] = useState("");
  const [stickername, setStickername] = useState("");
  const [country, setCountry] = useState("");
  const [mobile, setMobile] = useState("");
  const [bio, setBio] = useState("");
  const [company, setCompany] = useState("");
  // makign sure user is logged in
  const userLogin = useSelector((state) => state.userLogin);

  const { userInfo } = userLogin;

  // get user details
  const userDetails = useSelector((state) => state.userDetails);

  const { user, loading, error } = userDetails;

  // updating user details
  const userUpdateProfile = useSelector((state) => state.userUpdateProfile);

  const { success, loading: updateLoading, } = userUpdateProfile;

  // redirect
  const redirect = location.state?.from || "/";


  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else {
      if (!user || !user.email || success || userInfo._id !== user._id) {

        dispatch({ type: USER_UPDATE_PROFILE_RESET });

        dispatch(getUserDetails("profile"));
        dispatch(CancelAuthActions());
        navigate("/account");
      } else {
        setFirstname(user.first_name);
        setLastname(user.last_name);
        setUsername(user.username)
        setStickername(user.sticker_name)
        setBio(user.bio);
        setCompany(user.company);
        setMobile(user.mobile);
        setCountry(user.country);
      }
    }
  }, [dispatch, navigate, user, userInfo, redirect, success]);



  const SubmitHandler = (e) => {
    e.preventDefault();



    dispatch(
      updateUserProfile({
        id: user._id,
        first_name: firstname,
        last_name: lastname,
        username: username,
        sticker_name: stickername,
        mobile: mobile,
        country: country,
        bio: bio,
        company: company,
      })
    );









  };


  const profileImageUpload = async (e) => {
    // const file = e.target.files[0];
    const formData = new FormData();

    formData.append("image", image);
    formData.append("user_id", userInfo.id);

    setUploading(true);

    try {
      const config = {
        header: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.post(
        `${REACT_APP_URL}/users/profile/upload/`,
        formData,
        config
      );


      setUploading(false);
      dispatch(getUserDetails("profile"));
      setDisplayImage(null)
      setImage(null)
      setUploadSuccess(true);
    } catch (error) {
      setUploading(false);
    }
  };

  const handleClearImage = () => {
    setDisplayImage(null);
    setImage(null);
  }

  const handleCheckUsername = (e) => {
    e.preventDefault();
    dispatch(usernameAvailability(username));
  }


  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logout());
  }




  return (
    <div className="subpixel-antialiased min-h-screen inset-0 flex flex-col justify-center items-center content-center font-sans    mx-auto  lg:align-center lg:text-centen rounded-b-2xl p-3 mt-[10px] lg:mt-[50px] w-full">
      {/* central div */}


      <div className="flex flex-col justify-start items-start content-center gap-3 md:gap-5 p-2 md:p-3  h-full w-[95%] md:w-[80%] lg:w-[80%]">
        {/* back button */}
        <div
          onClick={() => navigate(-1)}
          className=" flex flex-row items-center content-center justify-center text-gray-900 hover:text-black w-auto rounded-full p-1 md:p-2 gap-1 md:gap-2 cursor-pointer text-[12px] md:text-[15px] mb-[10px]"
        >
          <BsChevronLeft />
          <h1 className='font-[500]'>Back</h1>
        </div>




        {/* heading */}

        <div className='flex flex-row items-center justify-start content-center gap-1 md:gap-2 text-[14px] md:text-[16px] font-[500]  p-1 md:p-2'>
          <BsPencilFill className='text-green-600' />
          <h1>Edit Your Profile</h1>

        </div>


        {/* main edit page */}
        <div className='w-full flex flex-col  md:flex-row justify-start md:justify-evenly items-start content-center
         '>

          {/* image section */}
          <section className='w-full text-[12px] md:text-[14px]'>
            {/* image */}
            <div

              className="flex flex-col justify-start md:justify-start gap-2 w-full  rounded-xl p-1 md:p-2  mb-[20px]"
            >
              <div className=" w-full flex flex-col justify-start content-center items-center gap-2 p-1 md:p-2">
                <div className='flex flex-row items-center content-center justify-start gap-1 md:gap-2 w-full'>
                  <h1 className="font-[500] text-gray-900">
                    Profile Photo</h1>
                  <span className='text-gray-800 text-[12px] md:text-[14px]'><BsImage /></span>
                </div>

                {displayImage ?
                  (<>
                    <img
                      src={displayImage}
                      className="w-full h-[250px] lg:w-[50vw] md:h-[300px] rounded-xl  object-cover object-center drop-shadow-xl"
                      alt=""
                    /></>

                  ) : (
                    <img
                      src={`${REACT_APP_URL}${user?.profile_photo}` || 'noavatar.jpg'}
                      className="w-full h-[250px] lg:w-[50vw] md:h-[300px] rounded-xl  object-cover object-center drop-shadow-xl"
                      alt=""
                    />
                  )}

              </div>
              {displayImage === null & loading === false ? (""
              ) : (<div className='flex flex-row items-center content-center justify-between w-full p-1'>


                <div onClick={handleClearImage}
                  className='flex flex-row gap-1 md:gap-2 p-1 text-white bg-gray-600 justify-center items-center content-center rounded-full  text-[11px] md:text-[14px] w-[100px] h-[35px] self-end cursor-pointer hover:bg-gray-400 hover:font-[500] shadow-lg'>
                  <h1>Clear </h1>
                  <BsEraser />
                </div>
                <div
                  onClick={profileImageUpload}
                  className='flex flex-row gap-1 md:gap-2 p-1 text-white bg-green-600 justify-center items-center content-center rounded-full  text-[11px] md:text-[14px] w-[100px] h-[35px] self-end cursor-pointer hover:bg-green-500 hover:font-[500] shadow-lg'>
                  <h1>Upload</h1>
                  <BsCloudUpload />
                </div>

              </div>
              )}








              {uploading ? (
                <UploadingLoader />
              ) : (
                <div className="p-1 md:p-2 w-full flex flex-col gap-2">
                  <h1 className="text-[12px] md:text-[14px]">
                    Choose a new profile photo
                  </h1>
                  <input
                    class="block w-full text-[sm] text-gray-900 bg-gray-50 rounded-md border border-gray-300 cursor-pointer focus:outline-none "
                    aria-describedby="file_input_help"
                    id="file_input"
                    type="file"
                    onChange={(e) => {
                      setImage(e.target.files[0]);
                      const file = e.target.files[0];
                      const reader = new FileReader();
                      reader.readAsDataURL(file);
                      reader.onload = (event) => {
                        const result = event.target.result;
                        setDisplayImage(result);
                      };
                    }}
                  />

                  {uploadSuccess === true ? (
                    <Message type="success">Profile photo was uploaded</Message>
                  ) : ("")}
                </div>
              )}
            </div>

          </section>

          {/* input section */}
          <section className='w-full'>
            <form
              onSubmit={SubmitHandler}
              className='w-full flex flex-col  justify-start content-start  items-start gap-1 md:gap-2 p-1 md:p-2'>


              {/* username */}
              <div className="flex flex-col  justify-start content-start  items-start gap-1 md:gap-2 p-1 md:p-2 text-[12px] md:text-[13px]  w-full ">

                <div className='flex flex-row justify-between w-full'>

                  {/* username */}
                  <div className='flex flex-row items-center content-center justify-start gap-1 md:gap-2 h-auto'>
                    <h1 className="font-[500] text-gray-900">
                      Username</h1>
                    <span className='text-gray-800 text-[12px] md:text-[14px]'>
                      <BsPersonFill />
                    </span>
                  </div>

                </div>


                <div className='p-1 md:p-2 flex flex-row items-center content-center justify-between  border-b bg-none outline-none   w-full md:min-w-full lg:min-w-full h-[50px] md:h-[60px] text-[12px] md:text-[14px]  font-[600'>
                  <h1>
                    {username}
                  </h1>

                  <div
                  onClick={() => navigate("/account/username/change")}
                   className='flex flex-row items-center content-center justify-start gap-1 md:gap-2  text-green-600 hover:text-green-500 text-[11px] md:text-[12px]  cursor-pointer'>
                    <h1>Change Username</h1>
                    <BiSolidPen />
                  
                  </div>
                  </div>


              </div>


              {/* sticker name */}
              <div className="flex flex-col  justify-start content-start  items-start gap-1 md:gap-2 p-1 md:p-2 text-[12px] md:text-[13px]  w-full ">
                <div className='flex flex-row items-center content-center justify-start gap-1 md:gap-2'>
                  <h1 className="font-[500] text-gray-900">
                    Sticker Name</h1>
                  <span className='text-gray-800 text-[12px] md:text-[14px]'>
                    <BsTagFill />
                  </span>
                </div>

                <input
                  className="p-1 md:p-2  border-b bg-none outline-none  placeholder:text-gray-400 placeholder:text-[11px] placeholder:pl-4  w-full md:min-w-full lg:min-w-full h-[50px] md:h-[60px] text-[13px]  font-[600] "
                  type="text"
                  value={stickername}
                  onChange={(e) => setStickername(e.target.value)}
                  placeholder="sticker name"
                />
              </div>


              {/* full name */}

              <div className='flex flex-row  justify-start content-start  items-start gap-1 md:gap-2 p-1 md:p-2 text-[12px] md:text-[13px]  w-full'>

                {/* first_name  */}
                <div className="flex flex-col  justify-start content-start  items-start gap-1 md:gap-2 p-1 md:p-2 text-[12px] md:text-[13px]  w-full ">
                  <div className='flex flex-row items-center content-center justify-start gap-1 md:gap-2'>
                    <h1 className="font-[500] text-gray-900">
                      Firstname</h1>
                    <span className='text-gray-800 text-[12px] md:text-[14px]'>
                      <BsTextarea />
                    </span>
                  </div>

                  <input
                    className="p-1 md:p-2  border-b bg-none outline-none  placeholder:text-gray-400 placeholder:text-[11px] placeholder:pl-4  w-full md:min-w-full lg:min-w-full h-[50px] md:h-[60px] text-[13px]  font-[600] "
                    type="text"
                    value={firstname}
                    onChange={(e) => setFirstname(e.target.value)}
                    placeholder="Title"
                  />
                </div>

                {/* last name */}
                <div className="flex flex-col  justify-start content-start  items-start gap-1 md:gap-2 p-1 md:p-2 text-[12px] md:text-[13px]  w-full ">
                  <div className='flex flex-row items-center content-center justify-start gap-1 md:gap-2'>
                    <h1 className="font-[500] text-gray-900">
                      Lastname</h1>
                    <span className='text-gray-800 text-[12px] md:text-[14px]'>
                      <BsTextarea />
                    </span>
                  </div>

                  <input
                    className="p-1 md:p-2  border-b bg-none outline-none  placeholder:text-gray-400 placeholder:text-[11px] placeholder:pl-4  w-full md:min-w-full lg:min-w-full h-[50px] md:h-[60px] text-[13px]  font-[600] "
                    type="text"
                    value={lastname}
                    onChange={(e) => setLastname(e.target.value)}
                    placeholder="Title"
                  />
                </div>
              </div>

              {/* Country */}
              <div className="flex flex-col  justify-start content-start  items-start gap-1 md:gap-2 p-1 md:p-2 text-[12px] md:text-[13px]  w-full ">
                <div className='flex flex-row items-center content-center justify-start gap-1 md:gap-2'>
                  <h1 className="font-[500] text-gray-900">
                    Country</h1>
                  <span className='text-gray-800 text-[12px] md:text-[14px]'>
                    <BsGlobeEuropeAfrica />
                  </span>
                </div>

                <input
                  className="p-1 md:p-2  border-b bg-none outline-none  placeholder:text-gray-400 placeholder:text-[11px] placeholder:pl-4  w-full md:min-w-full lg:min-w-full h-[50px] md:h-[60px] text-[13px]  font-[600] "
                  type="text"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  placeholder="Country"
                />
              </div>



              {/* mobile */}
              <div className="flex flex-col  justify-start content-start  items-start gap-1 md:gap-2 p-1 md:p-2 text-[12px] md:text-[13px]  w-full ">
                <div className='flex flex-row items-center content-center justify-start gap-1 md:gap-2'>
                  <h1 className="font-[500] text-gray-900">
                    Mobile</h1>
                  <span className='text-gray-800 text-[12px] md:text-[14px]'>
                    <BsPhoneFill />
                  </span>
                </div>

                <input
                  className="p-1 md:p-2  border-b bg-none  outline-none  placeholder:text-gray-400 placeholder:text-[11px] placeholder:pl-4  w-full md:min-w-full lg:min-w-full h-[50px] md:h-[60px] text-[13px]  font-[600] "
                  type="text"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                  placeholder="Country"
                />
              </div>


              {/* company organization */}
              <div className="flex flex-col  justify-start content-start  items-start gap-1 md:gap-2 p-1 md:p-2 text-[12px] md:text-[13px]  w-full ">
                <div className='flex flex-row items-center content-center justify-start gap-1 md:gap-2'>
                  <h1 className="font-[500] text-gray-900">
                    Company/Organization</h1>
                  <span className='text-gray-800 text-[12px] md:text-[14px]'>
                    <BsFillPeopleFill />
                  </span>
                </div>

                <input
                  className="p-1 md:p-2  border-b bg-none outline-none  placeholder:text-gray-400 placeholder:text-[11px] placeholder:pl-4  w-full md:min-w-full lg:min-w-full h-[50px] md:h-[60px] text-[13px]  font-[600] "
                  type="text"
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                  placeholder="Company/organization"
                />
              </div>




              {/* bio */}
              <div className="flex flex-col  justify-start content-start  items-start gap-1 md:gap-2 p-1 md:p-2 text-[12px] md:text-[13px]  w-full">
                <div className='flex flex-row items-center content-center justify-start gap-1 md:gap-2'>
                  <h1 className="font-[500] text-gray-900">
                    Profile Bio</h1>
                  <span className='text-gray-800 text-[12px] md:text-[14px]'><BsParagraph /></span>
                </div>
                <textarea
                  value={bio}
                  onChange={(e) => setBio(e.target.value)}
                  placeholder="Bio"
                  className="p-1 md:p-2  border-b bg-none outline-none  placeholder:text-gray-400 placeholder:text-[11px] placeholder:pl-4  w-full md:min-w-[320px] lg:min-w-[400px] min-h-[100px] md:min-h-[120px"
                ></textarea>
              </div>


              {/* update button */}

              {updateLoading ? (
                <div className='w-full'>

                  <Loading /></div>) : (<button
                    type="submit"
                    className="p-1  md:p-2  bg-none outline-none rounded-lg    text-[0.8rem]  flex flex-row gap-1 md:gap-2  justify-center items-center content-center self-end bg-green-600 hover:bg-green-500 text-white w-[150px] text-center h-[40px] "
                  >

                    <h1>Save</h1>
                    <BsSave2 />
                  </button>)}





            </form>


            {/* sensitive info change links */}
            <div className="flex flex-col  justify-start content-start  items-start gap-1 md:gap-2 p-1 md:p-2 text-[12px] md:text-[13px]  w-full  ">

              {/* email */}
              <div className="flex flex-col  justify-start content-start  items-start gap-1 md:gap-2 p-2 md:p-2  text-[11px] sm:text-[12px] md:text-[13px]  w-full  border border-gray-100 bg-gray-100 rounded-lg">
                <div className='flex flex-row items-center content-center justify-start gap-1 md:gap-2'>
                  <h1 className="font-[500] text-gray-900">
                    Email



                  </h1>
                  <span className='text-gray-800 text-[12px] md:text-[14px]'>
                    <AiFillMail />
                  </span>
                </div>
                <div className='flex flex-row items-center content-center justify-between w-full text-[11px] md:text-[12px]'>
                  <h1>
                    {user?.email}
                  </h1>


                  <div
                    onClick={() => navigate('/account/email/change/request')}
                    className='flex flex-row items-center content-center justify-start gap-1 md:gap-2  text-blue-600 hover:text-blue-500  cursor-pointer'>
                    <h1>Change Email</h1>
                    <BsChevronRight />

                  </div>
                </div>
                <div className='w-full'>
                  {user?.is_email_authenticated ? (
                    <div className='flex flex-row items-center content-center justify-between w-full  p-1  rounded-md'>
                      <h1 className=' flex flex-row items-center content-center justify-start  text-[11px] md:text-[13px] gap-1 md:gap-2'>
                        <BsCheckCircleFill className='text-green-600' /> Your email is verified
                      </h1>
                    </div>

                  ) : (
                    <div className='flex flex-row items-center content-center justify-between w-full bg-red-100 p-1 md:p-2 rounded-md'>
                      <h1 className=' flex flex-row items-center content-center justify-start  text-[11px] md:text-[13px] gap-1 md:gap-2'>
                        <BsInfoCircleFill className='text-red-600' />
                        Your email is not verified
                      </h1>

                      <div onClick={() => navigate('/account/email/verify')} className='flex flex-row items-center content-center justify-start gap-1 md:gap-2  text-blue-600 hover:text-blue-500  cursor-pointer'>
                        <h1 >Verify Now</h1>
                        <BsChevronRight />
                      </div>
                    </div>)}
                </div>
              </div>

              {/* password */}
              <div className="flex flex-col  justify-start content-start  items-start gap-1 md:gap-2 p-2 md:p-2  text-[11px] sm:text-[12px] md:text-[13px]  w-full  border border-gray-100 bg-red-50 rounded-lg">
                <div className='flex flex-row items-center content-center justify-start gap-1 md:gap-2'>
                  <h1 className="font-[500] text-gray-900">
                    Password</h1>
                  <span className='text-gray-800 text-[12px] md:text-[14px]'>
                    <AiFillLock />
                  </span>
                </div>



                <div className='flex flex-row items-center content-center justify-between w-full text-[11px] md:text-[12px]'>
                  <h1>
                    Change Your Account Password
                  </h1>


                  <div onClick={() => navigate('/account/password/check')}
                    className='flex flex-row items-center content-center justify-start gap-1 md:gap-2  text-blue-600 hover:text-blue-500  cursor-pointer'>
                    <h1>Change Password</h1>
                    <BsChevronRight />

                  </div>
                </div>
              </div>
              {/* logout */}
              <div className="flex flex-col  justify-start content-start  items-start gap-1 md:gap-2 p-2 md:p-2  text-[11px] sm:text-[12px] md:text-[13px]  w-full  border border-gray-100 bg-gray-50 rounded-lg">
                <div className='flex flex-row items-center content-center justify-start gap-1 md:gap-2'>
                  <h1 className="font-[500] text-gray-900">
                    Logout</h1>
                  <span className='text-gray-800 text-[12px] md:text-[14px]'>
                    <AiFillLock />
                  </span>
                </div>



                <div className='flex flex-row items-center content-center justify-between w-full text-[11px] md:text-[12px]'>
                  <h1>
                    Logout from your current account
                  </h1>


                  <div onClick={(e) => handleLogout(e)}
                    className='flex flex-row items-center content-center justify-start gap-1 md:gap-2  text-red-600 hover:text-red-500  cursor-pointer'>
                    <h1>Logout</h1>
                    <BiLogOutCircle />

                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default ProfileEditPage