import React from 'react'
import { motion } from "framer-motion"

function About() {
  const easing = [0.6, -0.5, 0.01, 0.99];

  const fadeInUp = {
    initial: {
      y: 30,
      opacity: 0.6
    },

    initialTexts: {
      y: 30,
      opacity: 0
    },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: .5,
        ease: easing,
        type: "spring",



      }
    }
  };

  const stagger = {
    animate: {
      transition: {
        staggerChildren: 0.1
      }
    }
  };
  return (
    <motion.div className='h-full lg:h-auto flex flex-col justify-center content-center items-center gap-2 md:gap-3 inset-0 mt-[100px] mx-auto '>

      <motion.div
        initial={{ opacity: 0.5 }}
        whileInView="animate"
        viewport={{ once: false, amount: 0.4 }}
        variants={fadeInUp}

        exit={{ opacity: 0.5 }}
        className=' p-1 md:p-2'>
           <motion.h1
           initial="initialTexts"
           whileInView="animate"
           viewport={{ once: false, amount: 0.4 }}
           variants={fadeInUp}
           exit="initialTexts"
            className=' text-[2rem]  font-[500]'>Genz254</motion.h1>
       

        <motion.h1
          initial="initialTexts"
          whileInView="animate"
          viewport={{ once: false, amount: 0.4 }}
          variants={fadeInUp}
          exit="initialTexts"
          className='text-[12px] md:text-[14px] font-[700]'>About Genz254</motion.h1>
        <motion.p
          initial="initialTexts"
          whileInView="animate"
          viewport={{ once: false, amount: 0.4 }}
          variants={fadeInUp}
          exit="initialTexts"
          className='max-w-xs md:max-w-md text-[12px] md:text-[14px] text-gray-600'>Welcome to genz254, a web app we've built to enable people from all walks of life to share their ideas and engage in meaningful discussions. Our platform is designed to encourage open dialogue on a variety of issues across different fields, from politics and social justice to education and technology. We believe that everyone should have a space to voice their thoughts and contribute to important conversations, and genz254 offers that space in a safe and inclusive environment
        </motion.p>
        <motion.p
          initial="initialTexts"
          whileInView="animate"
          viewport={{ once: false, amount: 0.4 }}
          variants={fadeInUp}
          exit="initialTexts"
          className='max-w-xs md:max-w-md text-[12px] md:text-[14px] text-gray-600'>Beyond discussion, genz254 serves as a hub for community-driven events. Users can not only discover and attend events related to their interests but also create their own events to bring people together. Whether it's a local forum, a virtual debate, or a grassroots campaign, our event features are designed to connect people with shared passions and inspire real-world action.
        </motion.p>
        <motion.p
          initial="initialTexts"
          whileInView="animate"
          viewport={{ once: false, amount: 0.4 }}
          variants={fadeInUp}
          exit="initialTexts"
          className='max-w-xs md:max-w-md text-[12px] md:text-[14px] text-gray-600'>Additionally, genz254 offers the ability to create and manage donations for various causes. Whether you're fundraising for a community project, supporting a charitable organization, or organizing a campaign for social change, our platform makes it easy to rally support. We are committed to helping people turn ideas into impact, and through events and donations, genz254 empowers users to make a tangible difference in the world.
        </motion.p>
      </motion.div>



    </motion.div>
  )
}
export default About