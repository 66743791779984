import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { USER_UPDATE_PROFILE_RESET } from '../../features/constants/userConstants';
import { getUserDetails } from '../../features/actions/userActions';
import { REACT_APP_URL } from '../../utilities/utils';
import { BsChevronLeft, BsCloudUpload, BsEraser, BsFillXCircleFill } from 'react-icons/bs';
import MyDonationPage from './donation/MyDonationPage';
import MyEventsPage from './events/MyEventsPage';
import MyPostsPage from './posts/MyPostsPage';
import { BiImage, BiSolidCloudUpload, BiSolidDashboard, BiSolidEditAlt, BiSolidMagicWand } from 'react-icons/bi';
import { motion } from 'framer-motion';
import axios from 'axios';

function AccountPage() {
    const [ActiveTab, setActiveTab] = useState("posts");
    const [isSticky, setIsSticky] = useState(false);
    const [changeBG, setChangeBG] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [image, setImage] = useState(null);
    const [displayImage, setDisplayImage] = useState(null);
    const tabRef = useRef(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    // Correctly memoize defaultUser
    const defaultUser = useMemo(() => ({
        profile_photo: 'noavatar.jpg',
        background_photo: 'placeholder.jpg',
        username: 'Unknown User',
        sticker_name: 'No Sticker Name',
        bio: 'No bio available',
        is_staff: false,
    }), []);

    const userDetails = useSelector((state) => state.userDetails);
    const { user, loading } = userDetails;

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
    const { success } = userUpdateProfile;

    useEffect(() => {
        if (!userInfo) {
            navigate("/login");
        } else {
            if (!user || !user.email || success || userInfo._id !== user._id) {
                dispatch({ type: USER_UPDATE_PROFILE_RESET });
                if (navigator.onLine) {
                    dispatch(getUserDetails("profile"));
                } else {
                    dispatch({
                        type: USER_UPDATE_PROFILE_RESET,
                        payload: defaultUser,
                    });
                }
            }
        }
    }, [dispatch, navigate, user, userInfo, success, defaultUser]);

    useEffect(() => {
        const currentHash = location.hash.replace('#', '');
        if (currentHash) {
            setActiveTab(currentHash);
        }
    }, [location.hash]);

    useEffect(() => {
        const handleScroll = () => {
            const tabPosition = tabRef.current.getBoundingClientRect().top;
            setIsSticky(tabPosition <= 0);
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const profileImageUpload = async () => {
        const formData = new FormData();
        formData.append("image", image);
        formData.append("user_id", userInfo.id);
        setUploading(true);

        try {
            const config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${userInfo.token}`,
                },
            };
            await axios.post(`${REACT_APP_URL}/users/profile/upload/background/`, formData, config);
            setUploading(false);
            dispatch(getUserDetails("profile"));
            setDisplayImage(null);
            setImage(null);
        } catch (error) {
            setUploading(false);
        }
    };

    const handleClearImage = () => {
        setDisplayImage(null);
        setImage(null);
    };

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        navigate(`${location.pathname}#${tab}`);
    };

    const handleChangeBG = () => {
        setDisplayImage(null);
        setImage(null);
        setChangeBG(!changeBG);
    };


    return (
        <main className='min-h-[500px]'>

            {/* main div */}
            <div className='flex flex-col justify-center content-center items-center gap-1 md:gap-2 lg:mt-[60px]  mx-auto'>

                {/* profile section */}
                <section className="w-full  lg:w-[70vw]">
                    {/* background-img */}
                    <div className='w-full relative flex flex-col items-center justify-center content-center '>
                        {/* admin and back button */}
                        <div className='w-full absolute top-4 flex flex-row  justify-between items-center content-center p-1 md:p-2  lg:w-[70vw]'>
                            {/* back button */}
                            <div
                                onClick={() => navigate(-1)}
                                className=' flex flex-row items-center content-center justify-center gap-1 md:gap-2 bg-blend-saturation text-[12px] md:text-[14px] bg-white bg-opacity-75 rounded-sm p-1 pl-2 pr-3 md:p-2 md:pl-3 md:pr-3 cursor-pointer hover:bg-opacity-90'>
                                <BsChevronLeft />
                                <h1>Back</h1>

                            </div>


                            {/* Dashboard */}
                            {user.is_staff && (
                                <div
                                    onClick={() => navigate('/dashboard')}
                                    className=' flex flex-row items-center content-center justify-center gap-1 md:gap-2 bg-blend-saturation text-[12px] md:text-[14px] bg-blue-600 bg-opacity-75 text-white rounded-md p-1 pl-2 pr-3 md:p-2 md:pl-3 md:pr-3 cursor-pointer hover:bg-opacity-100'>

                                    <h1>Dashboard</h1>
                                    <BiSolidDashboard />

                                </div >)}

                        </div>

                        {displayImage ? (<img src={displayImage}
                             className="h-[35vh] w-[100vw] lg:min-w-[100vw] lg:h-[40vh] object-cover object-center  "
                            alt="background" />) : (<img src={user?.background_photo ? `${REACT_APP_URL}${user?.background_photo}` : "/placeholder.jpg"}
                                className="h-[35vh] w-[100vw] lg:min-w-[100vw] lg:h-[40vh] object-cover object-center  "
                                alt="background" />)}



                        <div className='md:w-[80vw] lg:w-[70vw]'>
                            <div
                                onClick={handleChangeBG}
                                className='absolute bottom-2 right-2 flex flex-row items-center content-center justify-center gap-1 md:gap-2 bg-blend-saturation text-[10px] md:text-[12px] bg-gray-400 bg-opacity-75 text-white rounded-md  p-1 pl-2 pr-3 md:p-2 md:pl-3 md:pr-3 cursor-pointer hover:bg-opacity-100 '>

                                <h1>Background</h1>
                                <BiSolidMagicWand />

                            </div>

                        </div>


                        {changeBG ? (
                            <motion.div
                                initial={{ bottom: -60, opacity: 0 }}
                                animate={{ bottom: -40, opacity: 1 }}
                                exit={{ bottom: -100, opacity: 0 }}
                                className='absolute z-20 bg-white text-[11px] flex flex-col justify-start items-start content-center gap-1 md:gap-2 lg:w-[25vw] md:text-[12px] p-1 md:p-4  bottom-[-60px] right-2   drop-shadow-lg rounded-lg'>
                                <div className='flex flex-row justify-between content-center items-center gap-1 font-[400] w-full  p-1 text-[0.7rem]'>

                                    <h1 className='flex flex-row justify-center content-center items-center gap-1'><span><BiImage /></span> Background </h1>


                                    <BsFillXCircleFill onClick={handleChangeBG} className='text-[1rem] cursor-pointer' />
                                </div>
                                {/* {displayImage ?
                                    (<>
                                        <img
                                            src={displayImage}
                                            className="w-full h-[80px] lg:w-full md:h-[120px]   object-cover object-center drop-shadow-xl"
                                            alt=""
                                        /></>

                                    ) : (
                                        <img
                                            src={user.profile_photo ? `${REACT_APP_URL}${user?.profile_photo}` : 'placeholder.jpg'}
                                            className="w-full h-[80px] lg:w-full md:h-[120px]  object-cover object-center drop-shadow-xl"
                                            alt=""
                                        />
                                    )} */}

                                {/* file input */}

                                {loading === false ? (<input class="block w-full text-[11px] text-gray-900 bg-gray-50 rounded-md border border-gray-300 cursor-pointer focus:outline-none "
                                    aria-describedby="file_input_help"
                                    id="file_input"
                                    type="file"
                                    onChange={(e) => {
                                        setImage(e.target.files[0]);
                                        const file = e.target.files[0];
                                        const reader = new FileReader();
                                        reader.readAsDataURL(file);
                                        reader.onload = (event) => {
                                            const result = event.target.result;
                                            setDisplayImage(result);
                                        };
                                    }} />) : ""}

                                {loading ? (<div className='flex flex-row it content-center justify-center gap-1 md:gap-2 text-green-600 bg-gray-50 animate-pulse text-[12px] w-full h-full'>
                                    <h1 >Uploading</h1>
                                    <BiSolidCloudUpload />

                                </div>) : ""}



                                {displayImage === null & loading === false ? (""
                                ) : (<div className='flex flex-row items-center content-center justify-between w-full p-1'>


                                    <div onClick={handleClearImage}
                                        className='flex flex-row gap-1 md:gap-2  text-red-500 justify-center items-center content-center   text-[11px] md:text-[12px] w-[100px] h-[20px] self-start cursor-pointer  hover:font-[500]   rounded-full '>
                                        <h1>Clear </h1>
                                        <BsEraser />
                                    </div>
                                    <div
                                        onClick={profileImageUpload}
                                        className='flex flex-row gap-1 md:gap-2  text-green-500 justify-center items-center content-center   text-[11px] md:text-[12px] w-[100px] h-[20px] self-end cursor-pointer  hover:font-[500]   rounded-full '>
                                        <h1>Change</h1>
                                        <BsCloudUpload />
                                    </div>

                                </div>
                                )}

                            </motion.div>
                        ) : ""}






                    </div>
                    {/* profile image and info */}

                    <div className=' flex flex-col items-center content-center justify-center w-full md:w-[80%]  lg:w-[70vw] relative mx-auto'>

                        {/* image */}
                        {/* profile photo */}
                        <div className='absolute top-[-50%] left-1 '>
                        <img 
    src={user?.profile_photo ? `${REACT_APP_URL}${user.profile_photo}` : '/noavatar.jpg'} 
    className="w-[120px] h-[120px] md:w-[200px] md:h-[200px] lg:h-[170px] lg:w-[170px]  object-center object-cover rounded-full  p-1 bg-white bg-opacity-80 "
    alt="profile" 
/>

                        </div>


                        {/* info */}
                        <div className='flex flex-col justify-end items-end content-center text-black gap-1   p-2 md:p-3 w-full text-[12px]'>
                            <div
                                onClick={() => navigate("/account/edit")}
                                className='flex flex-row gap-1 md:gap-2  text-green-500 justify-center items-center content-center   text-[11px] md:text-[12px]   self-end cursor-pointer  hover:font-[500]  '>
                                <h1>Edit Profile</h1>
                                <BiSolidEditAlt />
                            </div>
                            {/* username and sticker_name */}
                            <div className='flex flex-col justify-center  items-end content-center font-light p-1'>
                                <h1> {user?.sticker_name || defaultUser.sticker_name}</h1>
                                <h1 className='font-semibold'>@{" "}{user?.username || defaultUser.username}</h1>

                            </div>


                            {/* username */}
                            <div className='font-[400] max-w-xs text-right p-1'>

                                <h1>{user?.bio || defaultUser.bio}</h1>

                            </div>



                        </div>

                    </div>


                </section>


                {/* posts ,events and donations section */}

                <section
                    ref={tabRef}
                    className="flex flex-col items-center content-center justify-center gap-3 md:gap-4 p-2 md:p-3 w-full md:w-[80vw] lg:w-[70vw]   mx-auto ">
                    <div className={isSticky ? 'flex flex-row w-full sticky top-0 lg:top-[65px] sm:w-full justify-evenly content-center items-center bg-gray-200 rounded-md h-[40px] text-[12px] md:text-[14px] p-1  gap-1 md:gap-2  z-30 shadow-xl shadow-gray-500/40' : 'flex flex-row w-full sm:w-full justify-evenly content-center items-center bg-gray-100 rounded-md h-[40px] text-[12px] md:text-[14px] p-1  gap-1 md:gap-2 border border-gray-100'}>

                        <div
                            onClick={() => handleTabChange('posts')}
                            className={ActiveTab === "posts" ? 'bg-white w-full h-full flex flex-row items-center content-center justify-center border border-gray-200 rounded-md text-gray-900 cursor-pointer p-1   font-[500]' : ' w-full h-full flex flex-row items-center content-center justify-center rounded-md text-gray-700 cursor-pointer p-1   hover:bg-gray-200 hover:font-[500]'}>
                            <h1>Posts</h1>

                        </div>

                        <div
                            onClick={() => handleTabChange('events')}
                            className={ActiveTab === 'events' ? 'bg-white w-full h-full flex flex-row items-center content-center justify-center border border-gray-200 rounded-md text-gray-900 cursor-pointer p-1  font-[500]' : ' w-full h-full flex flex-row items-center content-center justify-center rounded-md text-gray-700 cursor-pointer p-1   hover:bg-gray-200 hover:font-[500]'} >
                            <h1>Events</h1>
                        </div>
                        <div
                            onClick={() => handleTabChange('donations')}
                            className={ActiveTab === 'donations' ? 'bg-white w-full h-full flex flex-row items-center content-center justify-center border border-gray-200 rounded-md text-gray-900 cursor-pointer p-1  font-[500]' : ' w-full h-full flex flex-row items-center content-center justify-center rounded-md text-gray-700 cursor-pointer p-1   hover:bg-gray-200 hover:font-[500]'} >
                            <h1>Donations</h1>
                        </div>
                    </div>

                    {ActiveTab === "donations" && <MyDonationPage key={3} />}
                    {ActiveTab === "events" && <MyEventsPage key={2} />}
                    {ActiveTab === "posts" && <MyPostsPage key={1} />}

                </section>


            </div>



        </main>
    )
}

export default AccountPage