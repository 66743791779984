import { motion } from 'framer-motion'
import React from 'react'
import { BiLogoFacebookSquare, BiLogoInstagramAlt, BiLogoLinkedinSquare } from 'react-icons/bi'
import { BsTwitterX } from 'react-icons/bs'

function Team() {
  const easing = [0.6, -0.5, 0.01, 0.99];

  const fadeInUp = {
    initial: {
      y: 30,
      opacity: 0.6
    },

    initialTexts: {
      y: 30,
      opacity: 0
    },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: .5,
        ease: easing,
        type: "spring",



      }
    }
  };

  const stagger = {
    animate: {
      transition: {
        staggerChildren: 0.1
      }
    }
  };
  return (
    <motion.div className='h-full lg:h-auto   flex flex-col justify-center content-center items-center gap-2 md:gap-3 inset-0 mt-[100px] '>
      <motion.div
        initial={{ opacity: 0.5 }}
        whileInView="animate"
        viewport={{ once: false, amount: 0.4 }}
        variants={fadeInUp}
        exit={{ opacity: 0.5 }}
        className=' p-1 md:p-2'>
        <motion.h1
        initial="initialTexts"
        whileInView="animate"
        viewport={{ once: false, amount: 0.4 }}
        variants={fadeInUp}
        exit="initialTexts"
         className='text-lg md:text-xl font-[700]'>The Team</motion.h1>
        <motion.p
        initial="initialTexts"
        whileInView="animate"
        viewport={{ once: false, amount: 0.4 }}
        variants={fadeInUp}
        exit="initialTexts"
         className='max-w-xs md:max-w-md text-[12px] md:text-[14px] text-gray-600'>The team behind the free speech app, genz254, is made up of passionate individuals from diverse fields, including tech, NGOs, and other sectors. United by a common goal, they aim to create a platform where people can openly express their thoughts on political issues and other important topics. With their combined expertise, they strive to empower voices that may otherwise go unheard, fostering an inclusive and open environment for dialogue.
        </motion.p>
      </motion.div>

      {/* avatars */}
      <motion.div
        initial={{ opacity: 0.5 }}
        whileInView="animate"
        viewport={{ once: false, amount: 0.6 }}
        variants={fadeInUp}
        exit={{ opacity: 0.5 }}
        className='flex flex-col sm:flex-row sm:flex-grow items-start content-center justify-center gap-2 md:gap-3 p-1 md:p-2'>


        {/* team member */}
        <motion.div
          initial="initialTexts"
          whileInView="animate"

          viewport={{ once: false, amount: 0.6 }}
          variants={fadeInUp}
          exit="initialTexts"

          className='flex flex-col items-center content-center justify-center gap-1  p-1 md:p-2'>
          {/* image */}
          <img src="/noavatar.jpg"
            className='w-[100px] h-[100px] rounded-full object-cover object-center'
            alt="Profile" />

          {/* name */}
          <h1 className='font-[500] text-[12px] md:text-[14px] border-b border-gray-200'>John Doe</h1>
          <h2 className='font-[400] text-gray-500 text-[11px] md:text-[]'>Software Developer</h2>

          <div className='bg-gray-50 border rounded-lg flex flex-row gap-2 md:gap-3 justify-evenly items-center content-center text-[14px] md:text-[14px] p-1 md:p-2 w-full'>
            <BiLogoLinkedinSquare className='hover:text-gray-500 hover:scale-110 duration-75 delay-75 ease-in-out cursor-pointer m-1' />
            <BsTwitterX className='hover:text-gray-500 hover:scale-110 duration-75 delay-75 ease-in-out cursor-pointer m-1' />
            <BiLogoInstagramAlt className='hover:text-gray-500 hover:scale-110 duration-75 delay-75 ease-in-out cursor-pointer m-1' />
            <BiLogoFacebookSquare className='hover:text-gray-500 hover:scale-110 duration-75 delay-75 ease-in-out cursor-pointer m-1' />
          </div>


        </motion.div>
        {/* team member */}
        <motion.div
          initial="initialTexts"
          whileInView="animate"

          viewport={{ once: false, amount: 0.6 }}
          variants={fadeInUp}
          exit="initialTexts"

          className='flex flex-col items-center content-center justify-center gap-1  p-1 md:p-2'>
          {/* image */}
          <img src="/noavatar.jpg"
            className='w-[100px] h-[100px] rounded-full object-cover object-center'
            alt="Profile" />

          {/* name */}
          <h1 className='font-[500] text-[12px] md:text-[14px] border-b border-gray-200'>John Doe</h1>
          <h2 className='font-[400] text-gray-500 text-[11px] md:text-[]'>Software Developer</h2>

          <div className='bg-gray-50 border rounded-lg flex flex-row gap-2 md:gap-3 justify-evenly items-center content-center text-[14px] md:text-[14px] p-1 md:p-2 w-full'>
            <BiLogoLinkedinSquare className='hover:text-gray-500 hover:scale-110 duration-75 delay-75 ease-in-out cursor-pointer m-1' />
            <BsTwitterX className='hover:text-gray-500 hover:scale-110 duration-75 delay-75 ease-in-out cursor-pointer m-1' />
            <BiLogoInstagramAlt className='hover:text-gray-500 hover:scale-110 duration-75 delay-75 ease-in-out cursor-pointer m-1' />
            <BiLogoFacebookSquare className='hover:text-gray-500 hover:scale-110 duration-75 delay-75 ease-in-out cursor-pointer m-1' />
          </div>


        </motion.div>
        {/* team member */}
        <motion.div
          initial="initialTexts"
          whileInView="animate"

          viewport={{ once: false, amount: 0.6 }}
          variants={fadeInUp}
          exit="initialTexts"

          className='flex flex-col items-center content-center justify-center gap-1  p-1 md:p-2'>
          {/* image */}
          <img src="/noavatar.jpg"
            className='w-[100px] h-[100px] rounded-full object-cover object-center'
            alt="Profile" />

          {/* name */}
          <h1 className='font-[500] text-[12px] md:text-[14px] border-b border-gray-200'>John Doe</h1>
          <h2 className='font-[400] text-gray-500 text-[11px] md:text-[]'>Software Developer</h2>

          <div className='bg-gray-50 border rounded-lg flex flex-row gap-2 md:gap-3 justify-evenly items-center content-center text-[14px] md:text-[14px] p-1 md:p-2 w-full'>
            <BiLogoLinkedinSquare className='hover:text-gray-500 hover:scale-110 duration-75 delay-75 ease-in-out cursor-pointer m-1' />
            <BsTwitterX className='hover:text-gray-500 hover:scale-110 duration-75 delay-75 ease-in-out cursor-pointer m-1' />
            <BiLogoInstagramAlt className='hover:text-gray-500 hover:scale-110 duration-75 delay-75 ease-in-out cursor-pointer m-1' />
            <BiLogoFacebookSquare className='hover:text-gray-500 hover:scale-110 duration-75 delay-75 ease-in-out cursor-pointer m-1' />
          </div>


        </motion.div>



      </motion.div>

    </motion.div>
  )
}

export default Team