import React from 'react'
import { BiCopyright } from 'react-icons/bi'
import { useNavigate } from 'react-router'

function Footer() {
  const navigate = useNavigate();
  return (
    <div className='min-h-[4rem]  lg:h-full flex flex-col justify-center content-center items-center lg:justify-end lg:items-end mb-[4.5rem]  mt-[20px] pt-6 border-t lg:mb-0 p-2 md:p-3 rounded-xl  '>

      {/* links side */}
      <div>
        <ul className='flex flex-row items-center justify-center content-center gap-2 md:gap-4 text-black font-[500] text-[12px] md:text-[14px]'>
          <li onClick={() => navigate("/team")} className="hover:text-gray-500 cursor-pointer">Team</li>
          <li onClick={() => navigate("/about")} className="hover:text-gray-500 cursor-pointer">About</li>
        </ul>
      </div>


      {/* logo side */}
      <div className='flex flex-col justify-center content-center items-center lg:items-end lg:justify-end gap-0'>
        <h1 className='flex flex-col justify-center content-center items-center text-[2rem] font-[600]'>
          Genz254

        </h1>
        <span className='text-[12px] font-[400]  md:text-[14px]'>Free Speech</span>



      </div>



      {/* copyright side */}
      <div className='flex flex-row items-center content-center justify-center text-[11px] md:text-[14px] gap-[2px] text-gray-600'>
        <h1>Copyright</h1>
        <BiCopyright className='text-black' />
        <h1>2024</h1>
        <h1 className='text-black font-[500]'>Genz254</h1>
      </div>
    </div>
  )
}

export default Footer