import React, { useEffect, useState } from 'react'
import { REACT_API_URL } from '../../../utilities/utils';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import Message from '../../message/Message';
import { BsArrowDown,  BsCalendar2Check, BsCalendar2X,  BsEraser, BsHouse, BsImage, BsImageFill, BsParagraph, BsPinMap, BsTextarea, BsUpload } from 'react-icons/bs';



const formatDateToYYYYMMDD = (date) => {
 
    const dateForm = new Date(date)

    const day = String(dateForm.getDate()).padStart(2,'0')
    const month = String(dateForm.getMonth()+ 1).padStart(2,'0')
    const year = dateForm.getFullYear()

    return `${year}-${month}-${day}`



}


function CreateEvent() {

    const navigate = useNavigate();
    const [title, setTitle] = useState("");
    const [venue, setVenue] = useState("");
    const [location, setLocation] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [description, setDescription] = useState("");
    const [image, setImage] = useState(null);
    const [displayImage, setDisplayImage] = useState(null);
    const [isLogged, setLogged] = useState(false);
    const [loading, setLoading] = useState(false);

    const  formattedStartDate =  formatDateToYYYYMMDD(startDate);
    const  formattedEndDate =  formatDateToYYYYMMDD(endDate);
   

    const [message, setMessage] = useState("");
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    // let date = new Date(startDate);

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;

    useEffect(() => {
        if (!userInfo) {
            setLogged(false);
        } else {
            setLogged(true);
        }
    }, [userInfo]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("title", title);
        formData.append("venue", venue);
        formData.append("location", location);
        formData.append("start_date", formattedStartDate);
        formData.append("end_date", formattedEndDate);
        formData.append("description", description);
        formData.append("event_cover", image);
        formData.append("user", userInfo.id);

        setLoading(true);

        fetch(`${REACT_API_URL}/events/create/`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
            body: formData,
        })
            .then((response) => response.json())
            .then((data) => {
                setSuccess(true);
                setLoading(false);
                setMessage("Event Added");
                navigate("/events");
               
            })
            .catch((error) => {
                setError(true);
                setMessage(error?.detail);
                setLoading(false);
            });
    };



    const handleClearImage = () => {
        setDisplayImage(null);
    }
    return (
        <div className="flex flex-col justify-start items-start content-start gap-2 md:gap-3  p-1 md:p-2 w-full md:w-full  min-h-[80vh] lg:min-h-auto">

            <form
                onSubmit={handleSubmit}
                className='w-full md:w-[90%] flex flex-col md:flex-row justify-center content-center items-start gap-2 md:gap-4'>


                {/* image */}

                <div className="flex flex-col  justify-start content-start  items-start gap-1 md:gap-2 p-1 md:p-2 text-[12px] md:text-[13px]  w-full mb-[20px]  h-full">
                    <div className='flex flex-row items-center content-center justify-start gap-1 md:gap-2 w-full'>
                        <h1 className="font-[500] text-gray-900">
                            Photo</h1>
                        <span className='text-gray-800 text-[12px] md:text-[14px]'><BsImage /></span>
                    </div>

                    {displayImage === null ? (<div className="w-full h-[250px] lg:w-full md:h-[300px] border border-gray-100 rounded-lg flex flex-col items-center justify-center content-center text-gray-500">


                        <BsImageFill className=' text-gray-300 text-[5rem]' />
                        <h1>Add Photo</h1>
                        <BsArrowDown className=' align-text-bottom text-[1.6rem] text-gray-300' />


                    </div>) : (<img src={displayImage} alt="" className="w-full h-[250px] lg:w-[50vw] md:h-[300px] rounded-xl  object-cover object-center drop-shadow-xl" />)}



                    <input
                        class="block w-full text-sm text-gray-900 bg-gray-50 rounded-md border border-gray-300 cursor-pointer focus:outline-none "
                        aria-describedby="file_input_help"
                        id="file_input"
                        type="file"
                        onChange={(e) => {
                            setImage(e.target.files[0]);
                            const file = e.target.files[0];
                            const reader = new FileReader();
                            reader.readAsDataURL(file);
                            reader.onload = (event) => {
                                const result = event.target.result;
                                setDisplayImage(result);
                            };
                        }}
                    />


                    {displayImage == null ? (""
                    ) : (
                        <div onClick={handleClearImage}
                            className='flex flex-row gap-1 md:gap-2 p-1 bg-blue-200 justify-center items-center content-center rounded-full border border-gray-300 text-[11px] md:text-[14px] w-[60px] self-end cursor-pointer hover:bg-blue-100 hover:font-[500] shadow-lg'>
                            <h1>Clear </h1>
                            <BsEraser />
                        </div>)}


                </div>



                <div className='w-full flex flex-col  items-start justify-start content-center gap-1 md:gap-3'>
                    {/* name */}
                    <div className="flex flex-col  justify-start content-start  items-start gap-1 md:gap-2 p-1 md:p-2 text-[12px] md:text-[13px]  w-full ">
                        <div className='flex flex-row items-center content-center justify-start gap-1 md:gap-2'>
                            <h1 className="font-[500] text-gray-900">
                                Title</h1>
                            <span className='text-gray-800 text-[12px] md:text-[14px]'>
                                <BsTextarea />
                            </span>
                        </div>

                        <input
                            className="p-1 md:p-2  border-b bg-none outline-none  placeholder:text-gray-400 placeholder:text-[11px] placeholder:pl-4  w-full md:min-w-full lg:min-w-full h-[50px] md:h-[60px] text-[13px]  font-[600] "
                            type="text"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            placeholder="Title"
                        />
                    </div>



                    {/* link */}

                    {/* <div className="flex flex-col  justify-start content-start  items-start gap-1 md:gap-2 p-1 md:p-2 text-[12px] md:text-[13px]  w-full">

                <div className='flex flex-row items-center content-center justify-start gap-1 md:gap-2'>
                    <h1 className="font-[500] text-gray-900">
                        Link</h1>
                    <span className='text-gray-800 text-[12px] md:text-[14px]'><BsLink /></span>
                </div>

                <input
                    className="p-1 md:p-2  border-b bg-none outline-none  placeholder:text-gray-400 placeholder:text-[11px] placeholder:pl-4  w-full md:min-w-[320px] lg:min-w-[400px] h-[45px] md:h-[50px]  "
                    type="Link"
                    value={link}
                    onChange={(e) => setLink(e.target.value)}
                    placeholder="Post Link"
                />
            </div> */}


                    {/* Location */}
                    <div className="flex flex-col  justify-start content-start  items-start gap-1 md:gap-2 p-1 md:p-2 text-[12px] md:text-[13px]  w-full">

                        <div className='flex flex-row items-center content-center justify-start gap-1 md:gap-2'>
                            <h1 className="font-[500] text-gray-900">
                                Location</h1>
                            <span className='text-gray-800 text-[12px] md:text-[14px]'><BsPinMap /></span>
                        </div>

                        <input
                            className="p-1 md:p-2  border-b bg-none outline-none  placeholder:text-gray-400 placeholder:text-[11px] placeholder:pl-4  w-full md:min-w-[320px] lg:min-w-[400px] h-[45px] md:h-[50px]  "
                            type="text"
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                            placeholder="Location"
                        />
                    </div>
                    {/* Venue */}
                    <div className="flex flex-col  justify-start content-start  items-start gap-1 md:gap-2 p-1 md:p-2 text-[12px] md:text-[13px]  w-full">

                        <div className='flex flex-row items-center content-center justify-start gap-1 md:gap-2'>
                            <h1 className="font-[500] text-gray-900">
                                Venue</h1>
                            <span className='text-gray-800 text-[12px] md:text-[14px]'><BsHouse /></span>
                        </div>

                        <input
                            className="p-1 md:p-2  border-b bg-none outline-none  placeholder:text-gray-400 placeholder:text-[11px] placeholder:pl-4  w-full md:min-w-[320px] lg:min-w-[400px] h-[45px] md:h-[50px]  "
                            type="text"
                            value={venue}
                            onChange={(e) => setVenue(e.target.value)}
                            placeholder="Venue"
                        />
                       
                    </div>

                    {/* Dates */}
                    <div className='flex flex-row  justify-start content-start  items-start gap-1 md:gap-2 p-1 md:p-2 text-[12px] md:text-[13px]  w-full'>
                        {/* start Date */}
                        <div className="flex flex-col  justify-start content-start  items-start gap-1 md:gap-2 p-1 md:p-2 text-[12px] md:text-[13px]  w-full">

                            <div className='flex flex-row items-center content-center justify-start gap-1 md:gap-2'>
                                <h1 className="font-[500] text-gray-900">
                                    Start Date</h1>
                                <span className='text-gray-800 text-[12px] md:text-[14px]'><BsCalendar2Check /></span>
                            </div>

                            <input
                                className="p-1 md:p-2  border-b bg-none outline-none  placeholder:text-gray-400 placeholder:text-[11px] placeholder:pl-4  w-full  h-[45px] md:h-[50px]  "
                                type="date"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                placeholder="YYYY-MM-DD"
                            />

                            
                        </div>


                        {/* end Date */}
                        <div className="flex flex-col  justify-start content-start  items-start gap-1 md:gap-2 p-1 md:p-2 text-[12px] md:text-[13px]  w-full">

                            <div className='flex flex-row items-center content-center justify-end gap-1 md:gap-2'>
                                <h1 className="font-[500] text-gray-900">
                                    End Date</h1>
                                <span className='text-gray-800 text-[12px] md:text-[14px]'><BsCalendar2X /></span>
                            </div>

                            <input
                                className="p-1 md:p-2  border-b bg-none outline-none  placeholder:text-gray-400 placeholder:text-[11px] placeholder:pl-4  w-full  h-[45px] md:h-[50px]  "
                                type="date"
                                format="YYYY-MM-DD"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                placeholder="YYYY-MM-DD"
                            />
                        </div>





                    </div>



                    {/*Description */}
                    <div className="flex flex-col  justify-start content-start  items-start gap-1 md:gap-2 p-1 md:p-2 text-[12px] md:text-[13px]  w-full">
                        <div className='flex flex-row items-center content-center justify-start gap-1 md:gap-2'>
                            <h1 className="font-[500] text-gray-900">
                                Description</h1>
                            <span className='text-gray-800 text-[12px] md:text-[14px]'><BsParagraph /></span>
                        </div>
                        <textarea
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder="Content"
                            className="p-1 md:p-2  border-b bg-none outline-none  placeholder:text-gray-400 placeholder:text-[11px] placeholder:pl-4  w-full md:min-w-[320px] lg:min-w-[400px] h-[45px] md:h-[50px]  "
                        ></textarea>
                    </div>




                    {error && <Message type="error">{message}</Message>}
                    {success && <Message type="success">{message}</Message>}



                    <div className="flex flex-row w-full justify-center items-center content-center    p-1 md:p-2 ">
                        {loading ? (
                             <div
                         
                             className=" flex flex-row items-center content-center justify-center text-center animate-pulse bg-blue-400  gap-1 md:gap-2 w-full p-1 md:p-2 h-[40px] md:h-[50px] text-[12px] md:text-[14px] text-white rounded-lg"
                         >
                             <h1>Adding Post</h1>
                             
                         </div>

                        ) : (
                            <button
                            type="submit"
                            className=" flex flex-row items-center content-center justify-center bg-blue-600 hover:bg-blue-500 gap-1 md:gap-2 w-full p-1 md:p-2 h-[40px] md:h-[50px] text-[12px] md:text-[14px] text-white rounded-lg"
                        >
                            <h1>Add Post</h1>
                            <BsUpload />
                        </button>

                        )}

                       
                    </div>



                </div>















            </form>
        </div>
    )
}

export default CreateEvent