import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { motion } from "framer-motion";
import { BsCalendarPlus } from "react-icons/bs";
import AdminSearch from "../navigation/AdminSearch";
import { POST_CREATE_RESET } from "../../../features/constants/postConstants";
import { createPost, listPosts } from "../../../features/actions/postActions";
import DashPostItem from "./DashPostItem";
import Paginate from "../navigation/Paginate";
import PostLoading from "../../loading/PostLoading";
import PostItem from "../../posts/PostItem";

function DashPostList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const postList = useSelector((state) => state.postList);

  const { posts, page, pages, loading, error } = postList;

  const postCreate = useSelector((state) => state.postCreate);
  const {
    post: createdPost,
    success: successCreate,
    loading: loadingCreate,
    error: errorCreate,
  } = postCreate;


  let keyword =
    location.search; /* IF USER SEARCHES FOR ANYTHING THEN THIS KEYWORD CHANGES AND USE EFFECT GETS TRIGGERED */

  return (
    <motion.div className=" p-1 md:p-2   flex flex-col gap-3 justify-center content-center items-center text-black  mx-auto bg-white mt-[100px] ">

      <div className='w-full flex flex-row items-end justify-end gap-2 md:gap-3'>
        <motion.button
          whileHover={{ scale: 1.05 }}
          onClick={() => navigate('/dashboard/posts/create')}
          className="flex flex-row items-center content-center justify-center gap-1 md:gap-2 bg-gray-100 border hover:bg-gray-200  rounded-full min-w-[120px] text-sm p-2 md:p-2">
          Add Posts

          <BsCalendarPlus />
        </motion.button>



      </div>

      <div className="w-full">
        <AdminSearch />

      </div>




      {/* <Search /> */}

      {/* center items */}
      {/* center items */}
      {loading ? <PostLoading /> : ""}

      {(posts === 0) & !loading ? (
        <div className="flex flex-col  justify-center items-center content-center w-full">
          <h1 className="text-gray-500 text-md md:text-xl">No Posts Found</h1>
        </div>
      ) : (
        <div className="flex flex-col justify-center content-center items-center gap-2 md:gap-3   w-full md:min-w-[400px]">
          {posts?.map((post) => (
            <PostItem key={post._id} post={post} />
          ))}
        </div>
      )}

      {/* pagination */}
      <div className="p-2 md:p-3 mt-[20px]">
        <Paginate page={page} pages={pages} keyword={keyword} route="/dashboard/posts" />
      </div>




    </motion.div>
  )
}

export default DashPostList;
