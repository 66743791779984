
import { votePoll } from '../../../features/actions/pollActions';
import { useDispatch, useSelector } from 'react-redux';
import { AiFillCheckCircle } from 'react-icons/ai';
import { useLocation } from 'react-router';


const formatNumber = (num) => {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + 'M'; // 1.1M
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1) + 'k'; // 1.2k
    } else {
      return num;
    }
  };


  // Utility function to format percentages
const formatPercentage = (percentage) => {
    return percentage > 0 ? `${percentage.toFixed(1)}%` : '0%';
  };
  


function FeedPollItem({choices,totalVotes}) {

    
const dispatch = useDispatch();

const location = useLocation();


const pollUpdate = useSelector((state) => state.pollUpdate);

const { loading: pollLoading, success:pollSuccess, error: pollError } = pollUpdate;



let keyword =
location.search; 


const handleVote = (id) => {
    (dispatch(votePoll(id)))
  };




// useEffect(()=> {

//  if(pollSuccess){
    
//     dispatch(listPosts(keyword))
//  } else {
//     return;
//  }


// }, [dispatch,keyword,pollSuccess])

 



  

  return (
     <div className="w-full">
              <div>
                <ul>
                  {choices?.map((choice) => {
                    // Calculate percentage for the current choice
                    const percentage = totalVotes > 0 ? (choice.votes / totalVotes) * 100 : 0;

                    return (
                      <li key={choice._id}>
                        <div className="flex flex-row items-center content-center justify-between gap-2">
                          <h1 className="text-[12px] font-[500] text-gray-600">{choice.choice_text}</h1>
                          <div className="rounded-lg h-[30px] text-center text-[14px] flex flex-row items-center content-center justify-center">
                            <p className="text-[12px] text-gray-500">{formatPercentage(percentage)}{""}(<span>{formatNumber(choice.votes)}</span>)</p>
                          </div>

                          <div
                            onClick={() => handleVote(choice._id)}
                            className="flex flex-row items-center content-center justify-center gap-1 text-gray-600 cursor-pointer hover:text-gray-900"
                          >
                            <h1 className="text-[12px]">Vote</h1>
                            <AiFillCheckCircle />
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
  )
}

export default FeedPollItem