// export const REACT_APP_URL = "";
// export const REACT_API_URL = "";


export const REACT_APP_URL = "/api";
export const REACT_API_URL = "/api";



// export const REACT_APP_URL = "https://www.genz254.africa";
// export const REACT_API_URL = "https://www.genz254.africa";


// export const REACT_APP_URL = "http://127.0.0.1:8000/api";
// export const REACT_API_URL = "http://127.0.0.1:8000/api";


export const modifyImageUrl = (url) => {
    //check if th url  already contains '/api/'
    if(url.includes('/api/')){
        return url;
    }

    if(!url){
        return '/placeholder.jpg';
    }

    // modify the url
    return url.replace('/media/', '/api/media/')
}

export const  getCookie =(name) => {
    let cookieValue = null;
  
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
  
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
  
                break;
            }
        }
    };
  
  }



export const paypalClientID = process.env.REACT_APP_PAYPAL_CLIENT;
