

// add post to QUEUE 
export const QUEUE_ADD_POST_ITEM = "QUEUE_ADD_POST_ITEM";
export const QUEUE_REMOVE_POST_ITEM = "QUEUE_REMOVE_POST_ITEM";
export const QUEUE_CLEAR_POST_ITEM = "QUEUE_CLEAR_POST_ITEM";



// add EVENT to QUEUE 
export const QUEUE_ADD_EVENT_ITEM = "QUEUE_ADD_EVENT_ITEM";
export const QUEUE_REMOVE_EVENT_ITEM = "QUEUE_REMOVE_EVENT_ITEM";
export const QUEUE_CLEAR_EVENT_ITEM = "QUEUE_CLEAR_EVENT_ITEM";


// add DONATION to QUEUE 
export const QUEUE_ADD_DONATION_ITEM = "QUEUE_ADD_DONATION_ITEM";
export const QUEUE_REMOVE_DONATION_ITEM = "QUEUE_REMOVE_DONATION_ITEM";
export const QUEUE_CLEAR_DONATION_ITEM = "QUEUE_CLEAR_DONATION_ITEM";