import React from 'react'
import { BiLoader } from 'react-icons/bi'

function Loading() {
    return (
        <div className=" h-auto flex flex-row items-center content-center justify-center w-full gap-1 md:gap-2  text-[12px] md:text-[14px]">
            <BiLoader className="animate-spin" />
            <h1 className="text-gray-500 ">Loading...</h1>
        </div>
    )
}

export default Loading