import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { listMyEvents } from '../../features/actions/eventActions';
import EventItem from '../events/EventItem';
import EventLoading from '../loading/EventLoading';

function MyEvents() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [choices, SetChoices] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const eventList = useSelector((state) => state.eventList);
  const { events, page, pages, loading, error } = eventList;

  const eventLike = useSelector((state) => state.eventLike);
  const { success: likeSuccess } = eventLike;

  const eventUnLike = useSelector((state) => state.eventUnLike);
  const { success: unlikeSuccess } = eventUnLike;

  const { search } = useLocation();
  let keyword = location.search;

  // Check for network connectivity
  useEffect(() => {
    const updateOnlineStatus = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, []);

  // Fetch events only if online
  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    } else if (isOnline) {
      dispatch(listMyEvents(keyword));
    }
  }, [dispatch, keyword, userInfo, navigate, isOnline]);

  useEffect(() => {
    if (likeSuccess || unlikeSuccess) {
      dispatch(listMyEvents(keyword));
    }
  }, [dispatch, likeSuccess, unlikeSuccess, keyword]);

  // Error or UserInfo Check
  if (!userInfo || error) {
    return (
      <div className="flex flex-col justify-center items-center min-h-[60vh]">
        <h1 className="text-gray-500 text-md md:text-xl">
          {error ? "Something went wrong" : "Please log in to view your events"}
        </h1>
      </div>
    );
  }

  // Offline Handling
  if (!isOnline) {
    return (
      <div className="flex flex-col justify-center items-center min-h-[60vh]">
        <h1 className="text-gray-500 text-md md:text-xl">You are offline. Please check your network connection.</h1>
      </div>
    );
  }

  return (
    <div className="p-1 md:p-2 flex flex-col gap-3 justify-center content-center items-center text-black mx-auto bg-white w-full min-h-[60vh]">
      {loading ? (
        <div className="flex flex-col justify-center content-center items-center gap-3 md:gap-4 w-full">
          <EventLoading />
        </div>
      ) : (
        ""
      )}

      {events?.length === 0 && !loading ? (
        <div className="flex flex-col justify-center items-center content-center w-full">
          <h1 className="text-gray-500 text-md md:text-xl">No Events Found</h1>
        </div>
      ) : (
        <div className="flex flex-col justify-center content-center items-center gap-3 md:gap-4 w-full">
          {events?.map((event) => (
            <EventItem key={event._id} event={event} />
          ))}
        </div>
      )}
    </div>
  );
}

export default MyEvents;
