import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo || !userInfo.token) {
      // Redirect to login page if user is not authenticated
      navigate("/login", { replace: true, state: { from: location } });
    }
  }, [userInfo, navigate, location]);

  // Only render children if authenticated
  return userInfo && userInfo.token ? children : null;
};

export default PrivateRoute;
