import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { AiFillMail, AiOutlineMail } from 'react-icons/ai';
import Message from '../../../components/message/Message';
import { CancelAuthActions, changeEmail } from '../../../features/actions/AuthActions';

import { getUserDetails } from '../../../features/actions/userActions';

function ChangeEmailPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");

    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;


    const emailChange = useSelector((state) => state.emailChange);
    const { message ,invalid, error, loading, success } = emailChange;



    useEffect(() => {
        if (success) {
            dispatch(getUserDetails("profile"));
            navigate('/account/email/verify')
           
        }
    }, [navigate,dispatch, success])



    const Cancel = (e) => {
        e.preventDefault();
        

        dispatch(CancelAuthActions())


        navigate('/account/edit')
    }


    const handleEmailChange = (e) => {
        e.preventDefault();
        dispatch(changeEmail(email));
       
 };



    
    return (
        <div className="">
            <main className="min-h-[500px] ">
                <div className="subpixel-antialiased  bg-hero-cover bg-no-repeat bg-center bg-cover  min-h-screen flex flex-row justify-around items-center font-sans    mx-auto lg:justify-around lg:align-center lg:text-centen  p-3 lg:p-4">

                    <div className='flex flex-col justify-center content-center items-center w-full'>





                        <div className='text-[1rem] md:text-[1.4rem] font-[500] flex flex-row items-center content-center justify-center gap-1 md:gap-2 mb-[20px]'>
                            <h1>New Email</h1>
                            <AiFillMail />

                        </div>
                        {/* central div */}
                        <form
                            onSubmit={handleEmailChange}
                            className='w-full md:w-[40vw] flex flex-col  justify-start content-start  items-start gap-1 md:gap-2 p-1 md:p-2'>


                            {/* new password */}
                            <div className="flex flex-col  justify-start content-start  items-start gap-1 md:gap-2 p-1 md:p-2 text-[12px] md:text-[13px]  w-full ">
                                <div className='flex flex-row items-center content-center justify-start gap-1 md:gap-2 mb-[10px]'>


                                    <h1 className="font-[500] text-gray-900">
                                        Your New Email</h1>
                                    <span className='text-gray-800 text-[12px] md:text-[14px]'>
                                        <AiOutlineMail />
                                    </span>



                                </div>

                                <input
                                    className="p-1 md:p-2  border-b bg-none  outline-none  placeholder:text-gray-400 placeholder:text-[11px] placeholder:pl-4  w-full md:min-w-full lg:min-w-full h-[50px] md:h-[60px] text-[13px]  font-[600] "
                                    type="email"
                                    required
                                    autoComplete="false"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="New Email"
                                />


                            </div>



                            {/* hanlding error */}
                            {invalid === true ? (
                                <div className='w-full'>
                                    <Message type="error">{error?.detail}</Message>
                                </div>
                            ) : ('')}
                            {error  ? (
                                <div className='w-full'>
                                    <Message type="error">{error}</Message>
                                </div>
                            ) : ('')}





                            <div className='flex flex-row justify-between w-full items-center content-center p-1 md:p-2'>
                                {/* Cancel    */}

                                <div
                                    onClick={Cancel}
                                    className='flex flex-row  items-center justify-center content-center gap-1  cursor-pointer  border rounded-full  text-[0.8rem] md:text-[1rem] w-[120px] p-1 text-red-500 text-center hover:bg-red-600 hover:text-white hover:border-red-600'>

                                    <h1>Cancel</h1>
                                </div>




                               

                            </div>




                            {loading ? (
                                <button

                                    className="p-2  bg-none outline-none rounded-xl bg-gray-200 text-gray-400  w-full text-[11px] md:text-[14px] h-[60px] m-1  animate-pulse"
                                >
                                    Changing Email
                                </button>
                            ) : (
                                <button
                                    type="submit"
                                    className="p-2  bg-none outline-none rounded-xl bg-blue-600 text-white  hover:bg-blue-700  w-full h-[60px] text-[11px] md:text-[14px]  m-1 drop-shadow-lg shadow-blue-500/50 "
                                >
                                    Change Email
                                </button>

                            )}














                        </form>

                    </div>
                </div>
            </main>
        </div>
    )
}

export default ChangeEmailPage