import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const AdminRoute = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo || !userInfo.is_staff) {
      // Redirect to login if not an admin
      navigate("/login", { replace: true, state: { from: location } });
    }
  }, [userInfo, navigate, location]);

  // Render the children only if the user is an admin
  return userInfo && userInfo.is_staff === true ? children : null;
};

export default AdminRoute;
