import React, { useEffect, useState } from "react";
import { AiOutlineSend, AiOutlineShareAlt } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { createComment, listComments } from "../../features/actions/commentActions";
import Message from "../message/Message";
import { BsSendArrowUp } from "react-icons/bs";
import { listPostDetails } from "../../features/actions/postActions";
import Loading from "../loading/Loading";

function CommentForm({ postId }) {
  const [content, setContent] = useState("");
  const [message, setMessage] = useState(false);
  const [warning, setWarning] = useState("");
  const dispatch = useDispatch();

  const commentCreate = useSelector((state) => state.commentCreate);
  const { loading, success, error } = commentCreate;


  useEffect(() => {
    if (success) {
      setContent("");
      dispatch(listPostDetails(postId));
      dispatch(listComments(postId));
    }
  }, [success, dispatch, postId]);

  

  const handleSubmit = (e) => {
    e.preventDefault();

    if (content === "") {
      setWarning("Can't add  Empty Comment");
      setMessage(true);
    } else {
      dispatch(
        createComment({
          post: postId,
         
          content,
        })
      );

      setContent("");
    }
  };

  return (
    <div className="w-full flex flex-col gap-2 md:gap-3 p-1 md:p-2 ">
      {loading ? (
        <div className="flex flex-col justify-center items-center content-center text-center    p-2 md:p-3  w-[100%]">
        <Loading />
      </div>
      ) : (
        <div className="w-full flex flex-col gap-1 md:gap-2">
    
    <form
            onSubmit={handleSubmit}
            className="flex flex-col items-start content-start justify-center gap-2 md:gap-3 "
          >
            <textarea
              placeholder="Write your comment here"
              onChange={(e) => setContent(e.target.value)}
              className="bg-gray-50 border-t border-b border-gray-200  w-full min-h-[100px] outline-none placeholder:text-[11px] p-1 placeholder:md:text-[12px] placeholder:text-gray-500  text-[12px] md:text-[14px]"
            ></textarea>

            {content !== "" && (
              <button
                type="submit"
                className=" border  min-w-[100px] rounded-full p-2 hover:bg-black hover:border-black hover:text-white border-gray-200 text-gray-500 flex flex-row gap-1 items-center content-center justify-center text-[11px] md:text-[12px] self-end"
              >
                comment
                <BsSendArrowUp className="text-green-600" />
              </button>
            )}
          </form>
        </div>
      )}

      {success ? <Message type="succes">"Comment Added"</Message> :""}
      {message == true ? <Message type="error">{warning}</Message> : ""}
      {error && <Message type="error">{error}</Message>}
    </div>
  );
}

export default CommentForm;
