import React from 'react'

function EventLoading() {
  return (
    <div className="flex flex-col  justify-center content-center items-center gap-3 md:gap-4 w-[80vw] md:w-[80vw]  ">
      <div className="flex flex-col items-start justify-center gap-2 animate-pulse min-w-full  h-[250px]   ">
        <div className="bg-gray-200 rounded-lg w-full h-[220px]"></div>
        <div className="bg-gray-200 rounded-lg  w-full h-[30px] "></div>

      </div>
      <div className="flex flex-col items-start justify-center gap-2 animate-pulse min-w-full  h-[250px]   ">
        <div className="bg-gray-200 rounded-lg w-full h-[220px]"></div>
        <div className="bg-gray-200 rounded-lg  w-full h-[30px] "></div>

      </div>
   


    </div>
  )
}

export default EventLoading