import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { listMyDonations } from '../../features/actions/donationsActions';
import Paginate from '../dashboard/navigation/Paginate';
import DonateItem from '../donations/DonateItem';
import DonationLoading from '../loading/DonationLoading';

function MyDonations() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const donationMyList = useSelector((state) => state.donationMyList);
  const { donations, page, pages, loading, error } = donationMyList;

  const donationLike = useSelector((state) => state.donationLike);
  const { success: likeSuccess } = donationLike;

  const donationUnLike = useSelector((state) => state.donationUnLike);
  const { success: unlikeSuccess } = donationUnLike;

  const { search } = useLocation();
  let keyword = search;

  // Check for network connectivity
  useEffect(() => {
    const updateOnlineStatus = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, []);

  // Fetch donations only if online
  useEffect(() => {
    if (isOnline) {
      dispatch(listMyDonations(keyword));
    }
  }, [dispatch, keyword, isOnline]);

  useEffect(() => {
    if (likeSuccess || unlikeSuccess) {
      dispatch(listMyDonations(keyword));
    }
  }, [dispatch, likeSuccess, unlikeSuccess, keyword]);

  // Error or UserInfo Check
  if (error) {
    return (
      <div className="flex flex-col justify-center items-center min-h-[60vh]">
        <h1 className="text-gray-500 text-md md:text-xl">Something went wrong</h1>
      </div>
    );
  }

  // Offline Handling
  if (!isOnline) {
    return (
      <div className="flex flex-col justify-center items-center min-h-[60vh]">
        <h1 className="text-gray-500 text-md md:text-xl">You are offline. Please check your network connection.</h1>
      </div>
    );
  }

  return (
    <div className="p-1 md:p-2 flex flex-col gap-3 justify-center content-center items-center text-black mx-auto bg-white w-full min-h-[60vh]">
      {loading ? (
        <div className="flex flex-col justify-center content-center items-center gap-3 md:gap-4 w-full">
          <DonationLoading />
        </div>
      ) : (
        ""
      )}

      {donations?.length === 0 && !loading ? (
        <div className="flex flex-col justify-center items-center content-center w-full">
          <h1 className="text-gray-500 text-md md:text-xl">No Donations Found</h1>
        </div>
      ) : (
        <div className="flex flex-col justify-center content-center items-center gap-3 md:gap-4 w-full">
          {donations?.map((donation) => (
            <DonateItem key={donation._id} donation={donation} />
          ))}
        </div>
      )}

      {/* Pagination */}
      <div className="p-2 md:p-3 mt-[20px]">
        <Paginate page={page} pages={pages} keyword={keyword} route="/donations" />
      </div>
    </div>
  );
}

export default MyDonations;
