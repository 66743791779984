
// CREDENTIALS CHECK CONSTANTS
export const CREDENTIALS_CHECK_REQUEST = "CREDENTIALS_CHECK_REQUEST";
export const CREDENTIALS_CHECK_SUCCESS = "CREDENTIALS_CHECK_SUCCESS";
export const CREDENTIALS_CHECK_ERROR = "CREDENTIALS_CHECK_ERROR";
export const CREDENTIALS_CHECK_FAIL = "CREDENTIALS_CHECK_FAIL";
export const CREDENTIALS_CHECK_RESETS = "CREDENTIALS_CHECK_RESET";



// EMAIL AVAILABILITY CHECK  CONSTANTS
export const EMAIL_AVAILABILITY_CHECK_REQUEST = "EMAIL_AVAILABILITY_CHECK_REQUEST";
export const EMAIL_AVAILABILITY_CHECK_SUCCESS = "EMAIL_AVAILABILITY_CHECK_SUCCESS";
export const EMAIL_AVAILABILITY_CHECK_ERROR = "EMAIL_AVAILABILITY_CHECK_ERROR";
export const EMAIL_AVAILABILITY_CHECK_FAIL = "EMAIL_AVAILABILITY_CHECK_FAIL";
export const EMAIL_AVAILABILITY_CHECK_RESETS = "EMAIL_AVAILABILITY_CHECK_RESETS";


// USERNAME AVAILABILITY CHECK  CONSTANTS
export const USERNAME_AVAILABILITY_CHECK_REQUEST = "USERNAME_AVAILABILITY_CHECK_REQUEST";
export const USERNAME_AVAILABILITY_CHECK_SUCCESS = "USERNAME_AVAILABILITY_CHECK_SUCCESS";
export const USERNAME_AVAILABILITY_CHECK_ERROR = "USERNAME_AVAILABILITY_CHECK_ERROR";
export const USERNAME_AVAILABILITY_CHECK_FAIL = "USERNAME_AVAILABILITY_CHECK_FAIL";
export const USERNAME_AVAILABILITY_CHECK_RESETS = "USERNAME_AVAILABILITY_CHECK_RESETS";


// EMAIL TOKEN RESEND  CONSTANTS
export const EMAIL_TOKEN_RESEND_REQUEST = "EMAIL_TOKEN_RESEND_REQUEST";
export const EMAIL_TOKEN_RESEND_SUCCESS = "EMAIL_TOKEN_RESEND_SUCCESS";
export const EMAIL_TOKEN_RESEND_ERROR = "EMAIL_TOKEN_RESEND_ERROR";
export const EMAIL_TOKEN_RESEND_FAIL = "EMAIL_TOKEN_RESEND_FAIL";
export const EMAIL_TOKEN_RESEND_RESETS = "EMAIL_TOKEN_RESEND_RESETS";




// EMAIL TOKEN CHECK  CONSTANTS
export const EMAIL_TOKEN_CHECK_REQUEST = "EMAIL_TOKEN_CHECK_REQUEST";
export const EMAIL_TOKEN_CHECK_SUCCESS = "EMAIL_TOKEN_CHECK_SUCCESS";
export const EMAIL_TOKEN_CHECK_ERROR = "EMAIL_TOKEN_CHECK_ERROR";
export const EMAIL_TOKEN_CHECK_FAIL = "EMAIL_TOKEN_CHECK_FAIL";
export const EMAIL_TOKEN_CHECK_RESETS = "EMAIL_TOKEN_CHECK_RESET";


// PASSWORD CHANGE  CONSTANTS
export const PASSWORD_CHANGE_REQUEST = "PASSWORD_CHANGE_REQUEST";
export const PASSWORD_CHANGE_SUCCESS = "PASSWORD_CHANGE_SUCCESS";
export const PASSWORD_CHANGE_ERROR = "PASSWORD_CHANGE_ERROR";
export const PASSWORD_CHANGE_FAIL = "PASSWORD_CHANGE_FAIL";
export const PASSWORD_CHANGE_RESETS = "PASSWORD_CHANGE_RESET";



// EMAIL CHANGE  CONSTANTS
export const EMAIL_CHANGE_REQUEST = "EMAIL_CHANGE_REQUEST";
export const EMAIL_CHANGE_SUCCESS = "EMAIL_CHANGE_SUCCESS";
export const EMAIL_CHANGE_ERROR = "EMAIL_CHANGE_ERROR";
export const EMAIL_CHANGE_FAIL = "EMAIL_CHANGE_FAIL";
export const EMAIL_CHANGE_RESETS = "EMAIL_CHANGE_RESET";