/* CONSTANTS USED IN HomeScreen COMPONENT */
export const POLL_LIST_REQUEST = "POLL_LIST_REQUEST";

export const POLL_LIST_SUCCESS = "POLL_LIST_SUCCESS";

export const POLL_LIST_FAIL = "POLL_LIST_FAIL";


export const POLL_CREATE_REQUEST = "POLL_CREATE_REQUEST";

export const POLL_CREATE_SUCCESS = "POLL_CREATE_SUCCESS";

export const POLL_CREATE_FAIL = "POLL_CREATE_FAIL";

export const POLL_CREATE_RESET = "POLL_CREATE_RESET";



export const POLL_DETAILS_REQUEST = "POLL_DETAILS_REQUEST";

export const POLL_DETAILS_SUCCESS = "POLL_DETAILS_SUCCESS";

export const POLL_DETAILS_FAIL = "POLL_DETAILS_FAIL";


/* CONSTANTS USED IN FEEDScreen COMPONENT */
export const POLL_VOTE_REQUEST = "POLL_VOTE_REQUEST";

export const POST_DETAILS_SUCCESS = "POLL_VOTE_SUCCESS";

export const POLL_VOTE_FAIL = "POLL_VOTE_FAIL";

/* CONSTANTS USED IN ProductListScreen COMPONENT */
export const POLL_ADD_REQUEST = "POLL_ADD_REQUEST";

export const POLL_ADD_SUCCESS = "POLL_ADD_SUCCESS";

export const POLL_ADD_FAIL = "POLL_ADD_FAIL";


/* CONSTANTS USED IN ProductListScreen COMPONENT */
export const POLL_UPDATE_REQUEST = "POLL_UPDATE_REQUEST";

export const POLL_UPDATE_SUCCESS = "POLL_UPDATE_SUCCESS";

export const POLL_UPDATE_FAIL = "POLL_UPDATE_FAIL";
export const POLL_UPDATE_RESETS = "POLL_UPDATE_RESET";


/* CONSTANTS USED IN ProductListScreen COMPONENT */
export const POLL_DELETE_REQUEST = "POLL_DELETE_REQUEST";

export const POLL_DELETE_SUCCESS = "POLL_DELETE_SUCCESS";

export const POLL_DELETE_FAIL = "POLL_DELETE_FAIL";