import React from 'react'
import { BsChevronLeft } from 'react-icons/bs'
import { useNavigate } from 'react-router';
import CreateEvent from '../../components/events/create/CreateEvent';

function CreateEventPage() {
     const navigate = useNavigate();
  return (
    <div className="subpixel-antialiased min-h-screen inset-0 flex flex-col justify-center items-center content-center font-sans    mx-auto  lg:align-center lg:text-centen rounded-b-2xl p-3 mt-[10px] lg:mt-[50px] w-full">
    {/* central div */}


    <div className="flex flex-col justify-start items-start content-center gap-3 md:gap-5 p-2 md:p-3  h-full w-[95%] md:w-[80%] lg:w-[80%]">
        {/* back button */}
        <div
            onClick={() => navigate(-1)}
            className=" flex flex-row items-center content-center justify-center text-gray-900 hover:text-black w-auto rounded-full p-1 md:p-2 gap-1 md:gap-2 cursor-pointer text-[12px] md:text-[15px] mb-[10px]"
        >
            <BsChevronLeft />
            <h1 className='font-[500]'>Back</h1>
        </div>


    {/* Options */}

        <CreateEvent />










    </div>

</div>
  )
}

export default CreateEventPage