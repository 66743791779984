import axios from "axios";
import { REACT_API_URL, REACT_APP_URL } from "../../utilities/utils";
import {
    DONATION_COMMENT_CREATE_FAIL,
    DONATION_COMMENT_CREATE_REQUEST,
    DONATION_COMMENT_CREATE_SUCCESS,
    DONATION_COMMENT_DELETE_FAIL,
    DONATION_COMMENT_DELETE_REQUEST,
    DONATION_COMMENT_DELETE_SUCCESS,
    DONATION_COMMENT_DETAILS_SUCCESS,
    DONATION_COMMENT_LIST_FAIL,
    DONATION_COMMENT_LIST_REQUEST,
    DONATION_COMMENT_LIST_SUCCESS,
    DONATION_COMMENT_UPDATE_FAIL,
    DONATION_COMMENT_UPDATE_REQUEST,
    DONATION_COMMENT_UPDATE_SUCCESS
} from "../constants/donationCommentConstants";


/* ACTION CREATOR USED IN HomeScreen COMPONENT */
export const listDonationComments = (id) => async (dispatch) => {
    try {
        dispatch({
            type: DONATION_COMMENT_LIST_REQUEST,
        });

        const { data } = await axios.get(`${REACT_API_URL}/donations/comments/${id}/`);

        dispatch({
            type: DONATION_COMMENT_LIST_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: DONATION_COMMENT_LIST_FAIL,
            payload:
                error.response && error.response.data.detail
                    ? error.response.data.detail
                    : error.message,
        });
    }
};

export const deleteDonationComment = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: DONATION_COMMENT_DELETE_REQUEST,
        });

        // PULLING OUT THE CURRENT USER WE ARE LOGGED IN AS
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axios.delete(
            `${REACT_APP_URL}/donations/comments/${id}/delete/`,
            config
        );

        dispatch({
            type: DONATION_COMMENT_DELETE_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: DONATION_COMMENT_DELETE_FAIL,
            payload:
                error.response && error.response.data.detail
                    ? error.response.data.detail
                    : error.message,
        });
    }
};

export const createDonationComment = (comment) => async (dispatch, getState) => {
    try {
        dispatch({
            type: DONATION_COMMENT_CREATE_REQUEST,
        });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        /* MAKING API CALL TO CREATE PRODUCT */
        const { data } = await axios.post(
            `${REACT_APP_URL}/donations/${comment.donation}/comments/create/`,
            comment,
            config
        );

        dispatch({
            type: DONATION_COMMENT_CREATE_SUCCESS,

            payload: data,
        });

    } catch (error) {
        dispatch({
            type: DONATION_COMMENT_CREATE_FAIL,
            payload:
                error.response && error.response.data.detail
                    ? error.response.data.detail
                    : error.message,
        });
    }
};

export const updateDonationComment = (comment) => async (dispatch, getState) => {
    try {
        dispatch({
            type: DONATION_COMMENT_UPDATE_REQUEST,
        });

        // PULLING OUT THE CURRENT USER WE ARE LOGGED IN AS
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        /* MAKING API CALL TO UPDATE PRODUCT */
        const { data } = await axios.put(
            `${REACT_APP_URL}/donations/comments/${comment._id}/update/`,
            comment,
            config
        );

        /* IF PUT REQUEST SUCCESSFULL WE DISPATCH & SEND THE PAYLOAD TO OUR REDUCER */
        dispatch({
            type: DONATION_COMMENT_UPDATE_SUCCESS,
            payload: data,
        });

        /* LOAD IN THE UPDATED PRODUCTS DETAILS */
        dispatch({
            type: DONATION_COMMENT_DETAILS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: DONATION_COMMENT_UPDATE_FAIL,
            payload:
                error.response && error.response.data.detail
                    ? error.response.data.detail
                    : error.message,
        });
    }
};
