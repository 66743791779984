/* CONSTANTS USED IN HomeScreen COMPONENT */
export const POST_LIST_REQUEST = "POST_LIST_REQUEST";

export const POST_LIST_SUCCESS = "POST_LIST_SUCCESS";

export const POST_LIST_FAIL = "POST_LIST_FAIL";

/* CONSTANTS USED IN FEEDScreen COMPONENT */
export const POST_DETAILS_REQUEST = "POST_DETAILS_REQUEST";

export const POST_DETAILS_SUCCESS = "POST_DETAILS_SUCCESS";

export const POST_DETAILS_FAIL = "POST_DETAILS_FAIL";

/* CONSTANTS USED IN ProductListScreen COMPONENT */
export const POST_DELETE_REQUEST = "POST_DELETE_REQUEST";

export const POST_DELETE_SUCCESS = "POST_DELETE_SUCCESS";

export const POST_DELETE_FAIL = "POST_DELETE_FAIL";

export const POST_DELETE_RESET = "POST_DELETE_RESET";

/* CONSTANTS USED IN ProductListScreen COMPONENT */
export const POST_CREATE_REQUEST = "POST_CREATE_REQUEST";

export const POST_CREATE_SUCCESS = "POST_CREATE_SUCCESS";

export const POST_CREATE_FAIL = "POST_CREATE_FAIL";

export const POST_CREATE_RESET = "POST_CREATE_RESET";

/* CONSTANTS USED IN ProductEditScreen COMPONENT */
export const POST_UPDATE_REQUEST = "POST_UPDATE_REQUEST";

export const POST_UPDATE_SUCCESS = "POST_UPDATE_SUCCESS";

export const POST_UPDATE_FAIL = "POST_UPDATE_FAIL";

export const POST_UPDATE_RESET = "POST_UPDATE_RESET";

/* CONSTANTS USED IN ProductScreen COMPONENT */
export const POST_CREATE_COMMENT_REQUEST = "POST_CREATE_COMMENT_REQUEST";

export const POST_CREATE_COMMENT_SUCCESS = "POST_CREATE_COMMENT_SUCCESS";

export const POST_CREATE_COMMENT_FAIL = "POST_CREATE_COMMENT_FAIL";

export const POST_CREATE_COMMENT_RESET = "PPOST_CREATE_COMMENT_RESET";



/* POST LIK  */
export const POST_LIKE_REQUEST = "POST_LIKE_REQUEST";

export const POST_LIKE_SUCCESS = "POST_LIKE_SUCCESS";

export const POST_LIKE_FAIL = "POST_LIKE_FAIL";

export const POST_LIKE_RESET = "POST_LIKE_RESET";



/* POST UNLIK  */
export const POST_UNLIKE_REQUEST = "POST_UNLIKE_REQUEST";

export const POST_UNLIKE_SUCCESS = "POST_UNLIKE_SUCCESS";

export const POST_UNLIKE_FAIL = "POST_UNLIKE_FAIL";

export const POST_UNLIKE_RESET = "POST_UNLIKE_RESET";

