import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import { motion } from 'framer-motion';
import { CgCalendar } from 'react-icons/cg';
import { BsCalendarPlus } from 'react-icons/bs';
import { DONATION_CREATE_RESET } from '../../../features/constants/donationConstants';
import { createDonation, listDonations } from '../../../features/actions/donationsActions';
import DashEventItem from '../events/DashEventItem';
import DashDonationItem from './DashDonationItem';
import Search from '../../search/Search';
import { HiCurrencyDollar } from "react-icons/hi";
import AdminSearch from '../navigation/AdminSearch';
import Paginate from '../navigation/Paginate';
import DonationLoading from '../../loading/DonationLoading';
import DonateItem from '../../donations/DonateItem';




function DashDonationList() {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation();


  const donationList = useSelector((state) => state.donationList);

  const { donations, page, pages, loading, error } = donationList;



  const donationCreate = useSelector((state) => state.donationCreate);
  const {
    donation: createdDonation,
    success: successCreate,
    loading: loadingCreate,
    error: errorCreate,
  } = donationCreate;


  let keyword =
    location.search; /* IF USER SEARCHES FOR ANYTHING THEN THIS KEYWORD CHANGES AND USE EFFECT GETS TRIGGERED */

  useEffect(() => {
    dispatch({ type: DONATION_CREATE_RESET })

    if (successCreate) {
      navigate(`/dashboard/donate/${createdDonation._id}/edit`)
    } else {
      dispatch(listDonations(keyword));

    }




  }, [dispatch, navigate,
    successCreate, createdDonation, keyword]);



  


  return (
    <motion.div className=" p-1 md:p-2   flex flex-col gap-3 justify-center content-center items-center text-black  mx-auto bg-white mt-[100px]">

      <div className='w-full flex flex-row items-end justify-end'>


      </div>

      <div className="w-full flex flex-col items-center content-center justify-center">
        <AdminSearch />

      </div>




      {/* <Search /> */}

      {/* center items */}



      {/* center items */}
      {loading ? <div className="flex flex-col  justify-center content-center items-center gap-3 md:gap-4  w-full ">
        <DonationLoading />
      </div>
        : ""}









      {donations === 0 & !loading ? (
        <div className="flex flex-col  justify-center items-center content-center w-full">
          <h1 className="text-gray-500 text-md md:text-xl">No Donations Found</h1>
        </div>


      ) : (
        <div className="flex flex-col  justify-center content-center items-center gap-3 md:gap-4  w-full ">
          {donations?.map((donation) => (
            <DonateItem key={donation._id} donation={donation} />
          )
          )}



        </div>

      )}
      {/* pagination */}
      <Paginate
        page={page}
        pages={pages}
        keyword={keyword}
        route="/dashboard/donate"
      />




    </motion.div>
  )
}

export default DashDonationList