import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import { createEvent, listEvents } from '../../../features/actions/eventActions';
import DashEventItem from './DashEventItem';
import { motion } from 'framer-motion';
import { EVENT_CREATE_RESET } from '../../../features/constants/eventCostants';
import AdminSearch from '../navigation/AdminSearch';
import Paginate from '../navigation/Paginate';
import EventLoading from '../../loading/EventLoading';
import EventItem from '../../events/EventItem';

function DashEventList() {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation();


  const eventList = useSelector((state) => state.eventList);

  const { events, page, pages, loading, error } = eventList;



  const eventCreate = useSelector((state) => state.eventCreate);
  const {
    event: createdEvent,
    success: successCreate,
    loading: loadingCreate,
    error: errorCreate,
  } = eventCreate;


  let keyword =
    location.search; /* IF USER SEARCHES FOR ANYTHING THEN THIS KEYWORD CHANGES AND USE EFFECT GETS TRIGGERED */

  useEffect(() => {
    dispatch({ type: EVENT_CREATE_RESET })

    if (successCreate) {
      navigate(`/dashboard/events/${createdEvent._id}/edit`)
    } else {
      dispatch(listEvents(keyword));

    }




  }, [dispatch,
    successCreate, createdEvent, keyword]);



  const createEventHandler = () => {
    dispatch(createEvent());
  };




  return (
    <motion.div className=" p-1 md:p-2   flex flex-col gap-3 justify-center content-center items-center text-black  mx-auto bg-white mt-[100px]">

      <div className='w-full flex flex-row items-end justify-end'>


      </div>

      <div className="w-full flex flex-col items-center content-center justify-center">
        <AdminSearch />

      </div>







      {/* center items */}
      {loading ? <div className="flex flex-col  justify-center content-center items-center gap-3 md:gap-4  w-full ">
        <EventLoading />
      </div>
        : ""}


      {events === 0 & !loading ? (
        <div className="flex flex-col  justify-center items-center content-center w-full">
          <h1 className="text-gray-500 text-md md:text-xl">No Events Found</h1>
        </div>


      ) : (
        <div className="flex flex-col  justify-center content-center items-center gap-3 md:gap-4  w-full ">
          {events?.map((event) => (
            <EventItem key={event._id} event={event} />
          )
          )}



        </div>

      )}


      {/* pagination */}
      <Paginate
        page={page}
        pages={pages}
        keyword={keyword}
        route="/dashboard/events"
      />




    </motion.div>
  )
}

export default DashEventList