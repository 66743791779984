import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router';
import { BsSearch } from 'react-icons/bs';
import { BiSolidEraser } from 'react-icons/bi';

function Search({isSticky}) {
  const navigate = useNavigate();
  const location = useLocation();

  // STATE
  const [keyword, setKeyword] = useState(null);
  const [currentkeyword, setCurrentKeyword] = useState(null);

  const route = location.pathname;

  /* STATE */
  const handleSubmit = (e) => {
    e.preventDefault();
    navigate(`${route}?keyword=${keyword}`);
    setCurrentKeyword(keyword)
  };
  const handleClear = (e) => {
    e.preventDefault();
    setCurrentKeyword(null)
    navigate(`${route}`);
    
    
  };

  return (
    <div
     className=" flex flex-col gap-1 md:gap-2 w-[90vw] md:w-[600px]"
    >
      <div className={isSticky ? "border flex flex-row justify-center items-center content-center rounded-lg w-full    h-[50px] p-1 md:p-2 bg-[#fafafa] drop-shadow-lg" :" border flex flex-row justify-center items-center content-center rounded-lg w-full    h-[50px] p-1 md:p-2 bg-[#fafafa]"}>
        <input
          type="text"
          name="keyword"
          onChange={(e) => setKeyword(e.target.value)}
          placeholder="Search ..." j
          className="bg-inherit placeholder:text-[14px] w-[90%] outline-none"
        />


        <BsSearch onClick={(e) => handleSubmit(e)} className="text-blue-600 cursor-pointer font-[500] hover:text-blue-400" />


      </div>

      {currentkeyword ? (<h1 
      onClick={(e) => handleClear(e)}
      className='flex flex-row items-center justify-center content-center gap-1 md:gap-2 self-end bg-gray-600 p-1 text-white rounded-full text-[11px] md:text-[12px] w-[70px] hover:bg-gray-400 cursor-pointer'>
        Clear <BiSolidEraser />
      </h1>) : ""}




    </div>
  )
}

export default Search