import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { createPost } from '../../../features/actions/postActions';
import { BsArrowDown, BsEraser, BsImage, BsImageFill, BsLink, BsParagraph, BsTextarea, BsUpload } from 'react-icons/bs';
import { addToPostQueue } from '../../../features/actions/QueueActions';
import { POST_CREATE_RESET } from '../../../features/constants/postConstants';

function NormalPost() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [user, setUser] = useState("");
    const [is_poll, setPoll] = useState(false);
    const [image, setImage] = useState(null);
    const [displayImage, setDisplayImage] = useState(null);
    const [link, setLink] = useState("");

    const postQueue = useSelector((state) => state.postQueue);

    const { post } = postQueue;


    const postCreate = useSelector((state) => state.postCreate);

    const { success, loading, error } = postCreate;


    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;



    useEffect(() => {
        setUser(userInfo._id);
        if (success) {

            dispatch({type: POST_CREATE_RESET})
            
            navigate('/');

        } else {
            return
        }
    }, [userInfo,dispatch, success]);

    const submitHandler = (e) => {
        e.preventDefault();

        dispatch(
            createPost({
                title,
                user,
                // cover_image:image,
                content,
                is_poll,
                link,

            })
        );
    };



    // const submitHandler = (e) => {
    //     e.preventDefault();
    //     if (post === null) {
    //         dispatch(addToPostQueue({

    //             title,
    //             user,
    //             cover_image: null,
    //             content,
    //             is_poll,
    //             link,
    //             has_photo: false


    //         }))

    //         navigate('/feed');
    //         dispatch(createPost(post))



    //     } else {
    //         dispatch(addToPostQueue({

    //             title,
    //             user,
    //             cover_image: null,
    //             content,
    //             is_poll,
    //             link,
    //             has_photo: false


    //         }))

    //         navigate('/feed');
    //         dispatch(createPost(post))

    //     }



    // };


    // const uploadHandler = (e) => {
    //     e.preventDefault();

    //     dispatch(createPost(post))
    // };





    const handleClearImage = () => {
        setDisplayImage(null);
    }




    const handleImageSelection = (e) => {

        setImage(e.target.files[0]);
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
            const result = event.target.result;
            setDisplayImage(result);
        };

    }



    return (
        <div className="flex flex-col justify-start items-start content-start gap-2 md:gap-3  p-1 md:p-2 w-full md:w-full  min-h-[80vh] lg:min-h-auto">

            <form
                onSubmit={submitHandler}
                className='w-full md:w-[90%] flex flex-col md:flex-row justify-center content-center items-center gap-2 md:gap-4'>


                {/* image */}

                {/* <div className="flex flex-col  justify-start content-start  items-start gap-1 md:gap-2 p-1 md:p-2 text-[12px] md:text-[13px]  w-full mb-[20px]  h-full">
                    <div className='flex flex-row items-center content-center justify-start gap-1 md:gap-2 w-full'>
                        <h1 className="font-[500] text-gray-900">
                            Photo</h1>
                        <span className='text-gray-800 text-[12px] md:text-[14px]'><BsImage /></span>
                    </div>

                    {displayImage === null ? (<div className="w-full h-[250px] lg:w-full md:h-[300px] border border-gray-100 rounded-lg flex flex-col items-center justify-center content-center text-gray-500">


                        <BsImageFill className=' text-gray-300 text-[5rem]' />
                        <h1>Add Photo</h1>
                        <BsArrowDown className=' align-text-bottom text-[1.6rem] text-gray-300' />


                    </div>) : (<img src={displayImage} alt="" className="w-full h-[250px] lg:w-[50vw] md:h-[300px] rounded-xl  object-cover object-center drop-shadow-xl" />)}



                    <input
                        class="block w-full text-sm text-gray-900 bg-gray-50 rounded-md border border-gray-300 cursor-pointer focus:outline-none "
                        aria-describedby="file_input_help"
                        id="file_input"
                        type="file"
                        onChange={(e) => handleImageSelection(e)}
                    />


                    {displayImage == null ? (""
                    ) : (<div onClick={handleClearImage}
                        className='flex flex-row gap-1 md:gap-2 p-1 bg-blue-200 justify-center items-center content-center rounded-full border border-gray-300 text-[11px] md:text-[14px] w-[60px] self-end cursor-pointer hover:bg-blue-100 hover:font-[500] shadow-lg'><h1>Clear </h1><BsEraser /></div>)}


                </div>
 */}


                <div className='w-full flex flex-col  items-start justify-start content-center gap-1 md:gap-3'>
                    {/* title */}
                    <div className="flex flex-col  justify-start content-start  items-start gap-1 md:gap-2 p-1 md:p-2 text-[12px] md:text-[13px]  w-full ">
                        <div className='flex flex-row items-center content-center justify-start gap-1 md:gap-2'>
                            <h1 className="font-[500] text-gray-900">
                                Title</h1>
                            <span className='text-gray-800 text-[12px] md:text-[14px]'><BsTextarea /></span>
                        </div>

                        <input
                            className="p-1 md:p-2  border-b bg-none outline-none  placeholder:text-gray-400 placeholder:text-[11px] placeholder:pl-4  w-full md:min-w-full lg:min-w-full h-[50px] md:h-[60px] text-[13px]  font-[600] "
                            type="Title"
                            value={title || post?.title}
                            onChange={(e) => setTitle(e.target.value)}
                            required
                            placeholder="Title"
                        />
                    </div>



                    {/* link */}

                    <div className="flex flex-col  justify-start content-start  items-start gap-1 md:gap-2 p-1 md:p-2 text-[12px] md:text-[13px]  w-full">

                        <div className='flex flex-row items-center content-center justify-start gap-1 md:gap-2'>
                            <h1 className="font-[500] text-gray-900">
                                Link</h1>
                            <span className='text-gray-800 text-[12px] md:text-[14px]'><BsLink /></span>
                        </div>

                        <input
                            className="p-1 md:p-2  border-b bg-none outline-none  placeholder:text-gray-400 placeholder:text-[11px] placeholder:pl-4  w-full md:min-w-[320px] lg:min-w-[400px] h-[45px] md:h-[50px]  "
                            type="Link"
                            value={link || post?.link}
                            onChange={(e) => setLink(e.target.value)}
                            placeholder="Post Link"
                        />
                    </div>
                    {/*Description */}
                    <div className="flex flex-col  justify-start content-start  items-start gap-1 md:gap-2 p-1 md:p-2 text-[12px] md:text-[13px]  w-full">
                        <div className='flex flex-row items-center content-center justify-start gap-1 md:gap-2'>
                            <h1 className="font-[500] text-gray-900">
                                Description</h1>
                            <span className='text-gray-800 text-[12px] md:text-[14px]'><BsParagraph /></span>
                        </div>
                        <textarea
                            value={content || post?.content}
                            onChange={(e) => setContent(e.target.value)}
                            placeholder="Content"
                            className="p-1 md:p-2  border-b bg-none outline-none  placeholder:text-gray-400 placeholder:text-[11px] placeholder:pl-4  w-full md:min-w-[320px] lg:min-w-[400px] h-[45px] md:h-[50px]  "
                        ></textarea>
                    </div>



                    <div className="flex flex-row w-full justify-center items-center content-center    p-1 md:p-2 ">

                        {loading ? (
                            <div
                                
                                className=" flex flex-row items-center content-center justify-center animate-pulse bg-blue-400  gap-1 md:gap-2 w-full p-1 md:p-2 h-[40px] md:h-[50px] text-[12px] md:text-[14px] text-white rounded-lg" >
                                <h1>Adding Post</h1>

                            </div>

                        ) : (
                            <button
                                type="submit"
                                className=" flex flex-row items-center content-center justify-center bg-blue-600 hover:bg-blue-500 gap-1 md:gap-2 w-full p-1 md:p-2 h-[40px] md:h-[50px] text-[12px] md:text-[14px] text-white rounded-lg" >
                                <h1>Add Post</h1>
                                <BsUpload />
                            </button>

                        )}

                    </div>
                </div>
            </form>
        </div>
    )
}

export default NormalPost