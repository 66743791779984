import React, { useEffect, useState } from 'react'
import Message from '../../../components/message/Message'
import { BiSolidKey } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { CancelAuthActions, resendEmailToken, verifyEmail } from '../../../features/actions/AuthActions';
import { getUserDetails, recallUserDetails } from '../../../features/actions/userActions';
import { AiOutlineReload } from 'react-icons/ai';

function EmailVerifyPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [token, setToken] = useState("");
  const [retry, setRetry] = useState(0);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;


  const emailTokenResend = useSelector((state) => state.emailTokenResend);
  const { success: ResendSuccess, loading: ResendLoading, error: ResendError, invalid: ResendInvalid } = emailTokenResend;


  const emailVerify = useSelector((state) => state.emailVerify)
  const { success, loading, error, invalid } = emailVerify;



  // useEffect(() => {
  //   if (userInfo.is_email_authenticated === true) {
  //     navigate('/account')
  //   }
  // }, [navigate,userInfo])

  useEffect(() => {
    if (success) {
      dispatch(getUserDetails("profile"));
      navigate('/account');
      dispatch(CancelAuthActions());



    }

  }, [navigate, dispatch, success])



  useEffect(() => {
    if (ResendSuccess) {
      setRetry(retry + 1);



    }

  }, [navigate, dispatch, retry, ResendSuccess])



  const handleResend = (e) => {
    e.preventDefault();
    dispatch(resendEmailToken());


  }

  const handleEmailVerify = (e) => {
    e.preventDefault();
    dispatch(verifyEmail(token
    ));

  }


  return (
    <div className="">
      <main className="min-h-[500px] ">
        <div className="subpixel-antialiased  bg-hero-cover bg-no-repeat bg-center bg-cover  min-h-screen flex flex-row justify-around items-center font-sans    mx-auto lg:justify-around lg:align-center lg:text-centen  p-3 lg:p-4">

          <div className='flex flex-col justify-center content-center items-center w-full'>



            <div className='text-[1rem] md:text-[1.4rem] font-[500] flex flex-row items-center content-center justify-center gap-1 md:gap-2'>
              <h1>Verify your email</h1>
              <BiSolidKey />

            </div>
            {/* central div */}
            <form
              onSubmit={handleEmailVerify}
              className='w-full md:w-[40vw] flex flex-col  justify-start content-start  items-start gap-1 md:gap-2 p-1 md:p-2'>
              {/* token */}
              <div className="flex flex-col  justify-start content-start  items-start gap-1 md:gap-2 p-1 md:p-2 text-[12px] md:text-[13px]  w-full ">
                <div className='flex flex-row items-center content-center justify-start gap-1 md:gap-2'>
                  <h1 className="font-[500] text-gray-900">
                    Token</h1>
                  <span className='text-gray-800 text-[12px] md:text-[14px]'>
                    <BiSolidKey />
                  </span>
                </div>

                <input
                  className="p-1 md:p-2  border-b bg-none  outline-none  placeholder:text-gray-400 placeholder:text-[11px] placeholder:pl-4  w-full md:min-w-full lg:min-w-full h-[50px] md:h-[60px] text-[13px]  font-[600] "
                  type="number"
                  required
                  value={token}
                  onChange={(e) => setToken(e.target.value)}
                  placeholder="Enter Token"
                />
              </div>


              <div className='w-full flex flex-row justify-end items-center content-center'>

                {ResendLoading ? (
                  <div

                    className='flex flex-row justify-center content-center items-center gap-1 md:gap-2 text-[11px] md:text-[12px] text-blue-500 animate-pulse'>

                    <h1>Resending Token</h1>



                  </div>
                ) : ("")}



                {retry > 3 & !ResendLoading ? (
                  <div

                    className='flex flex-row justify-center content-center items-center gap-1 md:gap-2 text-[11px] md:text-[12px] text-blue-600 hover:text-blue-500 cursor-pointer'>

                    <h1>Try Again Later,Too many Resends</h1>
                    <AiOutlineReload />


                  </div>
                ) : (
                  <div
                    onClick={(e) => handleResend(e)}
                    className='flex flex-row justify-center content-center items-center gap-1 md:gap-2 text-[11px] md:text-[12px] text-blue-600 hover:text-blue-500 cursor-pointer'>

                    <h1>Resend</h1>
                    <AiOutlineReload />


                  </div>
                )}


              </div>

              {/* <div>
                <input  type='number' maxLength={1} min={0} max={9} className='w-[60px] h-[80px] border rounded-lg r' />

              </div> */}

              {invalid === true || error ? (
                <div className='w-full'>

                  <Message type="error">{error?.detail || error}</Message>

                </div>
              ) : ""}


              {loading ? (
                <button

                  className="p-2  bg-none outline-none rounded-xl bg-gray-200 text-gray-400  w-full text-[11px] md:text-[14px] h-[60px] m-1  animate-pulse"
                >
                  Verifying
                </button>
              ) : (
                <button
                  type="submit"
                  className="p-2  bg-none outline-none rounded-xl bg-blue-600 text-white  hover:bg-blue-700  w-full h-[60px] text-[11px] md:text-[14px]  m-1 drop-shadow-lg shadow-blue-500/50 "
                >
                  Verify
                </button>


              )}


            </form>

          </div>
        </div>
      </main>
    </div>
  )
}

export default EmailVerifyPage