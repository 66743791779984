import axios from "axios";
import { REACT_API_URL, REACT_APP_URL } from "../../utilities/utils";
import { EVENT_COMMENT_CREATE_FAIL,
     EVENT_COMMENT_CREATE_REQUEST, 
     EVENT_COMMENT_CREATE_SUCCESS, 
     EVENT_COMMENT_DELETE_FAIL, 
     EVENT_COMMENT_DELETE_REQUEST, 
     EVENT_COMMENT_DELETE_SUCCESS, 
     EVENT_COMMENT_DETAILS_SUCCESS, 
     EVENT_COMMENT_LIST_FAIL, 
     EVENT_COMMENT_LIST_REQUEST, 
     EVENT_COMMENT_LIST_SUCCESS, 
     EVENT_COMMENT_UPDATE_FAIL, 
     EVENT_COMMENT_UPDATE_REQUEST, 
     EVENT_COMMENT_UPDATE_SUCCESS } from "../constants/eventCommentConstants";



/* ACTION CREATOR USED IN HomeScreen COMPONENT */
export const listEventComments = (id) => async (dispatch) => {
    try {
        dispatch({
            type: EVENT_COMMENT_LIST_REQUEST,
        });

        const { data } = await axios.get(`${REACT_API_URL}/events/comments/${id}/`);

        dispatch({
            type: EVENT_COMMENT_LIST_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: EVENT_COMMENT_LIST_FAIL,
            payload:
                error.response && error.response.data.detail
                    ? error.response.data.detail
                    : error.message,
        });
    }
};

export const deleteEventComment = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: EVENT_COMMENT_DELETE_REQUEST,
        });

        // PULLING OUT THE CURRENT USER WE ARE LOGGED IN AS
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axios.delete(
            `${REACT_APP_URL}/events/comments/${id}/delete/`,
            config
        );

        dispatch({
            type: EVENT_COMMENT_DELETE_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: EVENT_COMMENT_DELETE_FAIL,
            payload:
                error.response && error.response.data.detail
                    ? error.response.data.detail
                    : error.message,
        });
    }
};

export const createEventComment = (comment) => async (dispatch, getState) => {
    try {
        dispatch({
            type: EVENT_COMMENT_CREATE_REQUEST,
        });

        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        /* MAKING API CALL TO CREATE PRODUCT */
        const { data } = await axios.post(
            `${REACT_APP_URL}/events/${comment?.event}/comments/create/`,
            comment,

            config
        );

        dispatch({
            type: EVENT_COMMENT_CREATE_SUCCESS,

            payload: data,
        });

    } catch (error) {
        dispatch({
            type: EVENT_COMMENT_CREATE_FAIL,
            payload:
                error.response && error.response.data.detail
                    ? error.response.data.detail
                    : error.message,
        });
    }
};

export const updateEventComment = (comment) => async (dispatch, getState) => {
    try {
        dispatch({
            type: EVENT_COMMENT_UPDATE_REQUEST,
        });

        // PULLING OUT THE CURRENT USER WE ARE LOGGED IN AS
        const {
            userLogin: { userInfo },
        } = getState();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        /* MAKING API CALL TO UPDATE PRODUCT */
        const { data } = await axios.put(
            `${REACT_APP_URL}/events/comments/${comment._id}/update/`,
            comment,
            config
        );

        /* IF PUT REQUEST SUCCESSFULL WE DISPATCH & SEND THE PAYLOAD TO OUR REDUCER */
        dispatch({
            type: EVENT_COMMENT_UPDATE_SUCCESS,
            payload: data,
        });

        /* LOAD IN THE UPDATED PRODUCTS DETAILS */
        dispatch({
            type: EVENT_COMMENT_DETAILS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: EVENT_COMMENT_UPDATE_FAIL,
            payload:
                error.response && error.response.data.detail
                    ? error.response.data.detail
                    : error.message,
        });
    }
};
